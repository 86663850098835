<template>
  <b-modal
    v-model="onControl"
    no-close-on-backdrop
    title="Choose Motive"
    title-tag="h3"
    modal-class="modal-primary"
    size="lg"
    @hidden="close"
  >
    <b-row cols="1">
      <validation-observer ref="form">
        <b-col>
          <b-form-group label="Motive:">
            <validation-provider
              v-slot="{ errors }"
              name="description"
              rules="required"
            >
              <v-select
                v-model="motiveId"
                :options="motives"
                label="description"
                :reduce="(description) => description.id"
              />
              <span v-if="errors[0]" class="text-danger">
                Motive {{ errors[0] }}</span
              >
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col v-if="motiveId === 6">
          <b-form-group label="Arguments: ">
            <validation-provider
              v-slot="{ errors }"
              name="description"
              rules="required"
            >
              <b-form-input
                v-model="argument"
                :state="errors[0] ? false : null"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
      </validation-observer>
    </b-row>

    <template #modal-footer>
      <button-save @click="saveMotiveLoss" />
    </template>
  </b-modal>
</template>

<script>
import buttonSave from "@/views/commons/utilities/ButtonSave.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import vSelect from "vue-select";
import useAgentAway from "@/views/ce-digital/sub-modules/customer-service/views/agent-away/components/useAgentAway.js";

export default {
  components: {
    buttonSave,
    vSelect,
  },
  data() {
    return {
      onControl: false,
      motiveId: null,
      argument: null,
      motives: [
        { id: 1, description: "IN TRAINING" },
        { id: 2, description: "IN THE BATHROOM" },
        { id: 3, description: "IN MEETING" },
        { id: 4, description: "OF PERMISSION" },
        { id: 5, description: "IN LUNCH" },
        { id: 6, description: "OTHERS" },
      ],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    this.onControl = true;
  },
  methods: {
    ...mapMutations({
      SET_USER_STATUS_SESSION: "UserStore/SET_USER_STATUS_SESSION",
      SET_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/SET_USER_STATUS_SESSION_SUB_MODULE",
    }),
    ...mapActions({
      A_CHANGE_USER_STATUS_SESSION: "UserStore/A_CHANGE_USER_STATUS_SESSION",
      A_CHANGE_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/A_CHANGE_USER_STATUS_SESSION_SUB_MODULE",
      A_SAVE_ARGUMENT_MOTIVE_BUSY: "UserStore/A_SAVE_ARGUMENT_MOTIVE_BUSY",
    }),
    close() {
      this.$emit("close");
    },
    async saveMotiveLoss() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          this.addPreloader();
          this.SET_USER_STATUS_SESSION_SUB_MODULE([{ status_session: 2 }]);
          const params = {
            created_by: this.currentUser.user_id,
            sub_module_id: this.moduleId,
            status_sub_module_id: 2,
            motive_id: this.motiveId,
            motive_arg: this.argument,
          };
          const data = await this.A_CHANGE_USER_STATUS_SESSION_SUB_MODULE(
            params
          );
          if (data.status === 200) {
            this.showGenericToast({});
            this.close();
            this.removePreloader();
            if ([15, 16].includes(this.currentUser.role_id)) {
              this.toogleBusyState();
            }
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
        this.close();
      }
    },
  },
  setup() {
    const { toogleBusyState } = useAgentAway();
    return { toogleBusyState };
  },
};
</script>

<style scoped>
</style>
