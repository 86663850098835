<template>
  <b-navbar-nav class="nav">
    <b-nav-item
      v-if="!isPhone"
      :id="`bookmark-messages`"
      v-b-tooltip.hover.top="'Messages'"
      :to="{ name: 'amg-messages' }"
    >
      <messages />
    </b-nav-item>
    <b-nav-item
      v-if="!isPhone"
      :id="`bookmark-sticky-notes`"
      @click="$refs.stickyNotes.openStickyNotes()"
    >
      <sticky-notes ref="stickyNotes" />
    </b-nav-item>
    <!-- <b-nav-item
      v-if="!isPhone"
      :id="`bookmark-pay-stub`"
      @click="$refs.payStub.openPayStubModal()"
    >
      <pay-stub ref="payStub" />
    </b-nav-item> -->
    <template>
      <b-nav-item v-if="currentUser.main_module === 17">
        <recruitment-meetings ref="recruitment-meetings" />
      </b-nav-item>
      <b-nav-item
        v-else-if="!isPhone"
        v-b-tooltip.hover.top="'Appointments'"
        @click="$refs.calendar.openAppointmentsModal()"
      >
        <calendar ref="calendar" />
      </b-nav-item>
    </template>

    <b-nav-item
      :id="`bookmark-messenger-new`"
      v-b-tooltip.hover.top="'New AMG Messenger'"
      @click="$refs.messengerNew.redirectToMessenger()"
    >
      <messenger-new ref="messengerNew" />
    </b-nav-item>

    <attendance />

    <ParagonSoft ref="softProjects" style="margin-right: 5px" />

    <rb-notifications-button
      style="margin-right: 10px"
      v-b-tooltip.hover.top="'RB Notifications'"
      v-if="isCeo || isChief || isSupervisor"
    />

    <meta-inbox-button v-if="isCeo || isMetamedia" />

    <rc-chat-icon style="margin-right: 10px" />
    <ButtonRegisterPayment v-if="validateModules || isCeo" />
  </b-navbar-nav>
</template>

<script>
import { mapGetters } from "vuex";
import attendance from "@/layouts/components/navbar/components/attendance/attendance.vue";
import RecruitmentMeetings from "@/layouts/components/navbar/components/meetings/recruitments/RecruitmentMeetings.vue";
import StickyNotes from "./components/sticky-notes/StickyNotes.vue";
import Appointments from "./components/appointments/Appointments.vue";
import Messages from "./components/messages/Messages.vue";
import Messenger from "./components/messenger/Messenger.vue";
import PayStub from "./components/pay-stub/PayStub.vue";
import Calendar from "./components/calendar/Calendar.vue";
import MessengerNew from "./components/messenger-new/MessengerNew.vue";
import ParagonSoft from "@/layouts/components/navbar/soft/ParagonSoft.vue";
import RbNotificationsButton from "@/layouts/components/navbar/components/notifications/rb-notifications/RbNotificationsButton.vue";
import RcChatIcon from "@/views/commons/components/ring-central/chat-sms/RcChatIconGeneral.vue";
import MetaInboxButton from "@/layouts/components/navbar/components/meta-inbox/metaInboxButton.vue";
import ButtonRegisterPayment from "@/views/commons/new-payment/ButtonRegisterPayment.vue";

export default {
  components: {
    StickyNotes,
    Appointments,
    Messages,
    Messenger,
    PayStub,
    Calendar,
    MessengerNew,
    attendance,
    RecruitmentMeetings,
    ParagonSoft,
    RbNotificationsButton,
    RcChatIcon,
    MetaInboxButton,
    ButtonRegisterPayment,
  },
  data() {
    return {
      cols: 6,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isPhone() {
      return this.screenWidth <= 600 && this.screenWidth > 0;
    },
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    validateModules() {
      let modules = [2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 14, 20, 22, 23, 25, 26];
      return modules.includes(this.moduleId);
    },
    isMetamedia() {
      return this.currentUser.arrModuls.find((rol) => rol.id_module === 15);
    },
  },
  watch: {
    screenWidth() {
      if (this.screenWidth >= 1300) {
        this.cols = 6;
      } else {
        this.cols = 12;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.nav-bookmar-content-overlay {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  z-index: -1;

  &:not(.show) {
    pointer-events: none;
  }

  &.show {
    cursor: pointer;
    z-index: 10;
    opacity: 1;
  }
}
</style>
