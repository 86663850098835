<template>
  <div>
    <b-modal
      v-model="oncontrol"
      size="lg"
      :body-bg-variant="isDarkSkin ? 'dark' : 'white'"
      :header-bg-variant="bgHeaderModal"
      no-close-on-backdrop
      centered
      @hidden="closeModal()"
    >
      <template #modal-title>
        <div class="d-flex align-items-center">
          <feather-icon
            icon="ClockIcon"
            size="18"
            class="mr-1"
          />
          <strong class="text-uppercase">
            {{ action }} Meet</strong>
        </div>
      </template>
      <b-card class="shadow-none">
        <b-row v-if="loading">
          <b-col cols="12">
            <b-skeleton-img
              no-aspect
              height="300px"
            />
          </b-col>
        </b-row>
        <validation-observer
          v-else
          ref="formMeeting"
        >
          <b-row>
            <b-col
              cols="12"
              class="pr-2"
            >
              <b-row>
                <b-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="type"
                  >
                    <b-form-group label="Meeting type">
                      <v-select
                        v-model="dataMeeting.type"
                        :options="typesMeeting"
                        :reduce="(option) => option.id"
                        label="name"
                        :state="errors[0] ? false : null"
                        :class="errors[0] ? 'border-danger rounded text-white' : ''"
                        :disabled="disabledTypeMeeting"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <template v-if="actionMeet === 'reprogrammed'">
                <b-row
                  style="margin: 0px 2px 30px 2px"
                  class="py-2 px-1 rounded"
                  :class="isDarkSkin ? 'border-light' : 'bg-light'"
                >
                  <b-col cols="6">
                    <b-form-group
                      class="mb-1"
                      label="Previous Date"
                      label-for="PreviousDate"
                    >
                      <p
                        style="font-size: 15px"
                        class="pt-1"
                      >
                        <feather-icon
                          style="margin-top:-1px"
                          icon="CalendarIcon"
                          size="18"
                          class="text-secondary ml-1 mr-1"
                        />
                        {{ dataMeeting.oldDate | myGlobal }}
                      </p>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      class="mb-1"
                      label="Previous Time"
                      label-for="PreviousTime"
                    >
                      <p
                        style="font-size: 15px"
                        class="pt-1"
                      >
                        <feather-icon
                          style="margin-top:-1px"
                          icon="ClockIcon"
                          size="18"
                          class="text-secondary ml-1 mr-1"
                        />
                        {{ dataMeeting.oldTime }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <b-row>
                <b-col
                  cols="12"
                  :lg="optionReprogram ? '5' : '6'"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="date"
                    rules="required"
                  >
                    <b-form-group
                      class="mb-1"
                      :label="actionMeet === 'reprogrammed' ? 'New Date' : 'Date'"
                      label-for="Date"
                    >
                      <kendo-datepicker
                        id="date"
                        v-model="dataMeeting.date"
                        v-mask="'##/##/####'"
                        name="date"
                        :first-day-of-week="1"
                        placeholder="MM/DD/YYYY"
                        :format="'MM/dd/yyyy'"
                        :min="minDate"
                        class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
                        :readonly="completedOrDetail"
                        :style="completedOrDetail ? 'pointer-events:none' : ''"
                        :state="errors[0] ? false : null"
                        :class="errors[0] ? 'border-danger' : ''"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  :lg="optionReprogram ? '4' : '6'"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="time"
                    rules="required"
                  >
                    <b-form-group
                      class="mb-1"
                      :label="actionMeet === 'reprogrammed' ? 'New Time' : 'Time'"
                      label-for="Time"
                    >
                      <kendo-timepicker
                        id="time"
                        v-model="dataMeeting.time"
                        v-mask="'##:##'"
                        format="HH:mm"
                        name="time"
                        :interval="15"
                        class="w-100 rounded bg-transparent"
                        placeholder="Hour"
                        style="height: 2.73rem"
                        :readonly="completedOrDetail"
                        :style="completedOrDetail ? 'pointer-events:none' : ''"
                        :state="errors[0] ? false : null"
                        :class="errors[0] ? 'border-danger' : ''"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="optionReprogram && reprogramValidation"
                  cols="12"
                  lg="3"
                >
                  <b-button
                    v-if="isReprogram"
                    variant="primary"
                    class="mt-2 mb-1 mb-lg-0"
                    @click="reprogramMeeting()"
                  >
                    <feather-icon
                      icon="ClockIcon"
                      size="13"
                      class="mr-1"
                    />
                    Reprogram
                  </b-button>
                  <b-button
                    v-else
                    variant="danger"
                    class="mt-2 mb-1 mb-lg-0"
                    @click="reprogramMeeting()"
                  >
                    <feather-icon
                      icon="XIcon"
                      size="13"
                      class="mr-1"
                    />
                    Cancel
                  </b-button>
                </b-col>
              </b-row>
              <template v-if="actionMeet == 'detail'">
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Project"
                      label-for="name_project"
                    >
                      <b-form-input
                        id="name_project"
                        v-model="dataMeeting.name_project"
                        name="name_project"
                        placeholder="Name project"
                        :disabled="true"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Place"
                    label-for="Place"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-select
                        id="place"
                        v-model="dataMeeting.place"
                        name="place"
                        label="Place"
                        :options="options"
                        :state="errors[0] ? false : null"
                        :class="errors[0] ? 'border-danger rounded' : ''"
                        :disabled="completedOrDetail"
                        placeholder="-- Select a place --"
                      />
                      <small class="text-danger">
                        {{
                          errors[0]
                        }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-card-group deck>
                    <b-card
                      no-body
                      class="shadow-none border-light mt-1"
                      :class="requiredParticipants == false ? 'border-danger' : ''"
                      :header-bg-variant="isDarkSkin ? 'dark' : 'light'"
                      :header-text-variant="isDarkSkin ? 'white' : 'secondary'"
                    >
                      <template #header>
                        <div class="d-flex w-100 justify-content-between align-items-center">
                          <strong class="mr-2">
                            Participants
                            <b-badge
                              variant="primary"
                              class="rounded ml-1"
                            >{{ listParticipants.length }}</b-badge>
                          </strong>
                          <b-button
                            v-if="!completedOrDetail"
                            variant="success"
                            size="sm"
                            @click="showModalEmployees"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              szie="15"
                            />
                          </b-button>
                        </div>
                      </template>
                      <div
                        v-if="listParticipants.length > 0"
                        class="d-block"
                      >
                        <b-list-group
                          class="w-100"
                          style="max-height:300px;overflow-y: auto"
                        >
                          <b-list-group-item
                            v-for="(item) in listParticipants"
                            :key="item.name"
                            class="w-0 d-flex justify-content-between align-items-center bg-transparent border-bottom rounded-0"
                            style="font-size: 15px"
                          >
                            <div>
                              <feather-icon
                                icon="UserIcon"
                                class="mr-1 text-primary"
                                size="18"
                              />
                              <span class="text-primary text-uppercase">{{ item.name || item.know_as }}</span>
                            </div>
                            <div class="text-right">
                              <span
                                v-if="item.status_attendance === 'CONFIRMED' "
                                class="text-success"
                              >{{ item.status_attendance }}</span>
                              <span
                                v-else-if="item.status_attendance === 'REJECTED'"
                                class="text-danger"
                              >{{ item.status_attendance }}</span>
                              <span
                                v-else
                                class="text-warning"
                              >UNCONFIRMED</span>
                            </div>
                            <feather-icon
                              v-if="!completedOrDetail"
                              icon="Trash2Icon"
                              size="15"
                              class="cursor-pointer text-danger hover-me"
                              @click="deleteEmployee(item.user_id)"
                            />
                          </b-list-group-item>
                        </b-list-group>
                      </div>
                      <div
                        v-else
                        class="d-flex justify-content-center align-items-center w-100 p-4"
                        style="min-height: 150px !important"
                      >
                        <feather-icon
                          icon="UsersIcon"
                          size="25"
                          class="text-secondary mr-2"
                        />
                        <span> No participants</span>
                      </div>
                    </b-card>
                  </b-card-group>
                  <span
                    v-if="requiredParticipants == false"
                    class="text-danger"
                  >
                    You need one or more participants
                  </span>
                </b-col>
              </b-row>
              <b-row
                v-if="completedOrDetail"
                class="mt-1"
              >
                <b-col
                  cols="6"
                  lg="6"
                  class="pt-1"
                >
                  <strong
                    class="rounded-left p-1"
                    style="font-size: 13px;"
                    :class="optionsBorder[dataMeeting.status]"
                  >Status</strong>
                  <strong
                    style="font-size: 13px;"
                    class="text-white rounded-right p-1"
                    :class="`${optionsBg[dataMeeting.status]} meeting`"
                  >{{ dataMeeting.status }} meeting</strong>
                </b-col>
                <b-col
                  cols="6"
                  lg="6"
                  class="d-flex align-items-end"
                >
                  <b-select
                    v-model="confirmAttendanceSelected"
                    :options="optionsAttendance"
                    value-field="item"
                    text-field="name"
                    hidenn="false"
                    :disabled="confirmStatusSelect"
                    class="mr-1 d-flex align-items-center"
                  />

                  <b-button
                    v-if="confirmStatusButton"
                    size="sm"
                    variant="success"
                    @click="confirmAttendanceUser()"
                  >
                    Confirm Attendance
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
      <template #modal-footer>
        <div class="d-flex justify-content-end align-items-center w-100">
          <b-button
            v-if="!completedOrDetail"
            :variant="actionMeet === 'reprogrammed' ? 'warning' : 'primary'"
            class="mr-1"
            @click="programMeet"
          >
            <feather-icon
              icon="ClockIcon"
              class="mr-1"
            />
            {{ actionMeet === 'reprogrammed' ? 'Reprogram meeting' : 'Program meeting' }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <!--Modal employees-->
    <modal-employees
      v-if="onControlEmployee"
      :employee-selected="JSON.parse(dataMeeting.participants)"
      @closeModal="closeModalEmployee"
      @saveData="saveSelectedEmployees"
    />
  </div>
</template>
<script>
import ModalEmployees from '@/views/commons/components/projects/components/meetings/modals/ModalEmployees.vue';
import ServiceMeeting from '@/views/commons/components/projects/services/meeting.service';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
  components: {
    ModalEmployees,
  },
  props: {
    meet: {
      type: Object,
      default: () => { },
      require: true,
    },
    action: {
      type: String,
      default: 'detail',
      require: true,
    },
    completedMeetings: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {

      reprogramValidation: false,
      confirmStatusSelect: false,
      confirmStatusButton: true,

      confirmAttendanceSelected: null,
      optionsAttendance: [
        { name: '-- Select a confirm option --', disabled: true },
        { item: 'CONFIRMED', name: 'Confirm attendance' },
        { item: 'REJECTED', name: 'Refuse attendance' },
      ],

      options: [
        'Roger\'s office',
        'Conference floor',
        'Training floor',
        'Video conference',
      ],
      oncontrol: false,
      optionsBg: {
        pending: 'bg-warning border-warning',
        reprogrammed: 'bg-info border-info',
        completed: 'bg-success border-success',
      },
      optionsBorder: {
        pending: 'border-warning',
        reprogrammed: 'border-info',
        completed: 'border-success',
      },
      onControlEmployee: false,
      dataMeeting: {},
      requiredParticipants: null,
      actionMeet: null,
      optionsMeetingTypes: [],
      loading: true,
      disabledTypeMeeting: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    isCeo() {
      return this.currentUser.user_id === 1;
    },
    minDate() {
      if (!['detail', 'completed'].includes(this.actionMeet)) {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
      }
      return new Date(this.dataMeeting.date);
    },
    listParticipants() {
      return this.dataMeeting.participants ? JSON.parse(this.dataMeeting.participants) : [];
    },
    bgHeaderModal() {
      const colors = {
        create: 'primary',
        reprogrammed: 'warning',
        completed: 'success',
        detail: 'info',
      };
      return colors[this.actionMeet];
    },
    currentDate() {
      return moment().format('YYYY-MM-DD');
    },
    completedOrDetail() {
      return ['detail', 'completed'].includes(this.actionMeet);
    },
    isReprogram() {
      return this.actionMeet === this.action;
    },
    optionReprogram() {
      // 1. es CEO, la reunion aun no esta completada y el tipo de reunion es REVISION DEL PROYECTO o y la accion no es reprogramar
      // 2. es chief, le reunion aun no esta completada o y la accion no es reprogramar
      return (this.isCeo && [1, 2].includes(this.dataMeeting.status_id) && this.dataMeeting.type === 1)
      || (!this.isCeo && [1, 2].includes(this.dataMeeting.status_id) && this.action !== 'reprogrammed');
    },
    typesMeeting() {
      let options = [];
      if (this.isCeo && this.action === 'create') {
        options = this.optionsMeetingTypes.filter(item => [1, 13, 14, 15].includes(item.id));
      } else
      if (this.action === 'create' && !this.isCeo) {
        options = this.optionsMeetingTypes.filter(item => !this.completedMeetings.includes(item.id));
      } else {
        options = this.optionsMeetingTypes;
      }
      options.unshift({ id: null, name: '--Select a type meeting--', phase: null });
      return options;
    },
  },
  async created() {
    await this.getMeetingsType();

    if (this.meet.id) {
    
      await this.getAttendanceUser();
      await this.getCreatedMeetingUser();
    }
  },
  async mounted() {
    this.actionMeet = this.action.toLowerCase();
    this.oncontrol = true;
    await this.getMeet();
  },
  methods: {
    ...mapActions({
      A_COUNT_MEETINGS: 'NotificationStore/A_COUNT_MEETINGS',
    }),
    async getCreatedMeetingUser() {
      const params = {
        meeting_id: this.meet.id,
      };
      const data = await ServiceMeeting.getMeetingCreatedBy(params);
      if (data.data[0].created_by === this.currentUser.user_id) {
        this.reprogramValidation = true;
      }
    },
    async getAttendanceUser() {
      const params = {
        user_id: this.currentUser.user_id,
        meeting_id: this.meet.id,
      };

      const data = await ServiceMeeting.getConfirmAttendance(params);

      if (data?.data.length > 0 && data.data[0].confirm_attendance_status !== null) {
        this.confirmAttendanceSelected = data.data[0].confirm_attendance_status;
        this.confirmStatusButton = false;
        this.confirmStatusSelect = true;
      }
    },

    async confirmAttendanceUser() {
      try {
        const confirm = await this.showConfirmSwal();

        if (confirm.isConfirmed) {
          if (this.confirmAttendanceSelected === null) {
            this.showWarningSwal('Please select an answer.');
            return;
          }

          const params = {
            user_id: this.currentUser.user_id,
            meeting_id: this.meet.id,
            confirm_type: this.confirmAttendanceSelected,
          };

          this.addPreloader();

          const data = await ServiceMeeting.postConfirmAttendance(params);
          this.removePreloader();
          this.getAttendanceUser();
          this.getMeet();
        }
      } catch (e) {
        this.showErrorSwal(e);
        this.removePreloader();
      }
    },

    closeModal() {
      this.oncontrol = false;
      this.$emit('closeModal');
    },
    showModalEmployees() {
      this.onControlEmployee = true;
    },
    closeModalEmployee() {
      this.onControlEmployee = false;
    },
    saveSelectedEmployees(employees) {
      this.dataMeeting.participants = JSON.stringify(employees);
      this.onControlEmployee = false;
      this.requiredParticipants = employees.length > 0;
    },
    deleteEmployee(userId) {
      const newParticipants = JSON.parse(this.dataMeeting.participants).filter(item => item.user_id !== userId);
      this.requiredParticipants = newParticipants.length > 0;
      this.dataMeeting.participants = JSON.stringify(newParticipants);
    },
    reprogramMeeting() {
      if (this.actionMeet === this.action) {
        this.actionMeet = 'reprogrammed';
        this.dataMeeting.oldDate = this.dataMeeting.date;
        this.dataMeeting.oldTime = this.dataMeeting.time;
        this.dataMeeting.date = null;
        this.dataMeeting.time = null;
      } else {
        this.actionMeet = this.action;
        this.dataMeeting.date = this.dataMeeting.oldDate;
        this.dataMeeting.time = this.dataMeeting.oldTime;
        this.dataMeeting.oldDate = null;
        this.dataMeeting.oldTime = null;
      }
    },
    async programMeet() {
      this.requiredParticipants = JSON.parse(this.dataMeeting.participants);
      const validForm = await this.$refs.formMeeting.validate();

      if (!validForm || this.requiredParticipants.length === 0) {
        return;
      }
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) {
          return;
        }

        this.addPreloader();
        // anade el usuario que esta creando la reunion
        const participants = JSON.parse(this.dataMeeting.participants);
        if (!participants.find(item => item.user_id === this.currentUser.user_id)) {
          participants.push({
            user_id: this.currentUser.user_id, name: this.currentUser.fullName, know_as: this.currentUser.fullName,
          });
   
        }

        const params = {
          project_id: this.dataMeeting.project_id,
          date: moment(`${this.dataMeeting.date} ${this.dataMeeting.time}`, 'MM/DD/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
          status: this.actionMeet === 'reprogrammed' ? 2 : 1,
          reasons: null,
          user_id: this.currentUser.user_id,
          place: this.dataMeeting.place,
          meeting_type: this.dataMeeting.type,
          participants,
        };

        const { data } = await ServiceMeeting.insertMeeting(params);

        if (data.result.result === -1) {
          this.showWarningSwal('Meet', 'A meeting has already been registered with this date and time');
        } else if (data.result.result === 1) {
          this.showSuccessSwal();
          const objMeet = {
            id: data.result.meeting_id,
            date: moment(this.dataMeeting.date, 'MM/DD/YYYY').format('YYYY-MM-DD'),
            time: this.dataMeeting.time,
          };
          this.$emit('successAction', objMeet);
          await this.A_COUNT_MEETINGS({ user_id: this.currentUser.user_id, month: parseInt(moment().format('MM'), 10), year: parseInt(moment().format('YYYY'), 10) });
        } else {
          this.showErrorSwal();
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log('Error to program meet', error);
      }
    },
    async getMeet() {
      if (this.meet.id) {
        try {
          const params = {
            id: this.meet.id,
          };
          const { data } = await ServiceMeeting.getMeetingById(params);
    
          const obj = data[0];
          this.dataMeeting = obj;
          this.dataMeeting.type = obj.meetings_type_id;
          if (this.actionMeet === 'reprogrammed') {
            this.dataMeeting.oldDate = obj.date;
            this.dataMeeting.oldTime = obj.time;
            this.dataMeeting.date = null;
            this.dataMeeting.time = null;
            this.dataMeeting.type = null;
          }
        } catch (error) {
          console.log('error get meet', error);
        }
        this.disabledTypeMeeting = true;
      } else {
        let typeMeeting = null;
        if (this.isCeo && this.meet.last_meeting_type_id === -1) {
          typeMeeting = 1;
        } else if (this.isCeo && this.meet.last_meeting_type_id >= 1) {
          typeMeeting = 13;
        }

        // if meeting type is 13 (meeting with chief paragon), automatic push to user chief
        // this information getting table employees ( get user only chief paragon )
        const previousParticipants = typeMeeting === 13 ? [
          {
            user_id: 41,
            name: 'Alexis Sifuentes',
            know_as: 'Alexis Sifuentes',
          },
          {
            user_id: 1,
            name: 'Roger Segura',
            know_as: 'Roger Segura',
          },
        ] : [];

        this.dataMeeting = {
          id: null,
          project_id: this.meet.project_id,
          date: null,
          time: null,
          name_project: null,
          reasons: null,
          place: null,
          status: null,
          status_id: null,
          participants: JSON.stringify(previousParticipants),
          type: typeMeeting,
        };
      }
      this.disabledTypeMeeting = this.isCeo || ['detail', 'completed'].includes(this.action);
      this.loading = false;
    },
    async getMeetingsType() {
      try {
        const { data } = await ServiceMeeting.getMeetingsType();
        this.optionsMeetingTypes = [];
        this.optionsMeetingTypes = data.data;
      } catch (error) {
        this.showErrorSwal();
        console.log('error', error);
      }
    },
  },
};
</script>
<style scoped>
.hover-me {
  transition: 0.1s ease-in-out;
}

.hover-me:hover {
  transform: scale(1.5);
}
</style>
