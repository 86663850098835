<template>
  <li
    v-if="canViewVerticalNavMenuGroup(item) && checkPermittedRoles"
    class="nav-item has-sub"
    :class="{
      open: isOpen,
      disabled: item.disabled,
      'sidebar-group-active': isActive,
    }"
  >
    <b-link
      class="d-flex align-items-center"
      @click="() => updateGroupOpen(!isOpen)"
    >
      <tabler-icon
        v-if="item.icon"
        :icon="item.icon || 'CircleIcon'"
        :badge="
          !isMouseHovered &&
          isVerticalMenuCollapsed &&
          (currentBreakPoint == 'xl' || currentBreakPoint == 'xxl')
            ? item.tag && item.tag > 0
              ? item.tag
              : 0
            : 0
        "
        badge-classes="bg-danger"
      />

      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag && item.tag > 0"
        pill
        :variant="item.tagVariant || 'danger'"
        class="mr-1 ml-auto"
        >{{ item.tag }}
      </b-badge>

      <!-- <tabler-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag && item.tag>0 "
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >{{ item.tag }}</b-badge> -->
    </b-link>
    <b-collapse v-model="isOpen" class="menu-content" tag="ul">
      <component
        :is="resolveNavItemComponent(child)"
        v-for="child in item.children.filter((child) => {
          if (child.title === 'Customer Tickets') {
            // Only Customer Tickets
            return (
              child.permittedRoles.includes(role_id) ||
              permittedUserscustomerTickets.includes(user_id)
            ); // Users availability customer tickets
          } else if (child.permittedRoles) {
            return child.permittedRoles.includes(role_id);
          }
          return true;
        })"
        :key="child.route"
        ref="groupChild"
        :item="child"
        :is-mouse-hovered="isMouseHovered"
        :is-vertical-menu-collapsed="isVerticalMenuCollapsed"
      />
    </b-collapse>
  </li>
</template>

<script>
import { BLink, BBadge, BCollapse } from "bootstrap-vue";
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { useUtils as useAclUtils } from "@core/libs/acl";
import { mapGetters } from "vuex";
import store from "@/store";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";

// Composition Function
import useVerticalNavMenuGroup from "./useVerticalNavMenuGroup";
import mixinVerticalNavMenuGroup from "./mixinVerticalNavMenuGroup";

export default {
  name: "VerticalNavMenuGroup",
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    BLink,
    BBadge,
    BCollapse,
  },
  mixins: [mixinVerticalNavMenuGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
    isMouseHovered: {
      type: Boolean,
      required: true,
    },
    isVerticalMenuCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentBreakPoint: "app/currentBreakPoint",
    }),
    permittedUserscustomerTickets() {
      return this.$store.getters[
        "NotificationStore/G_USERS_AVAILABILITY_CUSTOMER_TICKETS"
      ];
    },
    checkPermittedRoles() {
      for (const child of this.item.children) {
        if (child.permittedRoles !== undefined) {
          if (child.permittedRoles.includes(this.role_id)) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    },
  },
  setup(props) {
    const { isOpen, isActive, updateGroupOpen, updateIsActive } =
      useVerticalNavMenuGroup(props.item);

    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuGroup } = useAclUtils();
    const { role_id } = JSON.parse(localStorage.getItem("userData"));
    const { user_id } = JSON.parse(localStorage.getItem("userData"));
    return {
      resolveNavItemComponent,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuGroup,

      // i18n
      t,
      role_id,
      user_id,
    };
  },
};
</script>

<style lang="scss">
.main-menu {
  .nav-item {
    .badge.badge-up {
      top: -10px;
      right: 0px;
    }
  }
}
</style>
