<template>
  <div class="x-list">
    <div
      left
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="position-relative"
    >
      <b-link :to="{ name: G_COUNT_EMPLOYEES_WITHOUT_SCHEDULE? 'management-attendance-employees-schedule': 'management-attendance-dashboard' }">
        <feather-icon
          v-b-tooltip.hover.top="G_COUNT_EMPLOYEES_WITHOUT_SCHEDULE?`${G_COUNT_EMPLOYEES_WITHOUT_SCHEDULE} employees without schedule`:'Attendance'"
          badge-classes="badge-important"
          class="cursor-pointer mr-1"
          :class="G_COUNT_EMPLOYEES_WITHOUT_SCHEDULE? (isValidRange?'blink text-danger':'text-danger'): (isValidRange?'blink text-primary': 'text-primary')"
          icon="CalendarIcon"
          size="19"
        />
        <strong
          v-if="isValidRange"
          class="bg-danger rounded-sm text-white"
          style="
            padding: 1px 5px;
            font-size: 8px;
            position: absolute;
            top: -13px;
            left: -5px;
          "
        >New</strong>
      </b-link>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      G_COUNT_EMPLOYEES_WITHOUT_SCHEDULE:
        'NotificationStore/G_COUNT_EMPLOYEES_WITHOUT_SCHEDULE',
    }),
    isValidRange() {
      const dateDeployment = moment('2023-07-01');
      const currentDate = moment();
      const diffDate = currentDate.diff(dateDeployment, 'days');
      return diffDate <= 7;
    },
  },
  async created() {
    await this.A_SET_COUNT_EMPLOYEES_WITHOUT_SCHEDULE({
      user_id: this.currentUser.user_id,
    });
  },
  methods: {
    ...mapActions({
      A_SET_COUNT_EMPLOYEES_WITHOUT_SCHEDULE:
        'NotificationStore/A_SET_COUNT_EMPLOYEES_WITHOUT_SCHEDULE',
    }),
  },
};
</script>
<style scoped>
.blink {
  animation: animationBlink 0.5s ease-in-out infinite;
}
.blink:hover {
  animation: none;
}
@keyframes animationBlink {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
</style>
