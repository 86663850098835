<template>
  <ul>
    <template v-for="item in items">
      <component
        :is="resolveNavItemComponent(item)"
        v-if="item.route ? showTabNavigation(item) : true"
        :key="item.header || item.route"
        :item="item"
        :is-mouse-hovered="isMouseHovered"
        :is-vertical-menu-collapsed="isVerticalMenuCollapsed"
      />
    </template>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import { mapGetters } from "vuex";
import VerticalNavMenuHeader from "../vertical-nav-menu-header/VerticalNamMenuHeader.vue";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isJuan() {
      return this.currentUser.user_id === 127;
    },
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    isMouseHovered: {
      type: Boolean,
      required: true,
    },
    isVerticalMenuCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    showTabNavigation(item) {
      if (!this.currentUser) return false;
      const { route } = this.$router.resolve({ name: item.route });
      const { meta } = route;
      if (Object.keys(meta).length === 0) return true;
      if (!this.hasPermittedRoles(meta)) {
        if (meta.onlyChief) {
          return this.checkIfHasChief();
        }
        if (meta.onlyJuan) {
          return this.isJuan;
        }
        return this.checkEngSoftPermission(meta);
      }

      return this.checkRolePermissions(meta);
    },
    hasPermittedRoles(meta) {
      return meta.permittedRoles && meta.permittedRoles.length > 0;
    },
    checkIfHasChief() {
      const moduleHasChief = this.currentUser.arrModuls.find(
        (modul) => modul.id_module === this.moduleId && modul.hasChief === 1
      );
      if (moduleHasChief) {
        return this.isChief || this.isCeo || this.currentUser.eng_soft;
      }
      return this.isSupervisor || this.isCeo || this.currentUser.eng_soft;
    },
    checkEngSoftPermission(meta) {
      return meta.eng_soft ? this.currentUser.eng_soft === 1 : true;
    },
    checkRolePermissions(meta) {
      if (meta.hasLevelValidation) {
        for (const permittedRole of meta.permittedRoles) {
          if (
            permittedRole.role_id === this.currentUser.role_id &&
            (permittedRole.hasLevelValidation
              ? permittedRole.level_id === this.currentUser.level_id
              : true)
          ) {
            return true;
          }
        }
        return false;
      }
      return meta.permittedRoles.includes(this.currentUser.role_id);
    },
  },
  setup() {
    provide("openGroups", ref([]));
    return {
      resolveNavItemComponent,
    };
  },
};
</script>
