<template>
  <div>
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <feather-icon
          badge-classes="badge-important"
          class="text-body"
          :icon="userStatusSession"
          size="19"
        />
      </template>

      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            Status Session
          </h4>
        </div>
      </li>

      <b-dropdown-item
        :active="getUserStatusSession === 1"
        link-class="d-flex align-items-center"
        @click="changeStatusSession(1)"
      >
        <feather-icon
          size="16"
          icon="UserCheckIcon"
          class="mr-50"
        />
        <span>Active</span>
      </b-dropdown-item>

      <b-dropdown-item
        :active="getUserStatusSession === 2"
        link-class="d-flex align-items-center"
        @click="changeStatusSession(2)"
      >
        <feather-icon
          size="16"
          icon="UserPlusIcon"
          class="mr-50"
        />
        <span>Busy</span>
      </b-dropdown-item>

      <b-dropdown-item
        :active="getUserStatusSession === 3"
        link-class="d-flex align-items-center"
        @click="changeStatusSession(3)"
      >
        <feather-icon
          size="16"
          icon="UserMinusIcon"
          class="mr-50"
        />
        <span>Away</span>
      </b-dropdown-item>

      <b-dropdown-item
        :active="getUserStatusSession === 8"
        link-class="d-flex align-items-center"
        @click="changeStatusSession(8)"
      >
        <feather-icon
          size="16"
          icon="UsersIcon"
          class="mr-50"
        />
        <span>In meeting</span>
      </b-dropdown-item>

      <b-dropdown-item
        :active="getUserStatusSession === 4"
        link-class="d-flex align-items-center"
        @click="changeStatusSession(4)"
      >
        <feather-icon
          size="16"
          icon="UserXIcon"
          class="mr-50"
        />
        <span>Offline</span>
      </b-dropdown-item>

      <b-dropdown-item
        v-if="inCallAvialable"
        :active="getUserStatusSession === 5"
        link-class="d-flex align-items-center"
        @click="changeStatusSession(5)"
      >
        <feather-icon
          size="16"
          icon="PhoneCallIcon"
          class="mr-50"
        />
        <span>In Call</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import useAgentAway from '@/views/ce-digital/sub-modules/customer-service/views/agent-away/components/useAgentAway';

export default {
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      G_USER_STATUS_SESSION: 'UserStore/G_USER_STATUS_SESSION',
      G_USER_STATUS_SESSION_SUB_MODULE:
        'UserStore/G_USER_STATUS_SESSION_SUB_MODULE',
      G_USERS_AVAILABILITY_CUSTOMER_TICKETS: 'NotificationStore/G_USERS_AVAILABILITY_CUSTOMER_TICKETS',
    }),
    getUserStatusSession() {
      // >= 22 and <= 24 mean that it's a submodule of credit experts digital and != 24 mean that it's not settings
      return (this.moduleId >= 22 && this.moduleId <= 24 && this.moduleId !== 24) || this.moduleId === 20
        ? this.G_USER_STATUS_SESSION_SUB_MODULE
        : this.G_USER_STATUS_SESSION;
    },
    userStatusSession() {
      if ((this.moduleId >= 22 && this.moduleId <= 24 && this.moduleId !== 24) || this.moduleId === 20) {
        switch (this.G_USER_STATUS_SESSION_SUB_MODULE) {
          case 2:
            return 'UserPlusIcon';
          case 3:
            return 'UserMinusIcon';
          case 4:
            return 'UserXIcon';
          case 5:
            return 'PhoneCallIcon';
          case 6:
            return 'RepeatIcon';
          default:
            return 'UserCheckIcon';
        }
      } else {
        switch (this.G_USER_STATUS_SESSION) {
          case 1:
            return 'UserCheckIcon';
          case 2:
            return 'UserPlusIcon';
          case 3:
            return 'UserMinusIcon';
          case 5:
            return 'PhoneCallIcon';
          case 7:
            return 'UserXIcon';
          case 8:
            return 'UsersIcon';
          default:
            return 'UserXIcon';
        }
      }
    },
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    inCallAvialable() {
      if (this.moduleId === 22) return true;
      if (this.moduleId === 23) return true;
      if (this.moduleId === 20) return true;
      if (this.currentUser.arrRoles.find(rol => (rol.role_id === 2 || rol.role_id === 1 || rol.role_id === 6) && rol.module_id !== 24 && rol.user_id !== 38)) return true;
      if (this.G_USERS_AVAILABILITY_CUSTOMER_TICKETS.includes(this.currentUser.user_id)) return true;
      return false;
    },
    isCEdigitalSubmodule() {
      if (this.moduleId === 22) return true;
      if (this.moduleId === 23) return true;
      if (this.moduleId === 20) return true;
      return false;
    },
  },
  methods: {
    ...mapActions({
      A_CHANGE_USER_STATUS_SESSION: 'UserStore/A_CHANGE_USER_STATUS_SESSION',
      A_CHANGE_USER_STATUS_SESSION_SUB_MODULE:
        'UserStore/A_CHANGE_USER_STATUS_SESSION_SUB_MODULE',
    }),
    ...mapMutations({
      SET_USER_STATUS_SESSION: 'UserStore/SET_USER_STATUS_SESSION',
      SET_USER_STATUS_SESSION_SUB_MODULE:
        'UserStore/SET_USER_STATUS_SESSION_SUB_MODULE',
    }),
    changeStatusSession(status) {
      if (status === this.G_USER_STATUS_SESSION_SUB_MODULE) return;
      const params = {
        created_by: this.currentUser.user_id,
        sub_module_id: this.moduleId ? this.moduleId : this.currentUser.main_module,
        status_sub_module_id: status,
        id: this.currentUser.user_id,
        state: status.toString(),
        origin_action: 1,
      };

      if (this.isCEdigitalSubmodule) {
        if (status === 2) {
          this.$emit('setBusy');
          return;
        }
      }
      this.SET_USER_STATUS_SESSION_SUB_MODULE([{ status_session: status }]);

      this.A_CHANGE_USER_STATUS_SESSION_SUB_MODULE(params);
      if (status === 3 && [15, 16, 3].includes(this.currentUser.role_id)) {
        this.toogleBusyState();
      }

      if (status === this.G_USER_STATUS_SESSION) return;
      this.SET_USER_STATUS_SESSION([{ status_session: status }]);
      this.A_CHANGE_USER_STATUS_SESSION(params);
    },
  },
  setup() {
    const { isBusy, toogleBusyState } = useAgentAway();

    return {
      isBusy,
      toogleBusyState,
    };
  },
};
</script>
