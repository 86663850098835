<template>
  <div>
    <div @click="showRbNotificationsByModule = true">
      <b-avatar
        :badge="unread_counter > 99 ? '+99' : `${unread_counter}`"
        :src="`/assets/images/icons/authorize.png`"
        class="badge-important-custom clickable"
        :badge-variant="'danger'"
        size="20"
        v-if="unread_counter > 0"
      />
      <b-avatar
        :src="`/assets/images/icons/authorize.png`"
        class="clickable"
        size="20"
        v-else
      />
    </div>
    <RbNotificationList
      :unread_counter="unread_counter"
      v-if="showRbNotificationsByModule"
      @close="showRbNotificationsByModule = false"
      @openRB="updateUnreadCounter"
    />
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import RbNotificationList from "@/layouts/components/navbar/components/notifications/rb-notifications/RbNotificationList.vue";
import NotificationsService from "@/service/notification/index.js";

export default {
  components: {
    RbNotificationList,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showRbNotificationsByModule: false,
      unread_counter: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.updateUnreadCounter();
  },
  methods: {
    async updateUnreadCounter() {
      const { data } = await NotificationsService.getCounterNotifications({
        user_id: this.currentUser.user_id,
      });
      this.unread_counter = data || 0;
    },
  },
};
</script>
<style lang="scss">
.badge-important-custom {
  .b-avatar-badge {
    font-size: 10px !important;
    top: -20% !important;
    right: -20% !important;
    bottom: auto !important;
  }
}
</style>

