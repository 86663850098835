var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-nav-item-dropdown',{staticClass:"dropdown-notification mr-25",attrs:{"menu-class":"dropdown-menu-media","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"CreditCardIcon","size":"21","badge":_vm.automaticPaymentsNotificationCounter > 0
                ? _vm.automaticPaymentsNotificationCounter
                : '',"badgeClasses":{
              'bg-danger': _vm.automaticPaymentsNotificationCounter > 0,
            }}})]},proxy:true}])},[_c('li',{staticClass:"dropdown-menu-header"},[_c('div',{staticClass:"dropdown-header d-flex"},[_c('h4',{staticClass:"notification-title mb-0 mr-auto"},[_vm._v("Automatic Payments")])])]),_c('vue-perfect-scrollbar',{staticClass:"scrollable-container media-list scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"li"}},[_vm._l((_vm.S_NOTIFICATIONS_AUTOMATIC_PAYMENTS),function(notification){return [(
                (notification.type != 11 && notification.type != 0) ||
                _vm.moduleId == 2
              )?_c('b-link',{key:notification.id,attrs:{"to":_vm.notificationRoute(notification),"target":"_blank"},on:{"click":function($event){return _vm.clickReadNotification(notification.id)}}},[_c('b-media',{scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","variant":notification.status == 1
                        ? 'light-secondary'
                        : 'light-primary'}},[_c('feather-icon',{attrs:{"icon":"InfoIcon"}})],1)]},proxy:true}],null,true)},[_c('p',{staticClass:"media-heading"},[_c('span',{staticClass:"font-weight-bolder",domProps:{"innerHTML":_vm._s(notification.notification)}})]),_c('small',{staticClass:"notification-text"},[_vm._v(" "+_vm._s(_vm._f("myDateGlobal")(notification.created_at))+" ")])])],1):_c('div',{key:notification.id,staticClass:"cursor-pointer",on:{"click":function($event){return _vm.openOtherNotification(notification, notification.type)}}},[_c('b-media',{scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","variant":notification.status == 1
                        ? 'light-secondary'
                        : 'light-primary'}},[_c('feather-icon',{attrs:{"icon":"InfoIcon"}})],1)]},proxy:true}],null,true)},[_c('p',{staticClass:"media-heading"},[_c('span',{staticClass:"font-weight-bolder",domProps:{"innerHTML":_vm._s(notification.notification)}})]),_c('small',{staticClass:"notification-text"},[_vm._v(" "+_vm._s(_vm._f("myDateGlobal")(notification.created_at))+" ")])])],1)]})],2),_c('li',{staticClass:"dropdown-menu-footer"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","block":""},on:{"click":function($event){_vm.notificationModal = true}}},[_vm._v("Read all notifications")])],1)],1),_c('b-modal',{attrs:{"title-class":"h3 text-white","size":"lg","centered":"","title":"Automatic Payments","hide-footer":"","modal-class":"modal-primary"},model:{value:(_vm.notificationModal),callback:function ($$v) {_vm.notificationModal=$$v},expression:"notificationModal"}},[_c('notification-list',{on:{"closeNotifications":function($event){_vm.notificationModal = false},"readAllNotificationsByUser":_vm.clickReadAllNotificationsByUser}})],1),_c('b-modal',{attrs:{"modal-class":"modal-primary","title":"Automatic Payments","title-tag":"h3","size":"xlg","hide-footer":""},on:{"hidden":function($event){_vm.showModal = false}},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('AutomaticPayments',{attrs:{"notification":_vm.selectedNotification}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }