import { amgApi } from "@/service/axios";

class RegisterPaymentService {
  url = "/commons/register-payments";

  async getClientsOrLeads(body) {
    try {
      const data = await amgApi.post(`${this.url}`, body);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new RegisterPaymentService();
