<template>
  <div class="x-list">
    <div
      v-if="G_COUNT_MEETINGS"
      left
      toggle-class="d-flex align-items-center dropdown-user-link position-relative"
      class="position-relative"
    >
      <tabler-icon
        v-b-tooltip.hover.top="'Meetings'"
        badge-classes="badge-important"
        class="cursor-pointer mr-1"
        :class="countMeetingToday > 0 ? G_VIEW_MEETINGS ? 'text-warning' : 'text-warning wobble-class' : 'text-primary'"
        icon="AlarmIcon"
        size="25"
        @click="showCalendar"
      />
      <b-badge
        v-if="countMeetingToday"
        variant="warning"
        class="position-absolute rounded-circle"
        style="left:-15px;top: -5px"
      >
        {{ countMeetingToday }}
      </b-badge>
    </div>

    <!-- calendar -->
    <b-modal
      v-model="oncontrol"
      size="xl"
      :header-bg-variant="isDarkSkin ? 'dark' : 'white'"
      :body-bg-variant="isDarkSkin ? 'dark' : 'white'"
      no-close-on-backdrop
      centered
      class="border-0 border-white"
      @hidden="oncontrol = false"
    >
      <calendar-component />
      <template #modal-footer>
        <div />
      </template>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import service from '@/layouts/components/navbar/service/navbar.service';
import CalendarComponent from '@/views/commons/components/projects/components/meetings/CalendarMeetings.vue';

export default {
  components: {
    CalendarComponent,
  },
  data() {
    return {
      showMeeting: false,
      oncontrol: false,
      month: parseInt(moment().format('MM'), 10),
      year: parseInt(moment().format('YYYY'), 10),
      miItems: [],
      countMeetingToday: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      G_COUNT_MEETINGS: 'NotificationStore/G_COUNT_MEETINGS',
      G_VIEW_MEETINGS: 'NotificationStore/G_VIEW_MEETINGS',
    }),
    currentDate() {
      return moment().format('YYYY-MM-DD');
    },
  },
  async created() {
    this.currentMonth = moment().format('MMMM-YYYY');
    this.A_COUNT_MEETINGS({ user_id: this.currentUser.user_id, month: this.month, year: this.year });
    await this.getData();
  },
  methods: {
    ...mapActions({
      A_COUNT_MEETINGS: 'NotificationStore/A_COUNT_MEETINGS',
    }),
    ...mapMutations({
      SET_VIEW_MEETINGS: 'NotificationStore/SET_VIEW_MEETINGS',
    }),
    async showCalendar() {
      this.oncontrol = true;
      this.SET_VIEW_MEETINGS(true);
    },
    async getData() {
      try {
        const params = {
          created_by: this.currentUser.user_id,
          month: this.month,
          year: this.year,
          status: '1,2', // pending and reprogrammed meetings
        };
        const { data } = await service.getMeetings(params);
        const keysDates = Object.keys(data);
        this.countMeetingToday = 0;
        if (keysDates.includes(this.currentDate)) {
          this.countMeetingToday = data[this.currentDate].length;
        }
      } catch (error) {
        console.log('error', error);
      }
    },
  },
};
</script>
<style scoped>
.wobble-class {
  animation: wobble 3.3s ease-in-out infinite;
}

@keyframes wobble {
  0% {
    transform: scale(1)
  }

  45% {
    transform: scale(1.2)
  }

  50% {
    transform: scale(1.2) rotateZ(-35deg)
  }

  53% {
    transform: scale(1.2) rotateZ(35deg)
  }
  56%{
    transform: scale(1.2) rotateZ(-35deg)
  }
  59% {
    transform: scale(1.2) rotateZ(35deg)
  }
  62% {
    transform: scale(1.2) rotateZ(-35deg)
  }
  65% {
    transform: scale(1.2) rotateZ(35deg)
  }
  68% {
    transform: scale(1.2) rotateZ(-35deg)
  }
  70%{
    transform: scale(1.2);
  }
  80%{
    transform: scale(1.2);
  }
  100%{
    transform: scale(1)
  }
}
</style>
