<template>
  <div>
    <!--MODAL DETAILS OF MEETING-->
    <b-modal
      v-model="onControl"
      :header-bg-variant="modalVariant[detailsMeet.status_meeting_id]"
      size="default"
      centered
      scrollable
      hide-footer
      @hidden="hiddenModal"
    >
      <template #modal-title>
        <h5
          class="text-uppercase text-white"
          style="font-weight: bolder !important;"
        >
          DETAILS
        </h5>
      </template>
      <b-card class="p-1 shadow-none">
        <h3 class="mt-1 mb-4 text-uppercase">
          <b>{{ detailsMeet.title }}</b>
        </h3>
        <h5 class="my-2 text-uppercase">
          <b class="mr-3"> date : </b>{{ detailsMeet.date_time | myGlobalDay }}
        </h5>
        <h5 class="my-2 text-uppercase">
          <b class="mr-3">time : </b>{{ detailsMeet.date_time | myTime }}
        </h5>
        <h5 class="my-2 text-uppercase">
          <b class="mr-2">status : </b><b :class="textColor[detailsMeet.status_meeting_id]">{{ detailsMeet.status_meeting }}</b>
        </h5>
      </b-card>
      <template
        v-if="(showButtonCancel || showButtonSuccess) && detailsMeet.status_meeting_id !== 3"
      >
        <div class="d-flex justify-content-end align-items-center w-100">
          <b-button
            v-if="showButtonCancel"
            variant="danger"
            class="mr-1"
            @click="cancelAction"
          >
            <feather-icon icon="XIcon" />
            Cancel
          </b-button>
          <b-button
            v-if="showButtonSuccess"
            variant="success"
            @click="successAction"
          >
            <feather-icon icon="CheckIcon" />
            Complete
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    showButtonSuccess: {
      type: Boolean,
      default: true,
      required: false,
    },
    showButtonCancel: {
      type: Boolean,
      default: false,
      required: false,
    },
    detailsMeet: {
      type: Object,
      default: () => ({
        title: null,
        date_time: null,
        status_meeting_id: null,
        status_meeting: null,
      }),
      required: true,
    },
  },
  data() {
    return {
      onControl: false,
      modalVariant: {
        1: 'warning',
        2: 'info',
        3: 'success',
      },
      textColor: {
        1: 'text-warning',
        2: 'text-info',
        3: 'text-success',
      },
    };
  },
  mounted() {
    this.onControl = true;
  },
  methods: {
    cancelAction() {
      this.$emit('cancelAction', this.details);
    },
    successAction() {
      this.$emit('successAction', this.detailsMeet);
    },
    hiddenModal() {
      this.$emit('hiddenAction');
    },
  },
};
</script>
