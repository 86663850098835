<template>
  <div>
    <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
    >
      <template #button-content>
        <feather-icon
          :badge="G_NOTIFICATION_COUNTER > 99 ? '+99' : G_NOTIFICATION_COUNTER"
          badge-classes="badge-important"
          class="text-body"
          icon="BellIcon"
          size="19"
        />
      </template>

      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
          <b-badge pill variant="light-primary"
            >{{ G_NOTIFICATION_COUNTER }} New</b-badge
          >
        </div>
      </li>

      <!-- Notifications -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <!-- Account Notification -->
        <template v-for="notification in S_NOTIFICATIONS">
          <b-link
            v-if="
              (notification.type != 11 && notification.type != 0) ||
              moduleId == 2
            "
            :key="notification.id"
            :to="notificationRoute(notification)"
            target="_blank"
            @click="clickReadNotification(notification.id)"
          >
            <b-media>
              <template #aside>
                <b-avatar
                  size="32"
                  :variant="
                    notification.status == 1
                      ? 'light-secondary'
                      : 'light-primary'
                  "
                >
                  <feather-icon icon="InfoIcon" />
                </b-avatar>
              </template>
              <p class="media-heading">
                <span
                  class="font-weight-bolder"
                  v-html="notification.notification"
                />
              </p>
              <small class="notification-text">
                {{ notification.created_at | myDateGlobal }}
              </small>
            </b-media>
          </b-link>
          <div
            v-else
            :key="notification.id"
            class="cursor-pointer"
            @click="openOtherNotification(notification, notification.type)"
          >
            <b-media>
              <template #aside>
                <b-avatar
                  size="32"
                  :variant="
                    notification.status == 1
                      ? 'light-secondary'
                      : 'light-primary'
                  "
                >
                  <feather-icon icon="InfoIcon" />
                </b-avatar>
              </template>
              <p class="media-heading">
                <span
                  class="font-weight-bolder"
                  v-html="notification.notification"
                />
              </p>
              <small class="notification-text">
                {{ notification.created_at | myDateGlobal }}
              </small>
            </b-media>
          </div>
        </template>
      </vue-perfect-scrollbar>

      <!-- Cart Footer -->
      <li class="dropdown-menu-footer">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          @click="notificationModal = true"
          >Read all notifications</b-button
        >
      </li>
    </b-nav-item-dropdown>
    <b-modal
      v-model="notificationModal"
      title-class="h3 text-white"
      size="lg"
      centered
      title="Notifications"
      hide-footer
      modal-class="modal-primary"
    >
      <notification-list
        @closeNotifications="notificationModal = false"
        @readAllNotificationsByUser="clickReadAllNotificationsByUser"
      ></notification-list>
    </b-modal>
    <b-modal
      v-model="accountsHoldModal"
      title-class="h3 text-white"
      size="lg"
      centered
      title="NEW CLIENTS STATUS HOLD"
      hide-footer
      modal-class="modal-primary"
    >
      <new-client-status-hold-modal
        :notification="selectedNotification"
        @closeNotifications="accountsHoldModal = false"
      ></new-client-status-hold-modal>
    </b-modal>

    <b-modal
      v-model="showModal"
      modal-class="modal-primary"
      title="Automatic Payments"
      title-tag="h3"
      size="xlg"
      hide-footer
      @hidden="showModal = false"
    >
      <AutomaticPayments :notification="selectedNotification" />
    </b-modal>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import NotificationList from "./components/notifications/NotificationList.vue";
import NewClientStatusHoldModal from "./components/notifications/NewClientStatusHoldModal.vue";
import AutomaticPayments from "@/layouts/components/navbar/components/notifications/AutomaticPayments.vue";

import NotificationService from "@/service/notification/index.js";

export default {
  mounted() {
    if (this.currentUser) {
      this.A_GET_NOTIFICATIONS({ id: this.currentUser.user_id });
    }
  },
  components: {
    VuePerfectScrollbar,
    NotificationList,
    NewClientStatusHoldModal,
    AutomaticPayments,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_NOTIFICATION_COUNTER: "NotificationStore/G_NOTIFICATION_COUNTER",
    }),
    ...mapState({
      S_NOTIFICATIONS: (state) => state.NotificationStore.S_NOTIFICATIONS,
    }),
    notificationRoute: () => (item) => {
      if (!item.module_id) {
        // this.A_UPDATE_NOTIFICATION({
        //   id: item.id,
        //   type: 1,
        // });

        // this.DECREASE_NOTIFICATION_COUNTER();
        return item.link;
      } else {
        // validate target module
        switch (item.module_id) {
          case 26:
            if (item.type == 0) {
              return "/cedigital/sales/calendar";
            } else {
              return "/cedigital/sales/sales-made";
            }
          default:
            return item.link;
        }
      }
    },

    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      notificationModal: false,
      accountsHoldModal: false,
      selectedNotification: {},
      showModal: false,
    };
  },
  methods: {
    ...mapActions({
      A_GET_NOTIFICATIONS: "NotificationStore/A_GET_NOTIFICATIONS",
      A_UPDATE_NOTIFICATION: "NotificationStore/A_UPDATE_NOTIFICATION",
    }),
    ...mapMutations({
      DECREASE_NOTIFICATION_COUNTER:
        "NotificationStore/DECREASE_NOTIFICATION_COUNTER",
    }),
    openOtherNotification(notification, type) {
      this.clickReadNotification(notification.id);
      if (type == 0 && this.moduleId) {
        this.openModalAutomaticPayments(notification);
      } else if (type === 11) {
        this.openModalAccountHold(notification);
      }
    },
    openModalAccountHold(notification) {
      this.accountsHoldModal = true;
      this.selectedNotification = notification;
    },
    openModalAutomaticPayments(notification) {
      this.selectedNotification = notification;
      this.showModal = true;
    },
    async clickReadNotification(id) {
      const { data } = await NotificationService.readNotificationById({
        id: id,
      });
      if (this.currentUser) {
        this.A_GET_NOTIFICATIONS({ id: this.currentUser.user_id });
      }
    },

    clickReadAllNotificationsByUser() {
      if (this.currentUser) {
        this.A_GET_NOTIFICATIONS({ id: this.currentUser.user_id });
      }
    },
  },
};
</script>

<style></style>
