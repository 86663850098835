export default [
    {
        key: "lead",
        label: "lead",
        visible: true,
    },

    {
        key: "sent_date",
        label: "sent date",
        visible: true,
    },

    {
        key: "subject",
        label: "message",
        visible: true,
    },

    {
        key: "actions",
        label: "actions",
        visible: true,
    },

];
