export default [
  {
    key: 'selected',
    label: 'Selecteds',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'name',
    label: 'Employee',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'know_as',
    label: 'Know as',
    thClass: 'text-left',
  },
];
