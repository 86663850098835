export default [
    {
        key: "sms",
        label: "sms",
        visible: true,
    },

    {
        key: "list",
        label: "list",
        visible: true,
    },

    {
        key: "answers",
        label: "answer`s",
        visible: true,
    },

];
