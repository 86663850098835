<template>
  <div>
    <b-nav-item-dropdown
      class="dropdown-meta-inbox mr-25"
      menu-class="dropdown-meta-inbox"
      left
      ref="dropdown"
    >
      <template #button-content>
        <div style="position: relative">
          <img
            src="@/assets/images/icons/iconoMessenger.svg"
            style="width: 20px; height: 20px"
            class=""
            alt=""
          />
          <feather-icon
            icon
            :badge="getTotalUnreadCount"
            badge-classes="badge-danger"
          />
        </div>
      </template>

      <!-- Header -->
      <li class="dropdown-menu-header meta-inbox-list">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">Meta Inbox</h4>
        </div>
      </li>

      <!-- Notifications -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <!-- Account Notification -->
        <div
          class="meta-inbox-list"
          v-for="(item, index) in G_FB_PAGES"
          :key="index"
        >
          <div
            class="meta-item"
            :class="{
              active:
                G_CURRENT_FB_PAGE.id === item.id &&
                currentRoute == 'metamedia-messenger',
            }"
          >
            <div class="m-1">
              <b-avatar
                size="32"
                variant="light-secondary"
                class="cursor-pointer"
                :src="item.picture"
              >
              </b-avatar>
            </div>
            <div class="container-router-link">
              <div class="align-self-center mx-1">
                <router-link
                  class="text-muted"
                  :to="{
                    name: 'metamedia-messenger',
                  }"
                  @click.native="goToMessenger(item)"
                >
                  <span
                    class="font-weight-bolder align-self-center"
                    :class="
                      G_CURRENT_FB_PAGE.id == item.id &&
                      currentRoute == 'metamedia-messenger'
                        ? 'text-primary'
                        : ''
                    "
                  >
                    {{ item.name }}
                  </span>
                </router-link>
              </div>
              <b-badge
                v-if="getUnreadCountByPage(item.id)"
                variant="danger"
                class="unread-count"
              >
                {{ getUnreadCountByPage(item.id) }}
              </b-badge>
            </div>
          </div>
        </div>
      </vue-perfect-scrollbar>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions, mapMutations } from "vuex";
import NestNotification from "@/socket/nest.notification.js";

export default {
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      socket: null,
    };
  },
  computed: {
    ...mapGetters({
      G_FB_PAGES: "MessengerMetaStore/G_FB_PAGES",
      G_CURRENT_FB_PAGE: "MessengerMetaStore/G_CURRENT_FB_PAGE",
      G_INSTAGRAM_UNREAD_CHATS_COUNTER:
        "MessengerMetaStore/G_INSTAGRAM_UNREAD_CHATS_COUNTER",
      G_MESSENGER_UNREAD_CHATS_COUNTER:
        "MessengerMetaStore/G_MESSENGER_UNREAD_CHATS_COUNTER",
    }),
    getTotalUnreadCount() {
      let total = 0;
      total += this.G_MESSENGER_UNREAD_CHATS_COUNTER.reduce(
        (acc, item) => acc + item.count,
        0
      );

      total += this.G_INSTAGRAM_UNREAD_CHATS_COUNTER.reduce(
        (acc, item) => acc + item.count,
        0
      );

      total = total < 100 ? total : "99+";
      return total;
    },

    currentRoute() {
      return this.$route.name;
    },
  },
  async created() {
    this.socket = NestNotification.initWebsocket();
    await this.A_GET_FB_PAGES();
    await this.A_SET_MESSENGER_UNREAD_CHATS_COUNTER();
    await this.A_SET_INSTAGRAM_UNREAD_CHATS_COUNTER();
  },
  mounted() {
    this.socketOn();
  },
  methods: {
    ...mapActions({
      A_GET_FB_PAGES: "MessengerMetaStore/A_GET_FB_PAGES",
      A_SET_CURRENT_FB_PAGE: "MessengerMetaStore/A_SET_CURRENT_FB_PAGE",
      A_SET_MESSENGER_UNREAD_CHATS_COUNTER:
        "MessengerMetaStore/A_SET_MESSENGER_UNREAD_CHATS_COUNTER",
      A_SET_INSTAGRAM_UNREAD_CHATS_COUNTER:
        "MessengerMetaStore/A_SET_INSTAGRAM_UNREAD_CHATS_COUNTER",
    }),
    ...mapMutations({
      M_SET_CURRENT_INSTAGRAM_COMMENT_CHAT:
        "MessengerMetaStore/M_SET_CURRENT_INSTAGRAM_COMMENT_CHAT",
      M_SET_CURRENT_FB_COMMENT_CHAT:
        "MessengerMetaStore/M_SET_CURRENT_FB_COMMENT_CHAT",
      M_SET_MESSENGER_UNREAD_CHATS_COUNTER:
        "MessengerMetaStore/M_SET_MESSENGER_UNREAD_CHATS_COUNTER",
      M_SET_INSTAGRAM_UNREAD_CHATS_COUNTER:
        "MessengerMetaStore/M_SET_INSTAGRAM_UNREAD_CHATS_COUNTER",
    }),
    handleChangeProgram(item) {
      this.A_SET_CURRENT_FB_PAGE(item);
      // reset current chats on change tab
      this.M_SET_CURRENT_INSTAGRAM_COMMENT_CHAT(null);
      this.M_SET_CURRENT_FB_COMMENT_CHAT(null);
    },
    goToMessenger(page) {
      this.handleChangeProgram(page);
      this.$nextTick(() => {
        this.$refs.dropdown.hide();
      });
    },
    getUnreadCountByPage(pageId) {
      let total = 0;

      total += this.messengerFindPageById(pageId)?.count || 0;

      total += this.instagramFindPageById(pageId)?.count || 0;

      total = total < 100 ? total : "+99";
      return total;
    },
    socketOn() {
      this.socket.on("meta-messenger", (data) => {
        const { chat, message, unreadCount } = data;

        if (!this.shouldProcessMessage(unreadCount)) return;

        this.updateUnreadCount(chat, message, unreadCount, "messenger");

        this.M_SET_MESSENGER_UNREAD_CHATS_COUNTER(
          this.G_MESSENGER_UNREAD_CHATS_COUNTER
        );
      });

      this.socket.on("meta-instagram", (data) => {
        const { chat, message, unreadCount } = data;

        if (!this.shouldProcessMessage(unreadCount)) return;

        this.updateUnreadCount(chat, message, unreadCount, "instagram");

        this.M_SET_INSTAGRAM_UNREAD_CHATS_COUNTER(
          this.G_INSTAGRAM_UNREAD_CHATS_COUNTER
        );
      });
    },
    shouldProcessMessage(unreadCount) {
      return !!unreadCount;
    },
    updateUnreadCount(chat, message, unreadCount, platform) {
      const page = this.findPageByPlatform(chat.pageId, platform);
      if (!page) return;
      const { messageStatus, me } = message;

      switch (messageStatus) {
        case "Sent":
          this.updateCountForSentMessage(page, me, unreadCount);
          break;
        case "Read":
          this.updateCountForReadMessage(page, me, unreadCount);
          break;
        default:
          break;
      }
    },
    findPageByPlatform(pageId, platform) {
      return platform === "messenger"
        ? this.messengerFindPageById(pageId)
        : this.instagramFindPageById(pageId);
    },
    updateCountForSentMessage(page, isMessageFromMe, unreadCount) {
      page.count += isMessageFromMe ? -unreadCount : unreadCount;
      page.count = page.count < 0 ? 0 : page.count;
    },
    updateCountForReadMessage(page, isMessageFromMe, unreadCount) {
      if (isMessageFromMe) return;
      page.count -= unreadCount;
      page.count = page.count < 0 ? 0 : page.count;
    },
    messengerFindPageById(pageId) {
      return this.G_MESSENGER_UNREAD_CHATS_COUNTER.find(
        (page) => page._id === pageId
      );
    },
    instagramFindPageById(pageId) {
      return this.G_INSTAGRAM_UNREAD_CHATS_COUNTER.find(
        (page) => page._id === pageId
      );
    },
  },
  beforeDestroy() {
    this.socket.disconnect();
  },
};
</script>

<style lang="scss" scoped>
.meta-inbox-list {
  width: 220px;
  .meta-item {
    display: flex;
    &:hover {
      background-color: #0099dd11;
    }
    &.active {
      border-left: 4px solid #0099dd;
      background-color: #0099dd11;
    }
  }
}
.container-router-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 0.5rem;
  .unread-count {
    border-radius: 50%;
  }
}
</style>