var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"my-2"},[_c('b-col',{staticClass:"\n        d-flex\n        align-items-center\n        justify-content-center justify-content-sm-start\n      ",attrs:{"sm":"12","md":"12","xl":"6"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-backdrop",modifiers:{"sidebar-backdrop":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.getMoreInfo()}}},[_c('feather-icon',{staticClass:"mr-50 text-center cursor-pointer",attrs:{"icon":"InfoIcon"}}),_vm._v("MORE INFO ")],1)],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-end align-items-center",attrs:{"sm":"12","md":"12","xl":"2"}},[_c('b-form-group',{staticStyle:{"width":"100%"},attrs:{"label":"From","label-for":"from"}},[_c('b-form-datepicker',{attrs:{"id":"from","date-format-options":{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }},model:{value:(_vm.filter.from),callback:function ($$v) {_vm.$set(_vm.filter, "from", $$v)},expression:"filter.from"}})],1)],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-end align-items-center",attrs:{"sm":"12","md":"12","xl":"2"}},[_c('b-form-group',{staticStyle:{"width":"100%"},attrs:{"label":"To","label-for":"to"}},[_c('b-form-datepicker',{attrs:{"id":"to","date-format-options":{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }},model:{value:(_vm.filter.to),callback:function ($$v) {_vm.$set(_vm.filter, "to", $$v)},expression:"filter.to"}})],1)],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-end align-items-center",attrs:{"sm":"12","md":"12","xl":"2"}},[_c('b-form-group',{staticStyle:{"width":"100%"},attrs:{"label":"Module","label-for":"modul"}},[_c('b-form-select',{attrs:{"id":"modul","options":_vm.moduls},model:{value:(_vm.filter.modulId),callback:function ($$v) {_vm.$set(_vm.filter, "modulId", $$v)},expression:"filter.modulId"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-table-simple',{ref:"table_report_modul",staticClass:"table-report-schedules",attrs:{"bordered":"","small":"","caption-top":"","responsive":""}},[_c('b-thead',[_c('b-tr',_vm._l((_vm.fields),function(item,index){return _c('b-th',{key:index,staticClass:"text-center item-header"},[_vm._v(_vm._s(item.label))])}),1)],1),_c('b-tbody',_vm._l((_vm.items),function(item,index){return _c('b-tr',{key:index,staticClass:"text-center"},_vm._l((item.json_data),function(schedule,i){return _c('b-td',{key:i},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:[
                  _vm.bgSchedule(schedule),
                  'item-hour',
                  _vm.isDarkTheme ? 'item-hour-dark' : 'item-hour-light',
                  schedule.date == _vm.today ? 'bg-hour-today' : '' ],attrs:{"title":_vm.titleSchedule(schedule, 1)},on:{"click":function($event){return _vm.openDetails(item, schedule, schedule.checking_m, 1)}}},[_vm._v(" "+_vm._s(schedule.checking_m)+" ")]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:[
                  'bg-default item-hour',
                  _vm.isDarkTheme ? 'item-hour-dark' : 'item-hour-light',
                  schedule.date == _vm.today ? 'bg-hour-today ' : '' ],attrs:{"title":_vm.titleSchedule(schedule, 2)},on:{"click":function($event){return _vm.openDetails(item, schedule, schedule.m_begining_break, 2)}}},[_vm._v(" "+_vm._s(schedule.m_begining_break)+" ")]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:[
                  'bg-default item-hour',
                  _vm.isDarkTheme ? 'item-hour-dark' : 'item-hour-light',
                  schedule.date == _vm.today ? 'bg-hour-today ' : '' ],attrs:{"title":_vm.titleSchedule(schedule, 3)},on:{"click":function($event){return _vm.openDetails(item, schedule, schedule.m_finish_break, 3)}}},[_vm._v(" "+_vm._s(schedule.m_finish_break)+" ")]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:[
                  'bg-default item-hour',
                  _vm.isDarkTheme ? 'item-hour-dark' : 'item-hour-light',
                  schedule.date == _vm.today ? 'bg-hour-today ' : '' ],attrs:{"title":_vm.titleSchedule(schedule, 4)},on:{"click":function($event){return _vm.openDetails(item, schedule, schedule.m_checkout, 4)}}},[_vm._v(" "+_vm._s(schedule.m_checkout)+" ")]),_c('div',{class:[
                  'item-count-hours',
                  _vm.isDarkTheme ? 'text-light' : '',
                  schedule.date == _vm.today
                    ? 'bg-count-hours-today'
                    : 'bg-hours' ]},[_vm._v(" HOURS: "+_vm._s(schedule.total_hours)+" ")])])}),1)}),1)],1)],1)],1),_c('b-modal',{attrs:{"title":_vm.titleModal,"size":"lg","body-class":"p-0","title-class":"title-assitence-schedule","hide-footer":""},model:{value:(_vm.modalDetails),callback:function ($$v) {_vm.modalDetails=$$v},expression:"modalDetails"}},[_c('b-container',{staticClass:"p-4",attrs:{"fluid":""}},[_c('div',{staticClass:"text-center"},[_c('b-img',{staticClass:"image-captured",attrs:{"src":_vm.detailsSchedule.image,"fluid":"","alt":"Responsive image"}})],1),_c('b-row',{staticClass:"py-2"},[_c('b-col',{attrs:{"cols":"auto"}},[_c('feather-icon',{staticClass:"mr-50 text-center",attrs:{"icon":"CheckCircleIcon"}}),_vm._v(" ASSIGNED: "+_vm._s(_vm.detailsSchedule.assigned)+" ")],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('feather-icon',{staticClass:"mr-50 text-center",attrs:{"icon":"CheckCircleIcon"}}),_vm._v(" ASSISTANCE: "+_vm._s(_vm.detailsSchedule.assistance)+" ")],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('feather-icon',{staticClass:"mr-50 text-center",attrs:{"icon":"CheckCircleIcon"}}),_vm._v(" IP ADDRESS: "+_vm._s(_vm.detailsSchedule.ip)+" ")],1)],1)],1)],1),_c('b-sidebar',{attrs:{"id":"sidebar-backdrop","title":"More info","bg-variant":"white","sidebar-class":"sidebar-lg-schedule sidebar-schedules","header-class":"pt-1","backdrop":"","shadow":""}},[_c('div',{staticClass:"px-3 py-2"},[_c('content-more-info-report',{key:_vm.keyMoreInfo,attrs:{"data":_vm.dataSchedule}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }