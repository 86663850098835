/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

class MeetingService {
  async getMeetings(payload) {
    try {
      const data = await amgApi.post('/miro/meetings/get-meetings', payload);
      return data;
    } catch (error) {
      console.log('error to get meetings', error);
    }
  }

  async getMeetingById(payload) {
    try {
      const data = await amgApi.post('/miro/meetings/get-meetings-by-id', payload);
      return data;
    } catch (error) {
      console.log('error to get meetings', error);
    }
  }

  async insertMeeting(payload) {
    try {
      const data = await amgApi.post('/miro/meetings/insert-meeting', payload);
      return data;
    } catch (error) {
      console.log('error to get meetings', error);
    }
  }

  async approveDisapproveProjectByMeet(payload) {
    try {
      const data = await amgApi.post('/miro/meetings/approve-disapprove-project-by-meet', payload);
      return data;
    } catch (error) {
      console.log('error to approveDisapproveProjectByMeet', error);
    }
  }

  async getTrackingMeeting(payload) {
    try {
      const data = await amgApi.post('/miro/meetings/get-tracking-meeting', payload);
      return data;
    } catch (error) {
      console.log('error to get tracking of meeting', error);
    }
  }

  async getMeetingsType() {
    try {
      const data = await amgApi.post('/miro/meetings/list-meetings-type', {});
      return data;
    } catch (error) {
      console.log('error to list meeting type', error);
    }
  }

  async listMeetingsByProject(payload) {
    try {
      const data = await amgApi.post('/miro/meetings/list-meetings-by-project', payload);
      return data;
    } catch (error) {
      console.log('error to list meeting type', error);
    }
  }

  async getObservationsList() {
    try {
      const data = await amgApi.post('/miro/meetings/list-observations');
      return data;
    } catch (error) {
      console.log('error to list meeting type', error);
    }
  }

  async postObservation(params) {
    try {
      const data = await amgApi.post('/miro/meetings/insert-observation', params);
      return data;
    } catch (error) {
      console.log('error to list meeting type', error);
    }
  }

  async getConfirmAttendance(params) {
    try {
      const data = await amgApi.post('/miro/meetings/get-attendance', params);
      return data;
    } catch (error) {
      console.log('error to get attendance', error);
    }
  }

  async postConfirmAttendance(params) {
    try {
      const data = await amgApi.post('/miro/meetings/confirm-attendance', params);
      return data;
    } catch (error) {
      console.log('error to confirm attendance', error);
    }
  }

  // getMeetingCreatedBy get-meeting-created-by

  async getMeetingCreatedBy(params) {
    try {
      const data = await amgApi.post('/miro/meetings/get-meeting-created-by', params);
      return data;
    } catch (error) {
      console.log('error to confirm attendance', error);
    }
  }
}

export default new MeetingService();
