var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"x-list"},[_c('b-nav-item-dropdown',{attrs:{"left":"","toggle-class":"d-flex align-items-center dropdown-user-link"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"position-relative"},[_c('feather-icon',{staticClass:"cursor-pointer",class:_vm.G_COUNT_EMPLOYEES_WITHOUT_SCHEDULE
              ? _vm.isValidRange
                ? 'blink text-danger'
                : 'text-danger'
              : _vm.isValidRange
              ? 'blink text-primary'
              : 'text-primary',attrs:{"badge-classes":"badge-important","icon":"CalendarIcon","size":"20"}}),(_vm.isValidRange)?_c('strong',{staticClass:"bg-danger rounded-sm text-white",staticStyle:{"padding":"1px 5px","font-size":"8px","position":"absolute","top":"-13px","left":"-5px"}},[_vm._v("New")]):_vm._e()],1)]},proxy:true}])},_vm._l((_vm.attendanceChiefDashboard),function(ref,index){
              var module_name = ref.module_name;
              var module_id = ref.module_id;
              var module_icon = ref.module_icon;
return _c('b-dropdown-item',{key:index,attrs:{"to":{ name: _vm.attendanceDashboardsPathNames[module_id] }}},[_c('div',{staticClass:"d-flex"},[_c('div',[_c('img',{staticClass:"mr-2",class:[3, 5, 6, 7, 8, 11, 12, 15].includes(module_id)
                ? ''
                : 'gray-home',staticStyle:{"width":"auto"},attrs:{"src":_vm.baseUrl + module_icon,"alt":"Logo","height":"16"}})]),_c('span',{staticClass:"text-center",staticStyle:{"padding-top":"2px"}},[_vm._v(_vm._s(module_id != 2 ? _vm.capitalizeWord(module_name) : module_name)+" "+_vm._s(module_id == 21 ? "Digital" : "")+" ")])])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }