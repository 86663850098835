<template>
  <div class="mr-1" @click="redirectToTicket">
    <feather-icon
      icon="AlertCircleIcon"
      class="text-body cursor-pointer mr-25"
      size="23"
      v-b-tooltip.hover.top="'Paragon Tickets'"
      :badge="computedCounter > 99 ? '+99' : computedCounter"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
export default {
  mounted() {
    this.getCountTab();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      counter: "HelpdeskStore/G_COUNTER_NOTIFICATIONS",
      onReviewCount: "HelpdeskStore/G_REVIEWER_COUNTER_NOTIFICATIONS",
    }),
    computedCounter() {
      let counter = this.counter || 0;
      let counterReview = this.onReviewCount || 0;
      return counter + counterReview;
    },
    routeTickets() {
      const routes = {
        2: "helpdesk-crm",
        3: "helpdesk-bussiness",
        4: "helpdesk-administration",
        5: "helpdesk-debt-solution",
        6: "helpdesk-creditexperts",
        7: "helpdesk-boostcredit",
        8: "helpdesk-tax-research",
        11: "analyst-department-helpdesk",
        12: "helpdesk-ti",
        14: "helpdesk-bookeeping",
        16: "helpdesk-management",
        17: "helpdesk-rrhh",
      };
      const inModule = Object.keys(routes).includes(this.currentUser.modul_id);
      const { modul_id, main_module } = this.currentUser;
      const routeName = inModule
        ? routes[modul_id || main_module]
        : routes[main_module];
      return routeName;
    },
  },
  methods: {
    ...mapMutations({
      refreshTabsCounters: "HelpdeskStore/SET_COUNTER_NOTIFICATIONS",
      refreshTabOnReview: "HelpdeskStore/SET_REVIEWER_COUNTER_NOTIFICATIONS",
    }),
    async getCountTab() {
      const params = {
        user_id: this.currentUser.user_id,
        modul_id: this.currentUser.modul_id,
      };
      const result = await helpdeskService.getCountTab(params);
      this.refreshTabsCounters(result.data.total);
    },
    redirectToTicket() {
      if (this.$router.currentRoute?.name === this.routeTickets) return;
      this.$router.push({
        name: this.routeTickets,
      });
    },
  },
};
</script>

<style>
</style>