<template>
  <div>
    <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
    >
      <template #button-content>
        <feather-icon
          :key="key"
          v-b-tooltip.hover.top="'Customer Ticket Notifications'"
          icon="AlertTriangleIcon"
          size="23"
          class="text-body cursor-pointer"
          style="z-index: 2"
          :badge="
            pendingTicketsCustomer.length > 99
              ? '+99'
              : pendingTicketsCustomer.length
          "
        />
      </template>
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            Customer Ticket Notifications
          </h4>
          <b-badge pill variant="light-primary"
            >{{ pendingTicketsCustomer.length }} New</b-badge
          >
        </div>
      </li>
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <template>
          <b-media
            v-for="(notification, notificationIndex) in pendingTicketsCustomer"
            :key="notificationIndex"
          >
            <template #aside>
              <b-avatar
                size="32"
                :variant="getIconColor(notification.priority)"
              >
                <feather-icon icon="AlertCircleIcon" />
              </b-avatar>
            </template>
            <p class="media-heading">
              <span> Ticket: </span>
              <span v-if="notification.code" class="font-weight-bolder">{{
                notification.code
              }}</span>
              <br />
            </p>
            <div v-if="notification.client">
              <span>Client Account:</span>
              <span>{{ notification.client }}</span>
              <br />
            </div>
            <template
              v-if="
                !notification.expense_id && !notification.expense_transaction_id
              "
            >
              <span v-if="notification.client">Client Name: </span>
              <span v-else>Lead Name: </span>
              <span
                class="font-weight-bolder"
                v-html="notification.clientLeadName"
              />
            </template>
            <template v-else>
              <span v-if="notification.expense_id">Expense</span>
              <span v-if="notification.expense_transaction_id"
                >Imported Transaction</span
              >
              <span> Inconsistent</span>
            </template>
            <br />
            <small>New {{ notification.pendingMessages }} messages</small>
            <br />
            <div class="d-flex justify-content-between flex-row">
              <b-button
                variant="success"
                style="width: auto"
                @click="openTicketChat(notification)"
                >Open Chat</b-button
              >
              <b-button
                variant="primary"
                :to="{
                  name: redirectTicket(),
                }"
              >
                View Tickets
              </b-button>
            </div>
          </b-media>
        </template>
      </vue-perfect-scrollbar>
    </b-nav-item-dropdown>
    <ticket-customer-chat
      v-if="showTicketCustomer"
      :customer-ticket-chat="customerTicketChat"
      :file-type="fileType"
      @close="showTicketCustomer = false"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import NotificationsService from "@/layouts/components/navbar/service/navbar.service.js";
import TicketCustomerChat from "@/views/commons/components/customer-tickets/chat-components/TicketCostumerChat.vue";
import CustomerTicketsService from "@/views/commons/components/customer-tickets/services/customer-tickets.service";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    TicketCustomerChat,
    VuePerfectScrollbar,
  },
  data() {
    return {
      notificationsData: [],
      customerTicketChat: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      showTicketCustomer: false,
      fileType: {},
      key: 0,
      moduleIdToRouteMap: {
        2: "crm-customer-tickets",
        3: "business-customer-tickets",
        4: "administration-customer-tickets",
        5: "debt-solution-customer-tickets",
        6: "credit-experts-customer-tickets",
        7: "boost-credit-customer-tickets",
        8: "tax-research-customer-tickets",
        9: "correspondence-customer-tickets",
        10: "court-info-customer-tickets",
        11: "analyst-department-customer-tickets",
        12: "paragon-customer-tickets",
        14: "bookeeping-customer-tickets",
        15: "social-network-customer-tickets",
        16: "management-customer-tickets",
        17: "rrhh-customer-tickets",
        18: "quality-customer-tickets",
        19: "logistic-customer-tickets",
        20: "connection-customer-tickets",
        22: "customer-service-customer-tickets",
        23: "financial-customer-tickets",
        25: "specialist-digital-customer-tickets",
        26: "sales-customer-tickets",
        27: "creative-customer-tickets",
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      pendingTicketsCustomer: "NotificationStore/G_PENDING_CUSTOMER_TICKETS",
    }),
  },
  mounted() {
    // this.$store.dispatch('NotificationStore/A_USERS_AVAILABILITY_CUSTOMER_TICKETS');
    this.setPendingTicketsCustomer();
  },
  methods: {
    redirectTicket() {
      const { user_id, modul_id, main_module, arrRoles } = this.currentUser;
      if (
        this.$store.getters[
          "NotificationStore/G_USERS_AVAILABILITY_CUSTOMER_TICKETS"
        ].includes(user_id)
      ) {
        return this.moduleIdToRouteMap[main_module]; // validacion para los usuarios de bussines Alvaro, Alessia, Luciana
      }
      const resultado = arrRoles.find(
        (rol) =>
          (rol.role_id === 2 || rol.role_id === 6) &&
          rol.module_id !== 24 &&
          rol.user_id !== 38
      );
      return this.moduleIdToRouteMap[resultado.module_id];
    },
    async getCustomerTicketsNotifications() {
      const params = {
        userId: this.currentUser.user_id,
      };
      await NotificationsService.getCustomerTicketsNotifications(params);
    },
    ...mapActions({
      setPendingTicketsCustomer: "NotificationStore/A_PENDING_CUSTOMER_TICKETS",
    }),
    getIconColor(priority) {
      if (priority === "LOW") {
        return "success";
      }
      if (priority === "MEDIUM") {
        return "warning";
      }
      if (priority === "HIGH") {
        return "danger";
      }
      return "primary";
    },
    async openTicketChat(notification) {
      this.addPreloader();
      this.getCustomerTicketsNotifications();
      const params = {
        client_account: null,
        from: null,
        module_id: null,
        name_text: "",
        order: "DESC",
        orderBy: 10,
        page: 1,
        perPage: 50,
        priority: null,
        program_id: null,
        sortBy: "",
        sortOrder: "ASC",
        state_ticket: null,
        status: null,
        tab: 7,
        to: null,
        user_id: 1,
        ticketChat: notification.customerId,
        in_out: null,
      };
      const data = await CustomerTicketsService.getCustomerTickets(params);
      console.log(data);
      this.customerTicketChat = data.data.data[0];
      this.removePreloader();
      this.showTicketCustomer = true;
    },
  },
};
</script>
<style lang="scss" scoped></style>
