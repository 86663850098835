<template>
  <b-modal
    ref="modalRbNotifications"
    size="xmd"
    @hidden="closeModal()"
    hide-footer
    title="Recurring Billing Notifications"
    title-class="h3 text-white font-weight-bolder"
    scrollable
  >
    <b-nav card-header pills class="m-0">
      <b-nav-item
        v-for="(item, index) in tabs"
        :key="index"
        :link-classes="['px-3', bgTabsNavs]"
        :active="activeTab == item"
        @click="(activeTab = item), $refs['notificationList'].refresh()"
      >
        {{ item }}

        <span class="ml-2" v-if="unread_counter > 0 && item == 'Unread'">
          <feather-icon
            icon
            :badge="unread_counter <= 99 ? unread_counter : '99+'"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <filter-slot
        :filter="[]"
        :total-rows="FiltersForSlot.totalRows"
        :paginate="FiltersForSlot.paginate"
        :start-page="FiltersForSlot.startPage"
        :to-page="FiltersForSlot.toPage"
        :filter-principal="FiltersForSlot.filterPrincipal"
        @reload="$refs['notificationList'].refresh()"
        class="filter-slot-new-customization"
        :top-paginate="false"
      >
        <b-table
          ref="notificationList"
          slot="table"
          class="blue-scrollbar position-relative table-new-customization"
          :items="myProvider"
          :fields="visibleFields"
          :busy="isBusy"
          primary-key="authorizel_id"
          responsive="md"
          sticky-header="50vh"
          show-empty
          no-provider-filtering
          :per-page="FiltersForSlot.paginate.perPage"
          :current-page="FiltersForSlot.paginate.currentPage"
        >
          <template #cell(client)="data">
            <div @click="seenRbNotification(data.item, activeTab)">
              <router-link
                class="font-weight-bolder"
                target="_blank"
                :to="
                  routerDashboardRedirectioner(
                    data.item.program_id,
                    data.item.client_account_id
                  )
                "
              >
                {{ data.item.client }} <br />
                {{ data.item.account }}
              </router-link>
            </div>
          </template>
          <template #cell(authorizel_id)="data">
            <span class="font-weight-bolder">
              {{
                data.item.authorizel_id == null ? "-" : data.item.authorizel_id
              }}
              [{{ data.item.plan_day_payment }}]
            </span>
          </template>
          <template #cell(amount)="data">
            <span class="text-success font-weight-bolder"
              ><span class="text-success font-weight-bolder"> $ </span
              >{{ data.item.amount | currencyZero }}</span
            >
          </template>
          <template #cell(status)="data">
            <b-badge variant="light-danger text-uppercase">{{
              data.item.previous_status
            }}</b-badge>
          </template>
          <template #cell(canceled_at)="data">
            <span class="font-weight-bolder">{{
              data.item.created_at | myGlobalWithHour
            }}</span>
          </template>
          <template #cell(start_date)="data">
            <span class="font-weight-bolder">
              {{
                `${data.item.month_payment}/${data.item.day_payment}/${data.item.year_payment}`
              }}
            </span>
          </template>
          <template #cell(viewed_at)="data">
            <span class="font-weight-bolder">
              {{ data.item.viewed_by }}
              <br />
              {{ data.item.viewed_at | myGlobalWithHour }}
            </span>
          </template>
        </b-table>
      </filter-slot>
    </b-card>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import NotificationsService from "@/service/notification/index.js";
export default {
  props: {
    unread_counter: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tabs: ["Unread", "Read"],
      activeTab: "Unread",
      notifications: [],
      Fields: [
        {
          key: "client",
          label: "Client",
          visible: true,
        },
        {
          key: "authorizel_id",
          label: "Subscription ID",
          tdClass: "text-center",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "amount",
          label: "Amount",
          visible: true,
        },
        {
          key: "status",
          label: "Status",
          tdClass: "text-center",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "start_date",
          label: "Start Date",
          tdClass: "text-center",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "canceled_at",
          label: "Canceled At",
          tdClass: "text-center",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "viewed_at",
          label: "Viewed At",
          tdClass: "text-center",
          thClass: "text-center",
          visible: false,
        },
      ],
      FiltersForSlot: {
        totalRows: 0,
        paginate: {
          perPage: 10,
          currentPage: 1,
        },
        startPage: 1,
        toPage: 1,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search by name...",
          model: "",
        },
      },
      isBusy: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      // if active tab is read, then show viewed_at field else hide it
      let fields = this.Fields.map((field) => {
        field.visible =
          this.activeTab == "Unread" ? field.key !== "viewed_at" : true;
        return field;
      });
      return fields.filter((field) => field.visible);
    },
  },
  mounted() {
    this.toggleModal("modalRbNotifications");
  },
  created() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async myProvider() {
      try {
        this.addPreloader();
        const params = {
          user_id: this.currentUser.user_id,
          tab: this.activeTab,
          per_page: this.FiltersForSlot.paginate.perPage,
          page: this.FiltersForSlot.paginate.currentPage,
          search: this.FiltersForSlot.filterPrincipal.model,
        };
        const { data } = await NotificationsService.getRbNotificationByModuleId(
          params
        );
        this.notifications = data.data;
        this.FiltersForSlot.totalRows = data.total;
        return this.notifications || [];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async seenRbNotification(item, activeTab) {
      if (activeTab == "Read") {
        return;
      }
      try {
        const params = {
          authorizel_id: item.authorizel_id,
          user_id: this.currentUser.user_id,
        };
        await NotificationsService.seenRbNotification(params);
        this.$refs["notificationList"].refresh();
        this.$emit("openRB");
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style>
</style>