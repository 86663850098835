var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canViewVerticalNavMenuGroup(_vm.item) && _vm.checkPermittedRoles)?_c('li',{staticClass:"nav-item has-sub",class:{
    open: _vm.isOpen,
    disabled: _vm.item.disabled,
    'sidebar-group-active': _vm.isActive,
  }},[_c('b-link',{staticClass:"d-flex align-items-center",on:{"click":function () { return _vm.updateGroupOpen(!_vm.isOpen); }}},[(_vm.item.icon)?_c('tabler-icon',{attrs:{"icon":_vm.item.icon || 'CircleIcon',"badge":!_vm.isMouseHovered &&
        _vm.isVerticalMenuCollapsed &&
        (_vm.currentBreakPoint == 'xl' || _vm.currentBreakPoint == 'xxl')
          ? _vm.item.tag && _vm.item.tag > 0
            ? _vm.item.tag
            : 0
          : 0,"badge-classes":"bg-danger"}}):_vm._e(),_c('span',{staticClass:"menu-title text-truncate"},[_vm._v(_vm._s(_vm.t(_vm.item.title)))]),(_vm.item.tag && _vm.item.tag > 0)?_c('b-badge',{staticClass:"mr-1 ml-auto",attrs:{"pill":"","variant":_vm.item.tagVariant || 'danger'}},[_vm._v(_vm._s(_vm.item.tag)+" ")]):_vm._e()],1),_c('b-collapse',{staticClass:"menu-content",attrs:{"tag":"ul"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},_vm._l((_vm.item.children.filter(function (child) {
        if (child.title === 'Customer Tickets') {
          // Only Customer Tickets
          return (
            child.permittedRoles.includes(_vm.role_id) ||
            _vm.permittedUserscustomerTickets.includes(_vm.user_id)
          ); // Users availability customer tickets
        } else if (child.permittedRoles) {
          return child.permittedRoles.includes(_vm.role_id);
        }
        return true;
      })),function(child){return _c(_vm.resolveNavItemComponent(child),{key:child.route,ref:"groupChild",refInFor:true,tag:"component",attrs:{"item":child,"is-mouse-hovered":_vm.isMouseHovered,"is-vertical-menu-collapsed":_vm.isVerticalMenuCollapsed}})}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }