<template>
  <div
    class="container-wrapper"
    :class="darkSkin ? 'dark-skin':'light-skin'"
  >
    <div
      class="wrapper-head"
      style="min-width: 1000px;border-radius: 5px 5px 0px 0px;"
    >
      <!-- Names of Date -->
      <div
        v-for="date in days"
        :key="date"
        :class="colorVariant"
        class="position-relative"
        style="color: #34495e; padding: 15px"
      >
        <strong
          class="day-name"
          style="-webkit-text-stroke: 0.5px; letter-spacing: 2px"
        >{{
          date
        }}</strong>
      </div>
    </div>
    <div
      v-if="miItems.length"
      class="wrapper"
      style="min-width: 1000px;"
    >
      <!--BODY CALENDAR-->
      <!-- GENERATE SPACES EMPTY until first day of month-->
      <div
        v-for="(i) in spaceEmpty"
        :key="(i * -1)"
        class="w-100"
        style="background-color: transparent !important"
      />
      <!-- GENERATE THE MEETS-->
      <div
        v-for="(item, index) in miItems"
        :key="index"
        class="position-relative item-date"
        :class="colorVariant"
        :style="index+1 === currentDate ? 'border:2px solid currentColor;border-radius: 5px;':''"
      >
        <feather-icon
          v-if="index+1 === currentDate"
          icon="MapPinIcon"
          class="text-danger rounded-circle position-absolute jump"
          size="25"
          style="top: 15px !important;right: 10px !important;"
        />
        <strong
          class="day-number d-flex mt-1 ml-1 mr-1"
          style="font-size: 30px;"
        >{{ index + 1 > 9 ? index + 1 : `0${index + 1}`
        }}</strong>

        <!-- LIST OF MEETS -->
        <div
          class="d-block text-left px-1 customized-scroll"
          style="max-height: 170px"
        >
          <template
            v-for="meet in item"
          >
            <div
              v-if="meet"
              :key="meet.id"
              style="margin: 10px 0px; padding: 5px 10px;max-height: 100%"
              class="rounded cursor-pointer card-hover"
              :class="optionsClass[meet.status_meeting_id]"
              @click="showMeetDetails(meet)"
            >
              <strong
                class="text-uppercase d-flex"
                style="font-size: 13px;line-height: 14px !important;margin-bottom: 5px;"
              >
                {{ meet.title }}</strong>
              <div class="d-block">
                <small class="mr-1 d-flex w-100">
                  <feather-icon
                    icon="CalendarIcon"
                    size="12"
                    style="margin: 3px 2px 0px 0px;"
                  />
                  {{ meet.date_time | myGlobalDay }}
                </small>
                <small
                  v-if="meet.status_meeting"
                  class="mr-1 d-flex w-100"
                >
                  <feather-icon
                    icon="ClockIcon"
                    size="12"
                    style="margin: 3px 2px 0px 0px;"
                  />
                  {{ meet.status_meeting }}
                </small>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!--MODAL DETAILS OF MEETING-->
    <modal-detail
      v-if="showDetails"
      :details-meet="detailsMeet"
      :show-button-success="showButtonSuccess"
      :show-button-cancel="showButtonCancel"
      @successAction="completeMeet"
      @cancelAction="cancelMeet"
      @hiddenAction="hiddenDetail"
    />
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import ModalDetail from '@/layouts/components/navbar/components/meetings/recruitments/ModalDetails.vue';

export default {
  components: {
    ModalDetail,
  },
  props: {
    showButtonSuccess: {
      type: Boolean,
      default: false,
      required: false,
    },
    showButtonCancel: {
      type: Boolean,
      default: false,
      required: false,
    },
    optionDates: {
      type: Array,
      default: () => [],
      required: false,
    },
    miItems: {
      type: Array,
      required: true,
      default: () => [],
    },
    monthYear: {
      type: [String, null],
      default: null,
      // required: true, // THIS PROPERTY IS REQUIRED, BUT IF SPECIFIED IT GIVES ERROR
    },
    inEnglish: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    variant: {
      type: String,
      default: () => 'default',
      required: false,
    },
    darkSkin: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      optionsClass: {
        1: 'item-warning',
        2: 'item-info',
        3: 'item-success',
      },
      currentMonth: moment().format('MM-YYYY'),
      days: [],
      colorVariant: 'variant-default',
      showDetails: false,
      detailsMeet: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    spaceEmpty() {
      return parseInt(
        moment(this.currentMonth, 'MM-YYYY').startOf('month').day(),
        10,
      ) === 0
        ? 6
        : parseInt(
          moment(this.currentMonth, 'MM-YYYY').startOf('month').day(),
          10,
        ) - 1;
    },
    currentDate() {
      return parseInt(moment().format('DD'), 10);
    },
    keys() {
      return Object.keys(this.detailsMeet);
    },
  },
  mounted() {
    this.currentMonth = this.monthYear ? this.monthYear : this.currentMonth;
    const dayNames = {
      en: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'],
      es: ['LUNES', 'MARTES', 'MIÉRCOLES', 'JUEVES', 'VIERNES', 'SÁBADO', 'DOMINGO'],
    };

    if (!this.optionDates.length) {
      this.days = this.inEnglish ? dayNames.en : dayNames.es;
    } else {
      this.days = this.optionDates;
    }

    const variant = `variant-${this.variant.toLowerCase()}`;
    this.colorVariant = variant;
  },
  methods: {
    showMeetDetails(item) {
      this.showDetails = true;
      this.detailsMeet = item;
    },
    completeMeet(object) {
      this.$emit('successAction', object);
    },
    cancelMeet(object) {
      this.$emit('cancelAction', object);
    },
    hiddenDetail() {
      this.showDetails = false;
      this.detailsMeet = {};
    },
  },
};
</script>
<style scoped>
*{
  --colorDark: #34495E;
  --colorLight: white;
  --colorPrimary: #5499C7;
  --colorSuccess: #45CD22;
  --colorWarning: #ddb11f;
  --colorInfo: #F760C7;

  --headDefault: #34495e44;
  --headPrimary: #b3dcf7ab;
  --headSuccess: #abebc6a8;
  --headWarning: #f9e79f73;
  --headInfo: #d7bde265;
}
.card-hover:hover{
  box-shadow: 0px 3px 5px 2px var(--headDefault)
}
.container-wrapper {
  overflow: auto;
  transition: 0.5s ease-in-out;
}
.day-number,
.day-name{
  transition: 0.3s ease-in-out;
}
.item-date:hover .day-number{
  text-shadow: 0px 5px 5px #34495e7a !important;
}
.item-date:hover .day-number{
  transform: scale(1.1) translateX(10px);
}
.wrapper,
.wrapper-head {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(7, 7fr);
  margin: 0px;
  position: relative;
}

.wrapper {
  grid-auto-rows: minmax(240px, 240px);
  grid-gap: 20px 20px;
}

.wrapper-head {
  text-align: center;
  justify-content: center;
  align-items: center;
  grid-auto-rows: minmax(auto, 50px);
  grid-gap: 0px 0px;
}

.item-success {
  background: var(--colorSuccess);
  color: white;
}

.item-info {
  background: var(--colorInfo);
  color: white;
}

.item-warning {
  background: var(--colorWarning);
  color: white;
}

.item-primary {
  background: var(--colorPrimary);
  color: white;
}

.item-success:hover {
  background: linear-gradient(45deg, var(--colorSuccess), #3bf7b8);
}

.item-info:hover {
  background: linear-gradient(225deg, var(--colorInfo), #d38de9);
}

.item-warning:hover {
  background: linear-gradient(45deg, var(--colorWarning), rgb(243, 178, 81));
}

.item-primary:hover {
  background: linear-gradient(45deg, var(--colorPrimary), rgb(103, 190, 230));
}

.dark-skin .wrapper-head .variant-default{
  border-bottom: 2px solid var(--colorLight) !important;
}
.dark-skin .wrapper-head .variant-default .day-name,
.dark-skin .wrapper .variant-default .day-number{
  color: var(--colorLight);
}
.light-skin .wrapper-head .variant-default{
  border-bottom: 3px solid var(--colorDark) !important;
}
.light-skin .wrapper-head .variant-default .day-name,
.light-skin .wrapper .variant-default .day-number{
  color: var(--colorDark);
}
.wrapper-head .variant-primary{
  border-bottom: 2px solid var(--colorPrimary) !important;
}
.wrapper-head .variant-primary .day-name,
.wrapper .variant-primary .day-number{
  color: var(--colorPrimary);
}
.wrapper-head .variant-success{
  border-bottom: 2px solid var(--colorSuccess) !important;
}
.wrapper-head .variant-success .day-name,
.wrapper .variant-success .day-number{
  color: var(--colorSuccess);
}
.wrapper-head .variant-info{
  border-bottom: 2px solid var(--colorInfo) !important;
}
.wrapper-head .variant-info .day-name,
.wrapper .variant-info .day-number{
  color: var(--colorInfo)
}
.wrapper-head .variant-warning{
  border-bottom: 2px solid var(--colorWarning) !important;
}
.wrapper-head .variant-warning .day-name,
.wrapper .variant-warning .day-number{
  color: var(--colorWarning);
}

.wrapper-head .variant-default{
  background-color: var(--headDefault);
}
.wrapper-head .variant-primary{
  background-color: var(--headPrimary);
}
.wrapper-head .variant-success{
  background-color: var(--headSuccess);
}
.wrapper-head .variant-warning{
  background-color: var(--headWarning);
}
.wrapper-head .variant-info{
  background-color: var(--headInfo);
}

.light-skin .wrapper .variant-default {
  border-top: 2px solid var(--colorDark) !important;
}
.dark-skin .wrapper .variant-default{
  border-top: 2px solid white !important;
}
.wrapper .variant-warning {
  border-top: 2px solid var(--colorWarning) !important;
}
.wrapper .variant-info {
  border-top: 2px solid var(--colorInfo) !important;
}
.wrapper .variant-success {
  border-top: 2px solid var(--colorSuccess) !important;
}
.wrapper .variant-primary {
  border-top: 2px solid var(--colorPrimary) !important;
}
.jump{
  animation: jump 0.5s ease-in-out infinite alternate;
}
.customized-scroll {
  overflow: auto;
}
.customized-scroll::-webkit-scrollbar{
  width: 8px;
  border-radius: 10px;
  background:#cacfd2e7;
}
.customized-scroll::-webkit-scrollbar-thumb{
    background-color: var(--colorDark);
    border: var(--colorDark);
}
@keyframes jump{
  from{
    transform: translateY(-10px) scale(1.15);
  }to{
    transform: translateY(0px) scale(1);
  }
}
</style>
