<template>
  <div
    class="navbar-container content align-items-center"
    :class="displayBetween ? 'd-flex justify-content-between ' : 'd-flex'"
  >
    <!-- Bookmarks Container -->
    <div class="align-items-center d-flex">
      <b-link
        v-if="moduleId == undefined && $route.name != 'amg-menu'"
        :class="skin == 'dark' ? 'text-light' : 'text-dark'"
        :to="{ name: 'amg-menu' }"
        class="mr-2"
      >
      </b-link>
      <ul v-else-if="moduleId != undefined" class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link class="nav-link" @click="toggleVerticalMenuActive">
            <feather-icon v-if="moduleId !== 21" icon="MenuIcon" size="21" />
          </b-link>
        </li>
      </ul>
      <bookmarks :is-phone="isPhone" />
    </div>

    <!-- California and Nevada -->
    <b-navbar-nav
      class="align-items-center mx-auto"
      :class="removeClock ? 'd-none' : 'd-flex'"
    >
      <portal to="general-clocks" :disabled="!activePortal">
        <clock
          :format="format"
          :timezone="'America/Los_Angeles'"
          :tooltip="'Pacific Standard Time: California and Nevada'"
        >
          <template #title>
            <span class="text-bold-600">PST</span>
          </template>
        </clock>

        <!-- New Mexico and Arizona -->
        <clock
          :format="format"
          :timezone="'America/Denver'"
          :tooltip="'Mountain Standard Time: New Mexico and Arizona'"
        >
          <template #title>
            <span class="text-bold-600">MST</span>
          </template>
        </clock>
        <!-- Texas -->
        <clock
          :format="format"
          :timezone="'America/Chicago'"
          :tooltip="'Central Standard Time: Texas'"
        >
          <template #title>
            <span class="text-bold-600">CST</span>
          </template>
        </clock>

        <!-- Florida -->
        <clock
          :format="format"
          :timezone="'America/New_York'"
          :tooltip="'Eastern Standard Time: Florida'"
        >
          <template #title>
            <span class="text-bold-600">EST</span>
          </template>
        </clock>
      </portal>
    </b-navbar-nav>

    <b-navbar-nav class="nav align-items-center">
      <TicketParagon
        v-if="currentUser && (isChief || isSupervisor || currentUser.eng_soft)"
      />
      <AutomaticPaymentsNotificationIcon
        v-if="
          currentUser &&
          [1, 2, 17].includes(currentUser.role_id) &&
          currentUser.modul_id &&
          isNotCeoDashboard
        "
      />
      <customer-tickets-notifications
        v-if="
          thereAreModulesForAttendanceChiefDashboard && currentUser.user_id != 1
        "
      />
      <customer-tickets v-if="currentUser.role_id === 1" />
      <calendar-meetings v-if="!isCeo" />
      <!-- <projects v-if="currentUser.role_id == 1" /> -->

      <late-attendance-notifications v-if="currentUser.role_id != 1" />

      <attendance
        v-if="thereAreModulesForAttendanceChiefDashboard"
        v-b-tooltip.hover.top="
          G_COUNT_EMPLOYEES_WITHOUT_SCHEDULE
            ? `${G_COUNT_EMPLOYEES_WITHOUT_SCHEDULE} employees without schedule`
            : 'Attendance'
        "
        @employeeWithoutSchedule="employeeWithoutSchedule"
      />
      <management-attendance-shortcut v-if="currentUser.user_id == 1" />
      <dark-toggler />
      <task-dropdown v-b-tooltip.hover.top="'Tasks'" />

      <offers-dropdown
        v-if="isSupervisor && mainModule === 5"
        v-b-tooltip.hover.top="'Settlements'"
      />
      <!-- if user is in allowed sellers for metamedia-->
      <appointments-dropdown
        v-if="allowed_users.includes(currentUser.user_id) && moduleId === 2"
        v-b-tooltip.hover.top="'Appointments'"
      />

      <notification-dropdown v-b-tooltip.hover.top="'Notifications'" />

      <status-dropdown
        v-b-tooltip.hover.top="'Status Session'"
        @setBusy="setBusyMotive"
      />
      <user-dropdown />
      <modal-set-busy-motive v-if="busyModalOn" @close="busyModalOn = false" />
    </b-navbar-nav>
  </div>
</template>

<script>
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import ModalSetBusyMotive from "@/views/ce-digital/views/ModalSetBusyMotive";
import { mapGetters, mapActions } from "vuex";
import Clock from "@/layouts/components/navbar/components/clock/Clock.vue";
import AppointmentsDropdown from "@/layouts/components/navbar/AppointmentsDropdown.vue";
// import Projects from "@/layouts/components/navbar/components/projects/Projects.vue";
import CalendarMeetings from "@/layouts/components/navbar/components/meetings/CalendarMeetings.vue";
import CustomerTickets from "@/layouts/components/navbar/components/customer-tickets/CustomerTickets.vue";
import CustomerTicketsNotifications from "@/layouts/components/navbar/components/customer-tickets/CustomerTicketsNotifications.vue";
import NotificationDropdown from "./NotificationDropdown.vue";
import StatusDropdown from "./StatusDropdown.vue";
import TaskDropdown from "./components/tasks/TaskDropdown.vue";
import UserDropdown from "./UserDropdown.vue";
import Bookmarks from "./Bookmark.vue";
import OffersDropdown from "./OffersDropdown.vue";
import Attendance from "./AttendanceShortcut.vue";
import ManagementAttendanceShortcut from "./ManagementAttendanceShorcut.vue";
import LateAttendanceNotifications from "./NotficationsOnLateAttendance.vue";
import TicketParagon from "@/layouts/components/navbar/components/TicketParagon.vue";
import AutomaticPaymentsNotificationIcon from "@/layouts/components/navbar/components/automatic-payments-notifications/AutomaticPaymentsNotificationIcon.vue";
export default {
  components: {
    DarkToggler,
    NotificationDropdown,
    UserDropdown,
    Bookmarks,
    TaskDropdown,
    StatusDropdown,
    ModalSetBusyMotive,
    OffersDropdown,
    Clock,
    AppointmentsDropdown,
    Attendance,
    ManagementAttendanceShortcut,
    LateAttendanceNotifications,
    // Projects,
    CalendarMeetings,
    CustomerTickets,
    CustomerTicketsNotifications,
    AutomaticPaymentsNotificationIcon,
    TicketParagon,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      busyModalOn: false,
      removeClock: false,
      displayBetween: false,
      isPhone: false,
      format: "HH:mm:ss",
      allowed_users: [],
      activePortal: false,
      countEmployeeWithoutSchedule: null,
      lateKey: 0,
    };
  },

  created() {
    this.$store.dispatch(
      "NotificationStore/A_USERS_AVAILABILITY_CUSTOMER_TICKETS"
    );
    if (this.screenWidth > 1300) {
      this.removeClock = false;
      this.displayBetween = true;
      this.format = "HH:mm:ss";
    } else if (this.screenWidth <= 1300 && this.screenWidth > 1100) {
      this.removeClock = false;
      this.displayBetween = true;
      this.format = "HH:mm";
    } else if (this.screenWidth <= 1100 && this.screenWidth > 680) {
      this.removeClock = true;
      this.displayBetween = true;
      this.format = "HH:mm";
    }
    if (this.screenWidth <= 600) {
      this.isPhone = true;
      this.removeClock = true;
      this.displayBetween = true;
      this.format = "HH:mm";
    } else {
      this.isPhone = false;
      this.allowed_users = [];
    }
  },
  mounted() {
    this.getOwners();
  },
  computed: {
    isNotCeoDashboard() {
      return this.$route.name !== "ceo-dashboard";
    },
    skin() {
      return this.$store.getters["appConfig/skin"];
    },
    ...mapGetters({
      G_USER_STATUS_SESSION: "UserStore/G_USER_STATUS_SESSION",
      G_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/G_USER_STATUS_SESSION_SUB_MODULE",
      currentUser: "auth/currentUser",
      G_COUNT_EMPLOYEES_WITHOUT_SCHEDULE:
        "NotificationStore/G_COUNT_EMPLOYEES_WITHOUT_SCHEDULE",
      G_COUNT_LATE_ATTENDANCE_NOTIFICATIONS:
        "NotificationStore/G_COUNT_LATE_ATTENDANCE_NOTIFICATIONS",
    }),
    currentUserModules() {
      return this.currentUser?.arrRoles || [];
    },
    attendanceChiefDashboard() {
      return this.currentUserModules.filter(({ role_id, module_id }) => {
        if (role_id === 17 && this.mainModule === 6) {
          return module_id !== 24;
        }
        if (
          ([2, 17, 11, 26].includes(role_id) ||
            this.$store.getters[
              "NotificationStore/G_USERS_AVAILABILITY_CUSTOMER_TICKETS"
            ].includes(this.currentUser.user_id)) &&
          this.mainModule !== 6 &&
          ![1, 13, 16, 17, 21, 24].includes(module_id)
        ) {
          return module_id === this.mainModule;
        }
        return false;
      });
    },
    thereAreModulesForAttendanceChiefDashboard() {
      return this.attendanceChiefDashboard.length > 0;
    },
    userStatusSession() {
      // >= 22 and <= 24 mean that it's a submodule of credit experts digital and != 24 mean that it's not settings
      if (this.moduleId >= 22 && this.moduleId <= 24 && this.moduleId !== 24) {
        return this.G_USER_STATUS_SESSION_SUB_MODULE === 4
          ? "UserXIcon"
          : this.G_USER_STATUS_SESSION_SUB_MODULE === 2
          ? "UserPlusIcon"
          : this.G_USER_STATUS_SESSION_SUB_MODULE === 3
          ? "UserMinusIcon"
          : this.G_USER_STATUS_SESSION_SUB_MODULE === 5
          ? "PhoneCallIcon"
          : "UserCheckIcon";
      }
      return this.G_USER_STATUS_SESSION === 1
        ? "UserCheckIcon"
        : this.G_USER_STATUS_SESSION === 2
        ? "UserPlusIcon"
        : this.G_USER_STATUS_SESSION === 3
        ? "UserMinusIcon"
        : "UserXIcon";
    },
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    mainModule() {
      return this.currentUser.main_module;
    },
    isSupervisor() {
      return this.currentUser.role_id === 2;
    },
    isCeo() {
      return this.currentUser.user_id === 1;
    },
  },
  watch: {
    screenWidth() {
      if (this.screenWidth > 1300) {
        this.removeClock = false;
        this.displayBetween = true;
        this.format = "HH:mm:ss";
        this.activePortal = false;
      } else if (this.screenWidth <= 1300 && this.screenWidth > 1100) {
        this.removeClock = false;
        this.displayBetween = true;
        this.format = "HH:mm";
        this.activePortal = false;
      } else if (this.screenWidth <= 1100 && this.screenWidth > 680) {
        // this.removeClock = true;
        this.isPhone = true;
        this.displayBetween = true;
        this.format = "HH:mm";
        this.activePortal = true;
      }
      if (this.screenWidth <= 600) {
        this.isPhone = true;
        // this.removeClock = true;
        this.displayBetween = true;
        this.format = "HH:mm";
        this.activePortal = true;
      } else {
        this.isPhone = false;
      }
    },
  },
  methods: {
    ...mapActions({
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
    }),
    async getOwners() {
      try {
        const { data } = await this.A_GET_OWNERS({
          modul: 2,
          body: { roles: "[1,2,5]", type: "1", validate_filter: 1 },
        });
        this.allowed_users = data.map((user) => user.id);
      } catch (error) {
        console.log("Something went wrong getOwners:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },

    setBusyMotive() {
      this.busyModalOn = true;
    },
    getUserStatusSession() {
      // >= 22 and <= 24 mean that it's a submodule of credit experts digital and != 24 mean that it's not settings
      const value =
        this.moduleId >= 22 && this.moduleId <= 24 && this.moduleId !== 24
          ? this.G_USER_STATUS_SESSION_SUB_MODULE
          : this.G_USER_STATUS_SESSION;
      switch (value) {
        case 1:
          return "Active";
        case 2:
          return "Busy";
        case 3:
          return "Away";
        case 4:
          return "Offline";
        case 5:
          return "In Call";
        default:
          return "Active";
      }
    },
    employeeWithoutSchedule(count) {
      this.countEmployeeWithoutSchedule = count;
    },
  },
};
</script>

<style></style>
