<template>
  <b-nav-item
    v-if="isCeo || isChief"
    :id="`soft-projects`"
    v-b-tooltip.hover.top="'Soft Projects'"
    @click="redirectToProjects"
  >
    <feather-icon
      :badge="counterMessages"
      badge-classes="badge-important"
      icon="GithubIcon"
      size="18"
    />
  </b-nav-item>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      counterMessages: "SoftProjectsBinnacleStore/G_TOTAL_MESSAGES",
    }),
  },
  methods: {
    ...mapActions({
      A_GET_TOTAL_MESSAGES: "SoftProjectsBinnacleStore/A_GET_TOTAL_MESSAGES",
    }),
    async getCounterMessages() {
      await this.A_GET_TOTAL_MESSAGES({
        user_id: this.currentUser.user_id,
      });
    },
    redirectToProjects() {
      if (this.isCeo) {
        this.$router.push("/managment/paragon-soft");
      } else {
        this.$router.push("/paragon-soft");
      }
    },
  },
  mounted() {
    this.getCounterMessages();
  },
};
</script>

<style>
</style>