<template>
  <amg-icon icon="MessengerIcon" style="color: #0090e7 !important" size="19" />
</template>

<script>
import CryptoJS from "crypto-js";
import { mapGetters } from "vuex";
export default {
  mounted() {
    if (this.currentUser) {
      this.encryptId();
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  data() {
    return {
      encryptedId: null,
    };
  },
  methods: {
    encryptId() {
      const token = localStorage.getItem("accessToken");
      const encrypted = CryptoJS.AES.encrypt(
        token + "",
        "EstAEsUnaKeyCrEadAAmg1234"
      ).toString();
      this.encryptedId = encrypted.replaceAll("/", "-");
    },
    redirectToMessenger() {
      window.open(
        `${process.env.VUE_APP_MESSENGER_NEW_ROUTE}/autologin/${this.encryptedId}`,
        "_blank"
      );
    },
  },
};
</script>

<style></style>
