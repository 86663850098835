<template>
  <div>
    <b-list-group>
      <template v-if="!loading">
        <b-list-group-item
          v-for="notification in notifications"
          :key="notification.id"
          :to="goToLink(notification)"
          target="_blank"
        >
          <div class="d-flex w-100 justify-content-between">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  :class="notification.status == 1 ? '' : 'cursor-pointer'"
                  size="30"
                  :variant="
                    notification.status == 1 ? 'light-primary' : 'primary'
                  "
                >
                  <feather-icon size="15" icon="CalendarIcon" />
                </b-avatar>
              </b-media-aside>

              <b-media-body class="my-auto">
                <b-link
                  :class="[
                    'font-weight-bold mb-0 h5',
                    notification.status == 0
                      ? skin == 'dark'
                        ? 'text-light'
                        : 'text-dark'
                      : '',
                  ]"
                  class="d-flex"
                >
                  <p
                    v-html="notification.notification"
                    style="line-height: 1.8; font-size: 14px"
                  ></p>
                  <span
                    v-if="notification.status == 0"
                    class="float-right cursor-pointer unread-notification ml-1"
                    style="margin-top: 3px"
                  >
                  </span>
                </b-link>
              </b-media-body>
            </b-media>

            <div class="text-right d-flex flex-column">
              <div id="div1">
                <small>{{
                  notification.created_at | myDateGlobalWithHour
                }}</small>
              </div>
              <div id="div2">
                <b-button
                  title="Mark as read"
                  class="mt-1 text-primary"
                  v-if="notification.status == 0"
                  variant="outline-link"
                  size="sm"
                  @click="updateNotification(notification)"
                >
                  <feather-icon icon="CheckIcon" size="15" />
                </b-button>
              </div>
            </div>
          </div>
        </b-list-group-item>
      </template>
      <template v-else>
        <b-list-group-item
          :key="notification.id"
          v-for="notification in notifications.length"
        >
          <div class="d-flex w-100 justify-content-between align-items-center">
            <b-media no-body class="w-100">
              <b-media-aside class="mr-2" width="100%">
                <b-skeleton type="avatar" size="30px"></b-skeleton>
              </b-media-aside>
              <b-media-body class="my-auto">
                <b-skeleton style="margin-top: 9px"></b-skeleton>
              </b-media-body>
            </b-media>
            <small></small>
          </div>
        </b-list-group-item>
      </template>
    </b-list-group>
    <div class="mx-2 mb-2 mt-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Showing {{ startPage }} to {{ toPage }} of
            {{ totalData }} entries</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalData"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
  
  <script>
import { mapGetters, mapActions } from "vuex";
import NavbarService from "@/layouts/components/navbar/service/navbar.service.js";
import moment from 'moment'
export default {
  async mounted() {
    await this.getAllAppointmentNotifications();
  },
  data() {
    return {
      currentPage: 1,
      perPage: 7,
      totalData: 0,
      startPage: 0,
      toPage: 0,
      loading: false,
      notifications: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      skin: "appConfig/skin",
    }),
    dateNow(){
      return moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    ...mapActions({
      A_UPDATE_NOTIFICATION: "NotificationStore/A_UPDATE_NOTIFICATION",
    }),

    goToLink(notification) {
      if(notification.created_at_date <= '2023-05-17'){
        return notification.link;
      }else{
        return {name: 'lead-show', params: {id: notification.link} }
      }
      // window.open(notification.link, "_blank");
    },

    async getAllAppointmentNotifications(page = 1) {
      try {
        this.loading = true;
        const { data } = await NavbarService.getAllAppointmentNotifications({
          id: this.currentUser.user_id,
          page,
          action: "appointment",
        });
        this.notifications = data.data;
        this.currentPage = data.current_page;
        this.perPage = data.per_page;
        this.totalData = data.total;
        this.startPage = data.from;
        this.toPage = data.to;
        this.loading = false;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
      }
    },

    async updateNotification(notification) {
      try {
        await this.A_UPDATE_NOTIFICATION({
          id: notification.id,
        });
        this.$emit("update");
      } catch (error) {
        this.getAllAppointmentNotifications();
      }
    },
  },
  watch: {
    currentPage(newVal, oldVal) {
      this.getAllAppointmentNotifications(newVal);
    },
  },
};
</script>
<style scoped>
#div1 {
  top: 0;
}
#div2 {
  bottom: 0;
}
</style>