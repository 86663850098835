<template>
  <div>
    <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
    >
      <template #button-content>
        <tabler-icon
          v-b-tooltip.hover.top="'Attendance Notifications'"
          :badge="
            COUNT_LATE_ATTENDANCE_NOTIFICATIONS > 99
              ? '+99'
              : COUNT_LATE_ATTENDANCE_NOTIFICATIONS
          "
          badge-classes="badge-important"
          class="text-body cursor-pointer"
          icon="CalendarIcon"
          size="23"
        />
      </template>

      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            Attendance Notifications
          </h4>
          <b-badge pill variant="light-primary"
            >{{ COUNT_LATE_ATTENDANCE_NOTIFICATIONS }} New</b-badge
          >
        </div>
      </li>

      <!-- Notifications -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <!-- Account Notification -->
        <template v-for="notification in notificationData">
          <b-media
            :key="notification.id"
            style="cursor: pointer"
            @click="updateNotification(notification)"
          >
            <template #aside>
              <b-avatar
                size="32"
                :variant="notification.type == 101 ? 'success' : 'danger'"
              >
                <feather-icon icon="InfoIcon" />
              </b-avatar>
            </template>
            <p class="media-heading">
              <span
                class="font-weight-bolder"
                v-html="notification.notification_message"
              />
            </p>
            <small>{{ notification.created_at | myDateGlobal }}</small>
          </b-media>
        </template>
      </vue-perfect-scrollbar>
      <!-- Cart Footer -->
      <li class="dropdown-menu-footer">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          @click="notificationModal = true"
          >Read all notifications</b-button
        >
      </li>
    </b-nav-item-dropdown>
    <late-notifications-modal
      v-if="notificationModal"
      @close="closeLateNotificationModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import VuePerfectScrollbar from "vue-perfect-scrollbar"
import Ripple from "vue-ripple-directive"
import LateNotificationsModal from "./components/notifications/LateNotifications.vue"

export default {
  directives: {
    Ripple,
  },
  components: {
    VuePerfectScrollbar,
    LateNotificationsModal,
  },
  data() {
    return {
      notificationData: [],
      notificationModal: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      key: 0,
    }
  },
  async created() {
    await this.A_CALCULATE_LATE_ATTENDANCE_NOTIFICATIONS({
      user_id: this.currentUserId,
      role_id: this.currentRoleId,
    })
    const data = await this.A_SET_LATE_ATTENDANCE_NOTIFICATIONS({
      user_id: this.currentUserId,
      role_id: this.currentRoleId,
    })
    this.notificationData = data.data
  },
  methods: {
    ...mapActions({
      A_CALCULATE_LATE_ATTENDANCE_NOTIFICATIONS:
        "NotificationStore/A_SET_COUNT_LATE_ATTENDANCE_NOTIFICATIONS",
      A_SET_LATE_ATTENDANCE_NOTIFICATIONS:
        "NotificationStore/A_SET_LATE_ATTENDANCE_NOTIFICATIONS",
      A_UPDATE_NOTIFICATION: "NotificationStore/A_UPDATE_NOTIFICATION",
    }),
    ...mapMutations({
      DECREASE_LATE_NOTIFICATIONS_COUNTER:
        "NotificationStore/DECREASE_LATE_NOTIFICATIONS_COUNTER",
    }),
    async closeLateNotificationModal() {
      this.notificationModal = false
      const data = await this.A_SET_LATE_ATTENDANCE_NOTIFICATIONS({
        user_id: this.currentUserId,
        role_id: this.currentRoleId,
      })
      this.notificationData = data.data
    },
    async updateNotification(notification) {
      try {
        const previousFocusElement = document.activeElement

        await this.A_UPDATE_NOTIFICATION({
          id: notification.id,
        })
        notification.status = 1
        this.notificationData.splice(
          this.notificationData.findIndex(
            (item) => item.id === notification.id
          ),
          1
        )
        localStorage.setItem("lateAttendanceNotificationsUpdated", "false")
        this.DECREASE_LATE_NOTIFICATIONS_COUNTER()
        this.$nextTick(() => previousFocusElement.focus())
      } catch (error) {
        console.error(error)
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      COUNT_LATE_ATTENDANCE_NOTIFICATIONS:
        "NotificationStore/G_COUNT_LATE_ATTENDANCE_NOTIFICATIONS",
      G_LATE_ATTENDANCE_NOTIFICATIONS:
        "NotificationStore/G_LATE_ATTENDANCE_NOTIFICATIONS",
    }),
    currentRoleId() {
      return this.currentUser.role_id
    },
    currentModuleId() {
      return this.currentUser.modul_id
    },
    currentUserId() {
      return this.currentUser.user_id
    },
  },
  watch: {
    async G_LATE_ATTENDANCE_NOTIFICATIONS(value) {
      this.notificationData = value
    },
  },
}
</script>

  <style>
.x-list .dropdown-menu {
  max-height: 300px !important;
  overflow-y: auto;
}

.gray-home {
  transition: all 0.1s ease;
  filter: invert(93%) sepia(12%) saturate(0%) hue-rotate(127deg) brightness(83%)
    contrast(80%);
}
</style>
