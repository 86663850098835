<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div
        v-if="currentUser"
        class="d-sm-flex d-none user-nav"
      >
        <p class="user-name font-weight-bolder mb-0">
          {{ currentUser.fullName || currentUser.username }}
        </p>
        <span
          class="user-status"
        >{{
           currentUser.role_name ? currentUser.role_name : currentUser.roleName
         }}
          {{ isCeo || isSupervisor ? "" : getLevelSeller || "" }}</span>
      </div>
      <template v-if="currentUser">
        <b-avatar
          v-b-tooltip.hover.top="getUserStatusSession"
          size="40"
          :src="currentUser.avatar"
          variant="light-primary"
          badge
          class="badge-minimal"
          :badge-variant="statusSession"
        >
          <feather-icon
            v-if="!currentUser.fullName"
            icon="UserIcon"
            size="19"
          />
        </b-avatar>
      </template>
    </template>

    <b-dropdown-item
      :to="{ name: 'users-my-profile-other-information' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>My Profile </span>
      <feather-icon
        v-if="G_USER_STATUS_ESSENTIAL_DOCS == 1"
        id="warning-icon"
        icon="AlertTriangleIcon"
        class="mr-1 warning-icon"
      />
      <b-tooltip
        v-if="G_USER_STATUS_ESSENTIAL_DOCS == 1"
        target="warning-icon"
        triggers="hover"
        placement="right"
        title="You have Pending Docs"
      />
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      :to="{ name: 'amg-messages' }"
    >
      <messages class="mr-50" />
      <span>Messages</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="$refs.stickyNotes.openStickyNotes()"
    >
      <sticky-notes
        ref="stickyNotes"
        class="mr-50"
      />
      <span>Sticky Notes</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="$refs.payStub.openPayStubModal()"
    >
      <pay-stub
        ref="payStub"
        class="mr-50"
      />
      <span>Pay Stub</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="$refs.messengerNew.redirectToMessenger()"
    >
      <messenger-new
        ref="messengerNew"
        class="mr-50"
      />
      <span>Messenger New</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="$refs.schedules.openModalReport()"
    >
      <schedules-report
        ref="schedules"
        class="mr-50"
      />
      <span>Schedule R.</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Exit</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { unsubscribeAllSocket } from '@/socket/index';

import { initialAbility } from '@/libs/acl/config';
import useJwt from '@/auth/jwt/useJwt';
import { avatarText } from '@core/utils/filter';
import StickyNotes from './components/sticky-notes/StickyNotes.vue';
import Messages from './components/messages/Messages.vue';
import PayStub from './components/pay-stub/PayStub.vue';
import SchedulesReport from './components/schedules/SchedulesReportUser.vue';
import MessengerNew from './components/messenger-new/MessengerNew.vue';

export default {
  components: {
    StickyNotes,
    Messages,
    PayStub,
    SchedulesReport,
    MessengerNew,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      G_USER_STATUS_SESSION: 'UserStore/G_USER_STATUS_SESSION',
      G_USER_STATUS_SESSION_SUB_MODULE:
        'UserStore/G_USER_STATUS_SESSION_SUB_MODULE',
      G_USER_STATUS_ESSENTIAL_DOCS: 'UserStore/G_USER_STATUS_ESSENTIAL_DOCS',
    }),
    getLevelSeller() {
      const value = this.currentUser.level_name;
      switch (value) {
        case 'begginer':
          return 'Trainer';
        case 'Jr':
          return 'Junior';
        case 'Senior':
          return 'Senior';
        case 'Master':
          return 'Master';
        default:
          return false;
      }
    },
    getUserStatusSession() {
      // >= 22 and <= 24 mean that it's a submodule of credit experts digital and != 24 mean that it's not settings
      const value = (this.moduleId >= 22 && this.moduleId <= 24 && this.moduleId !== 24)
        || this.moduleId === 20
        ? this.G_USER_STATUS_SESSION_SUB_MODULE
        : this.G_USER_STATUS_SESSION;
      switch (value) {
        case 1:
          return 'Active';
        case 2:
          return 'Busy';
        case 3:
          return 'Away';
        case 4:
          return 'Offline';
        case 5:
          return 'In Call';
        case 7:
          return 'In Break';
        case 8:
          return 'In Meeting';
        default:
          return 'Active';
      }
    },
    statusSession() {
      if ((this.moduleId > 21 && this.moduleId <= 23) || this.moduleId === 20) {
        if (this.G_USER_STATUS_SESSION_SUB_MODULE === 1) return 'success';
        if (this.G_USER_STATUS_SESSION_SUB_MODULE === 2) return 'warning';
        if (this.G_USER_STATUS_SESSION_SUB_MODULE === 3) return 'danger';
        if (this.G_USER_STATUS_SESSION_SUB_MODULE === 5) return 'info';
        if (this.G_USER_STATUS_SESSION_SUB_MODULE === 6) return 'primary';
        if (this.G_USER_STATUS_SESSION_SUB_MODULE === 7) return 'info';
        if (this.G_USER_STATUS_SESSION_SUB_MODULE === 8) return 'primary';
        return 'secondary';
      }

      if (this.G_USER_STATUS_SESSION === 1) return 'success';
      if (this.G_USER_STATUS_SESSION === 2) return 'warning';
      if (this.G_USER_STATUS_SESSION === 3) return 'danger';
      if (this.G_USER_STATUS_SESSION === 5) return 'info';
      if (this.G_USER_STATUS_SESSION === 7) return 'info';
      if (this.G_USER_STATUS_SESSION === 8) return 'primary';
      return 'secondary';
    },
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
  },
  mounted() {
    this.changeStatusSession(1);
  },
  methods: {
    ...mapMutations({
      resetState: 'resetState',
      SET_USER_STATUS_SESSION: 'UserStore/SET_USER_STATUS_SESSION',
      SET_USER_STATUS_SESSION_SUB_MODULE:
        'UserStore/SET_USER_STATUS_SESSION_SUB_MODULE',
    }),
    ...mapActions({
      A_CHANGE_USER_STATUS_SESSION: 'UserStore/A_CHANGE_USER_STATUS_SESSION',
      A_CHANGE_USER_STATUS_SESSION_SUB_MODULE:
        'UserStore/A_CHANGE_USER_STATUS_SESSION_SUB_MODULE',
      A_UPDATE_CURRENT_USER: 'auth/updateCurrentUser',
    }),
    async changeStatusSession(status) {
      if (status === this.G_USER_STATUS_SESSION_SUB_MODULE) return;

      if (this.moduleId >= 20 && this.moduleId <= 23) {
        if (status === 2) {
          this.$emit('setBusy');
          return;
        }
      }
      if (this.currentUser.user_id !== 1) {
        const params = {
          id: this.currentUser.user_id,
          state: status.toString(),
          created_by: this.currentUser.user_id,
          sub_module_id: this.moduleId ? this.moduleId : this.currentUser.main_module,
          status_sub_module_id: status,
          origin_action: status === 1 ? 2 : 3, // Si esta iniciando sesion (status === 1) se registrara en la bd como 'automatic login' (2), sino sera 'automatic logout' (3)
        };
        this.SET_USER_STATUS_SESSION_SUB_MODULE([{ status_session: status }]);
        await this.A_CHANGE_USER_STATUS_SESSION_SUB_MODULE(params);
        this.SET_USER_STATUS_SESSION([{ status_session: status }]);
        this.A_CHANGE_USER_STATUS_SESSION(params);
      }
    },
    async logout() {
      try {
        await this.changeStatusSession(4);
        const userData = JSON.parse(localStorage.getItem('userData'));
        await useJwt.logout({
          email: userData.email,
          token_session: localStorage.getItem('accessToken'),
        });
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
        localStorage.removeItem('userData');
        sessionStorage.clear()
        this.resetState();
        this.A_UPDATE_CURRENT_USER(null);
        // Reset ability
        this.$ability.update(initialAbility);
        // window.location.href = process.env.VUE_APP_ORIGINAL_SOFT;
        // Redirect to login page
        unsubscribeAllSocket();
        this.$router.push({ name: 'auth-login' });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.warning-icon {
  color: yellow !important;
  height: 14px !important;
  width: 14px !important;
  margin-left: 10px !important;
}
</style>
