<template>
  <b-modal
    v-model="modalUp"
    title="Attendance Notifications"
    ok-title="Send Request"
    centered
    size="xsm"
    hide-footer
    @hidden="hiddenModal()"
  >
    <b-list-group :key="key">
      <template>
        <b-button
          v-if="notificationData.length > 0 && notificationData.filter((notification) => notification.status == 0).length > 0"
          style="width: 10em;height:50px "
          variant="primary"
          class="mb-1"
          @click="updateAllNotifications()"
        >
          Check All Notifications
        </b-button>
        <b-list-group-item
          v-for="notification in notificationData"
          :key="notification.id"
          @click="updateNotification(notification)"
        >
          <div class="d-flex w-100 justify-content-between">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  :class="notification.status == 1 ? '' : 'cursor-pointer'"
                  size="30"
                  :variant="
                    notification.status == 1 ? 'light-primary' : 'primary'
                  "
                >
                  <feather-icon
                    size="15"
                    icon="CalendarIcon"
                    style="cursor: pointer;"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <p
                  class="text-center"
                  v-html="notification.notification_message"
                />
              </b-media-body>
            </b-media>
          </div>
        </b-list-group-item>
      </template>
    </b-list-group>
    <div class="mx-2 mb-2 mt-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span
            class="text-muted"
          >Showing {{ startPage }} to {{ toPage }} of
            {{ totalData }} entries</span>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"

            @input="onChangeCurrentPage($event)"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  data: () => ({
    fields: [
      {
        key: 'notification_message',
        label: 'Notification',
        class: 'text-center',
      },
      {
        key: 'created_by',
        label: 'Created by',
        class: 'text-center',
      },
    ],
    notificationData: [],
    isLoading: false,
    notifications: [],
    modalUp: true,
    currentPage: 1,
    totalRows: 0,
    perPage: 10,
    startPage: 1,
    toPage: '',
    totalData: '',
    key: 0,
  }),
  async created() {
    await this.myProvider();
  },
  methods: {
    async myProvider() {
      try {
        this.addPreloader();
        localStorage.setItem('lateAttendanceNotificationsUpdated', 'false');
        const data = await this.A_SET_LATE_ATTENDANCE_NOTIFICATIONS({
          user_id: this.currentUserId,
          role_id: this.currentRoleId,
          status: 1,
          page: this.currentPage,
          perPage: this.perPage,
        });
        localStorage.setItem('lateAttendanceNotificationsUpdated', 'true');

        this.totalData = data.total;
        this.totalRows = data.total;
        this.toPage = data.to;
        this.removePreloader();
        this.notifications = data.data;
        this.startPage = data.from;
        this.notificationData = data.data;
      } catch (err) {
        console.error(err);
        this.removePreloader();
      }
    },
    async hiddenModal() {
      this.modalUp = false;
      localStorage.setItem('lateAttendanceNotificationsUpdated', 'false');
      await this.A_SET_LATE_ATTENDANCE_NOTIFICATIONS({
        user_id: this.currentUserId,
        role_id: this.currentRoleId,
      });
      localStorage.setItem('lateAttendanceNotificationsUpdated', 'true');
      this.$emit('close');
    },
    ...mapActions({
      A_SET_LATE_ATTENDANCE_NOTIFICATIONS:
        'NotificationStore/A_SET_LATE_ATTENDANCE_NOTIFICATIONS',
      LATE_ATTENDANCE_NOTIFICATIONS:
        'NotificationStore/G_LATE_ATTENDANCE_NOTIFICATIONS',
      A_UPDATE_NOTIFICATION: 'NotificationStore/A_UPDATE_NOTIFICATION',
      A_UPDATE_ALL_NOTIFICATIONS: 'NotificationStore/A_UPDATE_ALL_NOTIFICATIONS',
    }),
    ...mapMutations({
      DECREASE_LATE_NOTIFICATIONS_COUNTER:
        'NotificationStore/DECREASE_LATE_NOTIFICATIONS_COUNTER',
    }),
    onChangeCurrentPage(page) {
      this.currentPage = page;
      this.updateTable();
    },
    async updateTable() {
      await this.myProvider();
      this.key += 1;
    },
    async updateAllNotifications() {
      try {
        const params = {
          user_id: this.currentUserId,
          role_id: this.currentRoleId,
          page: this.currentPage,
          perPage: this.perPage,
          status: 1,
        };
        await this.A_UPDATE_ALL_NOTIFICATIONS(params);
        // console.log('data');
        // const data = await this.A_SET_LATE_ATTENDANCE_NOTIFICATIONS({
        //   user_id: this.currentUserId,
        //   role_id: this.currentRoleId,
        //   status: 1,
        //   page: 1,
        //   perPage: 10,
        // });
        this.notificationData = this.G_LATE_ATTENDANCE_NOTIFICATIONS;
      } catch (err) {
        console.error(err);
      }
    },
    async updateNotification(notification) {
      try {
        if (notification.status === 0) {
          await this.A_UPDATE_NOTIFICATION({
            id: notification.id,
          });

          notification.status = 1;
          this.DECREASE_LATE_NOTIFICATIONS_COUNTER();
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      G_LATE_ATTENDANCE_NOTIFICATIONS:
        'NotificationStore/G_LATE_ATTENDANCE_NOTIFICATIONS',
    }),
    currentRoleId() {
      return this.currentUser.role_id;
    },
    currentModuleId() {
      return this.currentUser.modul_id;
    },
    currentUserId() {
      return this.currentUser.user_id;
    },
  },
  watch: {},
};
</script>

    <style scoped>
</style>
