var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-nav-item-dropdown',{ref:"dropdown",staticClass:"dropdown-meta-inbox mr-25",attrs:{"menu-class":"dropdown-meta-inbox","left":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticStyle:{"position":"relative"}},[_c('img',{staticStyle:{"width":"20px","height":"20px"},attrs:{"src":require("@/assets/images/icons/iconoMessenger.svg"),"alt":""}}),_c('feather-icon',{attrs:{"icon":"","badge":_vm.getTotalUnreadCount,"badge-classes":"badge-danger"}})],1)]},proxy:true}])},[_c('li',{staticClass:"dropdown-menu-header meta-inbox-list"},[_c('div',{staticClass:"dropdown-header d-flex"},[_c('h4',{staticClass:"notification-title mb-0 mr-auto"},[_vm._v("Meta Inbox")])])]),_c('vue-perfect-scrollbar',{staticClass:"scrollable-container media-list scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"li"}},_vm._l((_vm.G_FB_PAGES),function(item,index){return _c('div',{key:index,staticClass:"meta-inbox-list"},[_c('div',{staticClass:"meta-item",class:{
            active:
              _vm.G_CURRENT_FB_PAGE.id === item.id &&
              _vm.currentRoute == 'metamedia-messenger',
          }},[_c('div',{staticClass:"m-1"},[_c('b-avatar',{staticClass:"cursor-pointer",attrs:{"size":"32","variant":"light-secondary","src":item.picture}})],1),_c('div',{staticClass:"container-router-link"},[_c('div',{staticClass:"align-self-center mx-1"},[_c('router-link',{staticClass:"text-muted",attrs:{"to":{
                  name: 'metamedia-messenger',
                }},nativeOn:{"click":function($event){return _vm.goToMessenger(item)}}},[_c('span',{staticClass:"font-weight-bolder align-self-center",class:_vm.G_CURRENT_FB_PAGE.id == item.id &&
                    _vm.currentRoute == 'metamedia-messenger'
                      ? 'text-primary'
                      : ''},[_vm._v(" "+_vm._s(item.name)+" ")])])],1),(_vm.getUnreadCountByPage(item.id))?_c('b-badge',{staticClass:"unread-count",attrs:{"variant":"danger"}},[_vm._v(" "+_vm._s(_vm.getUnreadCountByPage(item.id))+" ")]):_vm._e()],1)])])}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }