var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalRegisterPayment",attrs:{"modal-class":"modal-primary","title-class":"h4 text-white font-weight-bolder","title":"Make Payment","size":"md","hide-footer":"","no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.closeModal()}}},[_c('b-row',{staticStyle:{"padding":"0 15px"}},[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Search by:"}},[_c('b-form-radio-group',{staticClass:"w-100",attrs:{"options":_vm.itemOptions,"button-variant":"outline-primary","buttons":""},on:{"input":_vm.manageInput},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"12","xl":"8"}},[_c('b-overlay',{attrs:{"show":_vm.loadingSearch,"rounded":"lg","opacity":"0.6"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center mt-2",staticStyle:{"gap":"4px"}},[_c('b-spinner',{attrs:{"small":"","type":"grow","variant":"primary"}}),_c('b-spinner',{attrs:{"type":"grow","variant":"primary"}}),_c('b-spinner',{attrs:{"small":"","type":"grow","variant":"primary"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Please wait...")])],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"form"},[_c('b-form-group',{attrs:{"label":_vm.isSearchingLead ? 'Lead name:' : 'Client or Account Number:'}},[_c('ValidationProvider',{attrs:{"name":"searchText","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"aria-hidden":_vm.loadingSearch ? 'true' : null}},[_c('b-form-input',{ref:"inputSearch",attrs:{"type":"text","placeholder":_vm.isSearchingLead
                      ? 'Search by name'
                      : 'Search by name or account number',"id":"search-lead-or-client","state":errors[0] ? false : null},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.reloadTable()}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"py-0",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.reloadTable()}}},[_vm._v(" SEARCH "),_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1)]}}])})],1)],1)],1)],1)],1),_c('filter-slot',{staticClass:"filter-slot-new-customization",attrs:{"filter":[],"total-rows":_vm.FiltersForSlot.totalRows,"paginate":_vm.FiltersForSlot.paginate,"start-page":_vm.FiltersForSlot.startPage,"to-page":_vm.FiltersForSlot.toPage,"top-paginate":false,"filter-principal":{},"no-visible-principal-filter":true,"show-container-filters-selector":false},on:{"reload":function($event){return _vm.$refs['registerTable'].refresh()}}},[_c('b-table',{ref:"registerTable",staticClass:"blue-scrollbar position-relative table-new-customization",attrs:{"slot":"table","items":_vm.myProvider,"fields":_vm.showFields,"busy":_vm.isBusy,"primary-key":"id","responsive":"md","sticky-header":"50vh","show-empty":"","no-provider-filtering":"","per-page":_vm.FiltersForSlot.paginate.perPage,"current-page":_vm.FiltersForSlot.paginate.currentPage},on:{"update:busy":function($event){_vm.isBusy=$event}},slot:"table",scopedSlots:_vm._u([{key:"cell(client_account_id)",fn:function(data){return [_c('router-link',{staticClass:"font-weight-bolder",attrs:{"to":{
            name: _vm.dashboardRouteName(_vm.moduleId),
            params: {
              idClient: data.item.client_account_id,
            },
          },"target":"_blank"}},[_c('div',{staticClass:"d-flex gap-5"},[_c('status-account',{attrs:{"account":data.item,"text":false}}),_vm._v(" "+_vm._s(data.item.name)+" ")],1),_c('div',{staticStyle:{"margin-left":"24px"}},[_vm._v(" "+_vm._s(data.item.account)+" ")])])]}},{key:"cell(lead_id)",fn:function(data){return [_c('router-link',{staticClass:"text-warning font-weight-bolder",attrs:{"target":"_blank","to":{
            name: _vm.dashboardLeadRouteName(_vm.moduleId),
            params: { id: data.item.id },
          }}},[_vm._v(" "+_vm._s(data.item.name)+" "),_c('br'),_vm._v(" ID: "+_vm._s(data.item.id)+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center align-items-center gap-5"},[(_vm.selectedItem == 'lead')?_c('b-badge',{staticClass:"clickable",attrs:{"variant":"light-success"},on:{"click":function($event){return _vm.openChooseLeadTransaction(data.item)}}},[_vm._v(" Add Payment ")]):_vm._e(),(
              _vm.selectedItem == 'client' &&
              ![4, 5, 6, 11, 12, 13].includes(data.item.client_status)
            )?_c('b-badge',{staticClass:"clickable",attrs:{"variant":"light-success"},on:{"click":function($event){return _vm.openMonthlyPayment(data.item)}}},[_vm._v(" Monthly Payment ")]):_vm._e(),(_vm.selectedItem == 'client')?_c('b-badge',{staticClass:"clickable",attrs:{"variant":"light-primary"},on:{"click":function($event){return _vm.openChargePayment(data.item)}}},[_vm._v(" Charge ")]):_vm._e()],1),(data.item.sales)?_vm._l((JSON.parse(data.item.sales)),function(sale,index){return _c('div',{key:index},[_c('b-badge',{staticClass:"clickable",attrs:{"variant":"light-warning"},on:{"click":function($event){return _vm.openInitialPayment(data.item, sale)}}},[_vm._v(" Initial Payment ["+_vm._s(sale.program_name)+"] ")])],1)}):_vm._e()]}}])})],1),(_vm.showNewMonthlyPayment)?_c('monthly-payment-modal',{on:{"refreshTable":_vm.reloadTable,"close":function($event){_vm.showNewMonthlyPayment = false},"paymentSaveSuccessfully":_vm.reloadTable}}):_vm._e(),(_vm.showChargeModal)?_c('add-charge-modal',{attrs:{"title-add-charge":"Add Charge"},on:{"refresh":_vm.reloadTable,"close":function($event){_vm.showChargeModal = false}}}):_vm._e(),(_vm.showChooseLeadTransaction)?_c('lead-choose-transaction',{attrs:{"lead-name":_vm.lead.name,"lead-id":_vm.lead.id},on:{"hidden":function($event){_vm.showChooseLeadTransaction = false},"on-transaction-registered":function($event){_vm.showChooseLeadTransaction = false;
      _vm.reloadTable();}}}):_vm._e(),(_vm.showInitialPaymentModal)?_c('modal-initial-payment',{attrs:{"modal":{ initial_payment: true },"initial_payment":_vm.modalIP},on:{"reload":_vm.reloadTable,"close":function($event){_vm.showInitialPaymentModal = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }