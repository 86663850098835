<template>
  <div class="x-list">
    <!-- calendar -->
    <b-card class="shadow-none">
      <template #header>
        <div
          class="px-3 d-block justify-content-start align-items-center w-100"
          :class="isDarkSkin ? 'text-white' : 'text-dark'"
        >
          <!--NAVIGATES-->
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <strong style="font-size: 30px; min-width: 220px">
                {{ currentMonth }}
              </strong>
              <div class="d-flex ml-3">
                <feather-icon
                  icon="ArrowLeftCircleIcon"
                  size="25"
                  class="text-primary cursor-pointer hover-me"
                  @click="navigatorToDates(-1)"
                />
                <feather-icon
                  icon="CalendarIcon"
                  size="22"
                  class="text-primary mx-1 cursor-pointer hover-me"
                  @click="navigatorToDates(0)"
                />
                <feather-icon
                  icon="ArrowRightCircleIcon"
                  size="25"
                  class="text-primary cursor-pointer hover-me"
                  @click="navigatorToDates(1)"
                />
              </div>
            </div>
          </div>
          <!--DESCRIPTION-->
          <p
            class="mt-1 py-1 border-top border-bottom"
            style="border-color: skyblue !important"
          >
            Here you will find all the meetings concerning the recruitment of new applicants.
          </p>
        </div>
      </template>
      <!--FILTERS-->
      <div
        style="
          padding: 15px 50px;
          box-sizing: border-box !important
        "
        class="m-0 pb-5 w-100"
      >
        <b-row
          class="d-flex justify-content-center justify-content-xl-around align-items-center p-2 mb-2 overflow: auto !important;"
          :class="!isDarkSkin ? 'list-group-item-light' : ''"
        >
          <b-col
            cols="12"
            md="6"
            class="d-block d-md-flex justify-content-center align-items-center py-1"
          >
            <strong
              class="mr-4"
              style="font-size: 15px;"
            >Status of meet: </strong>
            <b-form-checkbox-group
              v-model="filterStatusMeetings"
              :options="optionsFilter"
              class="mr-2"
              value-field="item"
              text-field="name"
              switches
              disabled-field="notEnabled"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <strong
              class="mr-4"
              style="font-size: 15px;"
            >Status process: </strong>
            <v-select
              v-model="filterStatusRecruitment"
              label="name"
              :options="optionsStatus"
              :reduce="(option) => option.id"
              placeholder="Select proccess"
              :class="!isDarkSkin ? 'bg-white':''"
            />
          </b-col>
        </b-row>
      </div>
      <!--LOAD CALENDAR-->
      <b-skeleton-img
        v-if="loading"
        no-aspect
        height="500px"
      />
      <!--CALENDAR-->
      <template v-else>
        <body-calendar
          :key="keyCalendar"
          :mi-items="miItems"
          :month-year="monthYear"
          class="mx-3 rounded"
          :dark-skin="isDarkSkin"
          :variant="'default'"
          :show-button-success="true"
          :show-button-cancel="false"
          @cancelAction="cancelMeet"
          @successAction="completeMeet"
        />
      </template>
    </b-card>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters, mapMutations } from 'vuex';
import RecruimentService from '@/views/commons/components/recruitment-process/services/recruiment.service';
import BodyCalendar from '@/layouts/components/navbar/components/meetings/recruitments/bodyCalendar.vue';

export default {
  components: {
    BodyCalendar,
  },
  data() {
    return {
      currentMonth: moment().format('MMMM-YYYY'),
      miItems: [],
      optionDates: [
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
        'SATURDAY',
        'SUNDAY',
      ],
      optionsClass: {
        pending: 'item-warning',
        completed: 'item-success',
        reprogrammed: 'item-info',
        1: 'item-warning',
        2: 'item-success',
        3: 'item-info',
      },
      optionsFilter: [
        { item: 1, name: 'Pending' },
        { item: 2, name: 'Reprogrammed' },
        { item: 3, name: 'Completed' },
      ],
      filterStatusMeetings: [1, 2],
      filterStatusRecruitment: null,
      loading: false,
      optionsStatus: [],
      keyCalendar: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      G_COUNT_MEETING_RECRUITMENT: 'NotificationStore/G_COUNT_MEETING_RECRUITMENT',
    }),
    spaceEmpty() {
      return parseInt(
        moment(this.currentMonth, 'MMMM-YYYY').startOf('month').day(),
        10,
      ) === 0
        ? 6
        : parseInt(
          moment(this.currentMonth, 'MMMM-YYYY').startOf('month').day(),
          10,
        ) - 1;
    },
    currentDate() {
      return parseInt(moment().format('DD'), 10);
    },
    month() {
      return parseInt(moment(this.currentMonth, 'MMMM-YYYY').format('MM'), 10);
    },
    year() {
      return parseInt(moment(this.currentMonth, 'MMMM-YYYY').format('YYYY'), 10);
    },
    monthYear() {
      return moment(this.currentMonth, 'MMMM-YYYY').format('MM-YYYY');
    },
  },
  watch: {
    async filterStatusRecruitment() {
      await this.getData();
    },
    async filterStatusMeetings() {
      await this.getData();
    },
  },
  async mounted() {
    await this.getStatusApplicant();
    await this.getData();
  },
  methods: {
    ...mapMutations({
      SET_COUNT_MEETING_RECRUITMENT: 'NotificationStore/SET_COUNT_MEETING_RECRUITMENT',
    }),
    async navigatorToDates(value) {
      this.currentMonth = value !== 0
        ? moment(this.currentMonth).add(value, 'month').format('MMMM-YYYY')
        : moment().format('MMMM-YYYY');
      await this.getData();
    },
    async getData() {
      try {
        this.loading = true;
        const params = {
          status_meetings: this.filterStatusMeetings.length ? this.filterStatusMeetings.join(',') : null,
          status_recruitment: this.filterStatusRecruitment,
          user_id: null,
          month: this.month,
          year: this.year,
        };
        const { data } = await RecruimentService.getMeetingsToRecruitment(params);

        // We get last day of the month
        const totalDay = parseInt(moment(this.currentMonth, 'MMMM-YYYY').endOf('month').format('DD'), 10);

        // GENERATE THE DAYS OF CALENDAR OF ACCORDING TO MONTH
        this.miItems = Array.from({ length: totalDay }, (_, index) => {
          const keyDate = moment(`${this.year}-${this.month}-${index + 1}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
          return data.data ? (data.data[keyDate] || []) : [];
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log('error in getData recruitment meetings ', error);
      }
    },
    async getStatusApplicant() {
      try {
        const { data } = await RecruimentService.getStatusApplicant();
        this.optionsStatus = data;
      } catch (error) {
        console.log('error to getStatusApplicant in calendar');
      }
    },
    async completeMeet(object) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        this.addPreloader();
        const params = {
          applicant_id: object.applicant_id,
          status_applicant_id: object.applicant_status_id,
          status_meeting: 3,
          date_time: object.date_time,
          user_id: this.currentUser.user_id,
        };
        const { status } = await RecruimentService.createMeetingToRecruitment(params);
        if (status === 200) {
          this.showDetails = false;
          this.showSuccessSwal();
          await this.getData();
          this.keyCalendar += 1;
          this.SET_COUNT_MEETING_RECRUITMENT(this.G_COUNT_MEETING_RECRUITMENT - 1);
        }

        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log(error);
      }
    },
    cancelMeet() {
      this.showDetails = false;
      this.detailsMeet = {};
    },
  },
};
</script>
<style scoped>
.hover-me {
  transition: 0.3s ease-in-out;
}

.hover-me:hover {
  transform: scale(1.15);
}

.hover-light:hover {
  box-shadow: 0px 0px 3px white;
  /* border: 1px solid white; */
}

.hover-dark:hover {
  box-shadow: 0px 0px 2px black;
  /* border: 1px solid black; */
}

.day-calendar {
  display: block;
  margin: 10px 20px;
  width: 100%;
}

.container-wrapper {
  overflow: auto;
}

.wrapper,
.wrapper-head {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(7, 7fr);
  margin: 0px;
  position: relative;
}

.wrapper {
  grid-auto-rows: minmax(240px, 240px);
  grid-gap: 20px 20px;
}

.wrapper-head {
  justify-content: start;
  align-items: center;
  grid-auto-rows: minmax(auto, 50px);
  grid-gap: 5px 20px;
}

.item-success {
  background: #45CD22;
  color: white;
}

.item-info {
  background: #F760C7;
  color: white;
}

.item-warning {
  background: #ddb11f;
  color: white;
}

.item-success:hover {
  background: linear-gradient(45deg, #45CD22, #3bf7b8);
}

.item-info:hover {
  background: linear-gradient(225deg, #F760C7, #d38de9);
}

.item-warning:hover {
  background: linear-gradient(45deg, #D4DD1F, rgb(243, 178, 81));
}
</style>
