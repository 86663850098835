<template>
  <div>
    <b-modal
      v-model="modalShow"
      @hidden="$emit('hidden')"
      header-bg-variant="transparent border-bottom border-bottom-2"
      header-class="p-0"
      modal-class="modal-primary"
      hide-footer
      scrollable
      size="xmd"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <span>MESSAGES UNREAD</span>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="$emit('hidden')"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>

      <template #default>
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <div
              v-for="(creden, index) in credentials"
              :key="`credential_counter_${index}`"
              @click="changeCredential(creden)"
              class="my-1 mr-1 cursor-pointer my-tab-rc-v2"
              :class="creden.id == credential.id ? 'tab-active-v2' : ''"
            >
              {{ creden.number_format }}
              <b-badge pill variant="danger" v-if="creden.unreadCount != 0">
                {{ creden.unreadCount }}</b-badge
              >
            </div>
          </div>

          <div
            class="my-1 mr-1 cursor-pointer my-tab-rc-v3"
            @click="goToInbox()"
            v-b-tooltip.hover
            title="Go to SMS INBOX"
          >
            VIEW SMS INBOX
            <feather-icon icon="ArrowUpRightIcon" />
          </div>
        </div>

        <b-table
          :items="results"
          :fields="fields"
          striped
          responsive="sm"
          v-if="results.length != 0"
        >
          <template #cell(sms)="data">
            <show-more-text
              :text="data.item.subject"
              :letters="70"
              :show-more-styles="{
                color: '#0099DD',
              }"
            />
          </template>
          <template #cell(list)="data">
            {{ data.item.topic_name }}
          </template>
          <template #cell(answers)="data">
            <div class="d-flex justify-content-center">
              <b-badge pill variant="danger">{{ data.item.counter }}</b-badge>

              <feather-icon
                style="margin-left: 4px"
                class="cursor-pointer"
                size="18"
                :icon="
                  data.item._showDetails ? 'ChevronUpIcon' : 'ChevronDownIcon'
                "
                @click="data.toggleDetails"
              ></feather-icon>
            </div>
          </template>

          <template #row-details="row">
            <b-row>
              <b-col cols="2">Lead</b-col>
              <b-col cols="7">
                <div class="font-weight-bolder">Message</div>
              </b-col>
              <b-col cols="2">
                <div class="font-weight-bolder text-center">Date</div>
              </b-col>
              <b-col cols="1">
                <div class="font-weight-bolder">Action</div>
              </b-col>
            </b-row>
            <b-row
              v-for="answer in row.item.answers"
              :key="`${answer._id}`"
              class="border-top"
              style="margin: 5px 0"
            >
              <b-col cols="2">
                <div class="text-left">
                  <router-link
                    v-if="answer.chat.leadId"
                    :to="getRedirectRoute(answer.chat.leadId)"
                    target="_blank"
                    class="d-block text-primary"
                    :class="textLink"
                  >
                    {{ answer.chat.leadName | myFontCapitalize }}
                  </router-link>
                  <div>{{ answer.chat.leadPhoneNumber }}</div>
                </div>
              </b-col>
              <b-col cols="7" style="padding: 0">
                <show-more-text
                  :text="answer.subject"
                  :letters="100"
                  :show-more-styles="{
                    color: '#0099DD',
                  }"
                />
              </b-col>
              <b-col cols="2">
                <div class="text-center">
                  {{ naturalDate(answer.creationTime) }}
                </div>
              </b-col>
              <b-col cols="1">
                <tabler-icon
                  icon="MessageIcon"
                  size="20"
                  class="cursor-pointer"
                  v-b-tooltip.hover
                  title="Open Chat"
                  @click="openChat(answer.chat)"
                />
              </b-col>
            </b-row>
          </template>
        </b-table>

        <b-table
          :items="messagesUnread"
          :fields="fieldsUnread"
          striped
          responsive="sm"
          v-if="messagesUnread.length != 0"
        >
          <template #cell(lead)="data">
            <div class="text-left">
              <router-link
                v-if="data.item.chat.leadId"
                :to="getRedirectRoute(data.item.chat.leadId)"
                target="_blank"
                class="d-block text-primary"
                :class="textLink"
              >
                {{ data.item.chat.leadName | myFontCapitalize }}
              </router-link>
              <div v-else>
                {{ data.item.chat.leadName ? data.item.chat.leadName : "" }}
              </div>
              <div>{{ data.item.chat.leadPhoneNumber }}</div>
            </div>
          </template>

          <template #cell(sent_date)="data">
            <div class="text-center">
              {{ naturalDate(data.item.creationTime) }}
            </div>
          </template>

          <template #cell(subject)="data">
            <show-more-text
              :text="data.item.subject"
              :letters="70"
              :show-more-styles="{
                color: '#0099DD',
              }"
            />
          </template>
          <template #cell(actions)="data">
            <tabler-icon
              icon="MessageIcon"
              size="20"
              class="cursor-pointer"
              v-b-tooltip.hover
              title="Open Chat"
              @click="openChat(data.item.chat)"
            ></tabler-icon>
          </template>
        </b-table>
      </template>
    </b-modal>

    <b-modal
      v-if="showModal"
      v-model="showModal"
      @hidden="clearAll()"
      @hide="clearAll()"
      :size="modalSize"
      hide-footer
      title-class="h3 text-white font-weight-bolder"
    >
      <rc-chat-one
        :lead-id="leadId"
        :lead-phone-number="leadPhoneNumber"
        :show-header="false"
        :credential="credential"
        :current-chat="currentChat"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import SmsService from "@/views/commons/components/ring-central/chat-sms/service/sms.service.js";
import NestService from "@/views/commons/components/ring-central/chat-sms/service/sms-nest.service.js";
import ShowMoreText from "@/views/commons/components/ring-central/chat-sms/components/utils/ShowMoreText.vue";
import Fields from "@/views/commons/components/ring-central/chat-sms/data/detail-answers-fields.data.js";
import FieldsUnread from "@/views/commons/components/ring-central/chat-sms/data/unread-message-fields.data.js";
import RcChatOne from "@/views/commons/components/ring-central/chat-sms/RcChatOne.vue";
export default {
  components: {
    ShowMoreText,
    RcChatOne,
  },
  data() {
    return {
      fields: Fields,
      fieldsUnread: FieldsUnread,
      modalShow: true,
      credentials: [],
      results: [],
      messagesUnread: [],

      showModal: false,

      leadId: null,
      leadPhoneNumber: null,

      credential: null,
      currentChat: null,
    };
  },

  computed: {
    ...mapGetters({
      currentBreakPoint: "app/currentBreakPoint",
      currentUser: "auth/currentUser",
    }),

    titleModal() {
      return ``;
    },

    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    modalSize() {
      if (this.currentBreakPoint == "xxl") {
        return "xls";
      } else {
        return "caro";
      }
    },
  },

  methods: {
    goToInbox() {
      this.$emit("hidden");
      if (
        this.$route.name == "crm-chat-sms" ||
        this.$route.name == "mm-chat-sms"
      )
        return;

      if (this.moduleId == 2) {
        this.$router.push({
          name: "crm-chat-sms",
        });
        return;
      }

      if (this.moduleId == 15) {
        this.$router.push({
          name: "mm-chat-sms",
        });
        return;
      }

      const modules = this.currentUser.arrModuls.map(
        (modul) => modul.id_module
      );

      if (modules.includes(2) && modules.includes(15)) {
        const mainModule = this.currentUser.main_module;

        if (mainModule == 2 || this.currentUser.role_id == 1) {
          this.$router.push({
            name: "crm-chat-sms",
          });
          return;
        } else {
          this.$router.push({
            name: "mm-chat-sms",
          });
          return;
        }
      }

      if (modules.includes(2)) {
        this.$router.push({
          name: "crm-chat-sms",
        });
        return;
      }

      if (modules.includes(15)) {
        this.$router.push({
          name: "mm-chat-sms",
        });
        return;
      }
    },

    async getAllDetails() {
      const { data } = await SmsService.getAllCountersAndDetails({
        credentialId: this.credential.id,
      });
      this.results = data;
    },

    async getAllCredentials() {
      const { data } = await SmsService.getNewCredentials();
      this.credentials = data;
      this.credential = this.credentials[0];

      this.getAllCounts();
    },

    async getAllCounts() {
      const { data } = await NestService.getAllMessagesUnreadGroup();

      for (let i = 0; i < this.credentials.length; i++) {
        const item = data.find(
          (element) => element.credentialId == this.credentials[i].id
        );
        this.credentials[i].unreadCount = item.totalUnreadCount;
      }
    },

    async getAllUnreadByCredentialId() {
      const { data } = await NestService.getAllMessagesUnreadByCredentialId(
        this.credential.id
      );

      this.messagesUnread = data;
    },

    changeCredential(credential) {
      this.credential = credential;
    },

    openChat(chat) {
      this.currentChat = chat;
      this.leadId = chat.leadId ?? null;
      this.leadPhoneNumber = chat.leadPhoneNumber;
      this.showModal = true;
    },

    async clearAll() {
      await this.getAllCounts();
      await this.getAllDetails();
      await this.getAllUnreadByCredentialId();
      this.showModal = false;
    },

    getRedirectRoute(leadId) {
      // if (
      //   this.$route.name == "lead-show" ||
      //   this.$route.name == "sn-dashboard-new-lead"
      // )
      //   return null;

      if (this.moduleId == 2) {
        return `/crm/leads/${leadId}`;
      }

      if (this.moduleId == 15) {
        return `/socialnetwork/leads/new/dashboard/${leadId}`;
      }

      const modules = this.currentUser.arrModuls.map(
        (modul) => modul.id_module
      );

      if (modules.includes(2) && modules.includes(15)) {
        const mainModule = this.currentUser.main_module;

        if (mainModule == 2 || this.currentUser.role_id == 1) {
          return `/crm/leads/${leadId}`;
        } else {
          return `/socialnetwork/leads/new/dashboard/${leadId}`;
        }
      }

      if (modules.includes(2)) {
        return `/crm/leads/${leadId}`;
      }

      if (modules.includes(15)) {
        return `/socialnetwork/leads/new/dashboard/${leadId}`;
      }

      return `/crm/leads/${leadId}`;
    },
  },

  watch: {
    credential(value) {
      if (value) {
        this.getAllDetails();
        this.getAllUnreadByCredentialId();
      }
    },
  },

  created() {
    this.getAllCredentials();
  },
};
</script>

<style lang="scss" scoped>
.my-tab-rc-v2 {
  padding: 0.8rem;
  border-radius: 0.357rem;
  background-color: #f8f8f8;
}

.tab-active-v2, .my-tab-rc-v3 {
  background-color: #1654cc !important;
  color: #ffffff !important;
}

.my-tab-rc-v3{
  padding: 0.8rem;
   border-radius: 0.357rem;
}

.dark-layout {
  .my-tab-rc-v2 {
    background-color: #2d2d2d;
  }
}

.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.3rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
</style>