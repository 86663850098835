<template>
  <div>
    <div class="position-relative">
      <feather-icon
        v-if="G_COUNT_MEETING_RECRUITMENT"
        v-b-tooltip.hover.top
        v-b-tooltip.hover.v-warning
        :title="`${G_COUNT_MEETING_RECRUITMENT} recruitment meeting(s)`"
        icon="FlagIcon"
        size="19"
        class="text-warning"
        @click="oncontrol = true"
      />
      <feather-icon
        v-else
        v-b-tooltip.hover.top
        title="Not recruitment meetings"
        icon="FlagIcon"
        size="19"
        @click="oncontrol = true"
      />
      <strong
        v-if="G_COUNT_MEETING_RECRUITMENT"
        class="rounded-circle position-absolute text-warning pointer-events-none"
        style="top:12px; right: 0px;font-size: 12px;padding: 3px;"
      >{{ G_COUNT_MEETING_RECRUITMENT }}</strong>
    </div>
    <!-- calendar -->
    <b-modal
      v-model="oncontrol"
      size="xl"
      :header-bg-variant="isDarkSkin ? 'dark' : 'white'"
      :body-bg-variant="isDarkSkin ? 'dark' : 'white'"
      no-close-on-backdrop
      centered
      class="border-0 border-white"
      hide-footer
      @hidden="oncontrol = false"
    >
      <calendar-component />
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import CalendarComponent from '@/layouts/components/navbar/components/meetings/recruitments/calendarComponent.vue';

export default {
  components: {
    CalendarComponent,
  },
  data() {
    return {
      oncontrol: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      G_COUNT_MEETING_RECRUITMENT: 'NotificationStore/G_COUNT_MEETING_RECRUITMENT',
    }),
  },
  async mounted() {
    const params = {
      month: parseInt(moment().format('MM'), 10),
      year: parseInt(moment().format('YYYY'), 10),
      user_id: null,
    };
    await this.A_COUNT_MEETINGS_RECRUITMENT(params);
  },
  methods: {
    ...mapActions({
      A_COUNT_MEETINGS_RECRUITMENT: 'NotificationStore/A_COUNT_MEETINGS_RECRUITMENT',
    }),
  },
};
</script>
