<template>
  <b-nav-item
    v-if="isValidRange"
    :id="`my-attendance`"
    :to="{ name: 'user-attendance-dashboard' }"
  >
    <div class="position-relative">
      <strong
        class="bg-danger rounded text-white mb-1"
        style="
          position: absolute;
          right: -43px;
          padding: 1px 10px;
          transform: scale(0.6);
        "
      >
        New
      </strong>
    </div>
  </b-nav-item>
</template>
<script>
import moment from "moment";

export default {
  name: "AttendanceTopBar",
  computed: {
    currentDay() {
      return new Date().getDate();
    },
    isValidRange() {
      const dateDeployment = moment("2023-07-01");
      const currentDate = moment();
      const diffDate = currentDate.diff(dateDeployment, "days");
      return diffDate <= 3;
    },
  },
};
</script>
<style scoped>
.blink {
  animation: blinkAnimation 0.5s ease-in-out infinite;
}
.blink:hover {
  animation: none;
}
@keyframes blinkAnimation {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
