<template>
  <div>
    <b-modal
      v-model="oncontrol"
      size="lg"
      header-bg-variant="primary"
      @hidden="closeModal"
    >
      <template #modal-title>
        <strong>Selected employee</strong>
      </template>
      <b-container class="py-2">
        <b-row>
          <b-col
            cols="12"
          >
            <filter-slot
              :filter="[]"
              :filter-principal="filterPrincipal"
              :total-rows="totalRows"
              :paginate="paginate"
              :start-page="startPage"
              :to-page="toPage"
              @reload="$refs['refTableEmployee'].refresh()"
            >
              <template #table>
                <b-table
                  ref="refTableEmployee"
                  no-border-collapse
                  class="position-relative"
                  :fields="fields"
                  show-empty
                  no-provider-filtering
                  sticky-header="50vh"
                  primary-key="id"
                  responsive="sm"
                  :items="getEmployees"
                  :current-page="paginate.currentPage"
                  :per-page="paginate.perPage"
                  :filter="searchInput"
                  :busy.sync="isBusy"
                >
                  <template #table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle mr-1" />
                      <strong>Loading ...</strong>
                    </div>
                  </template>
                  <template #head(selected)>
                    <span
                      style="padding: 5px 5px 5px 8px"
                      class="rounded-circle text-white mr-1"
                      :class="countEmployeeMeeting>0?'bg-primary':'bg-secondary'"
                    >
                      {{ countEmployeeMeeting }}
                    </span>
                    Selecteds
                  </template>
                  <template #cell(selected)="data">
                    <div class="d-flex justify-content-center">
                      <b-form-checkbox
                        v-model="employeesSelectedForMeeting"
                        :value="data.item"
                        :name="data.name"
                      />
                    </div>
                  </template>
                </b-table>
              </template>
            </filter-slot>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div>
          <b-button
            class="mr-1"
            variant="primary"
            :disabled="countEmployeeMeeting === 0"
            @click="saveData()"
          >
            Save Employees
          </b-button>

        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import EmployeeService from '@/views/management/views/employees/employees.service';
import fields from '@/views/commons/components/projects/components/meetings/data/fields.meeting';

export default {
  props: {
    employeeSelected: {
      type: Array,
      default: () => [],
      require: true,
    },
  },
  data() {
    return {
      employeesSelectedForMeeting: [],
      oncontrol: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search by client name...',
        model: '',

      },
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      startPage: 0,
      toPage: 0,
      fields,
      searchInput: '',
      isBusy: false,
    };
  },
  computed: {
    countEmployeeMeeting() {
      return this.employeesSelectedForMeeting.length;
    },
  },
  mounted() {
    this.oncontrol = true;
    this.employeesSelectedForMeeting = this.employeeSelected;
  },
  methods: {
    saveData() {
      this.oncontrol = false;
      this.$emit('saveData', this.employeesSelectedForMeeting);
    },
    closeModal() {
      this.$emit('closeModal');
    },
    async getEmployees() {
      try {
        const params = {
          text: this.filterPrincipal.model,
          from: null,
          to: null,
          departament: null,
          page: this.paginate.currentPage,
          perPage: parseInt(this.paginate.perPage, 10),
        };
        const { data } = await EmployeeService.getEmployeesScheduleStatus(params);
        this.totalRows = data.total || 0;
        this.startPage = data.from || 0;
        this.toPage = data.to || 0;

    
        const employees = data.data.map(item => ({
          user_id: item.id_user,
          name:item.know_as?item.know_as :(`${item.first_name} ${item.last_name}`),
          know_as: item.know_as,
        }));
        employees.push({ user_id: 1, name: 'Roger Segura', know_as: 'Roger Segura' });
        return employees.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        this.showErrorSwal();
        console.log('Error to get employees', error);
      }
      return [];
    },
  },
};
</script>
