<template>
  <div
    class="border rounded px-1"
    :class="skin === 'dark' ? 'dark-clock' : 'light-clock'"
    v-b-tooltip.hover.top="tooltip"
  >
    <slot name="title">
      <span class="date"></span>
    </slot>
    <span
      class="time"
      :class="{
        'text-danger': isInClosingHours,
        'text-warning': isInNearCloseHours,
        'text-success': isInOpeningHours,
      }"
    >
      {{ this.clock }}
    </span>
  </div>
</template>
<script>
const moment = require("moment-timezone");

export default {
  props: {
    timezone: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      default: "HH:mm:ss",
    },
    tooltip: {
      type: String,
    },
  },
  data() {
    return {
      interval: null,
      clock: null,
      isInOpeningHours: false,
      isInNearCloseHours: false,
      isInClosingHours: false,
      openingStartTime: "08:00:00",
      nearCloseHourTime: "19:00:00",
      openingEndTime: "20:00:00",
    };
  },
  computed: {
    skin() {
      return this.$store.getters["appConfig/skin"];
    },
  },
  created() {
    this.interval = setInterval(() => {
      this.clock = this.getTime(this.timezone, this.format);
      if (
        this.clock >= this.openingStartTime &&
        this.clock <= this.nearCloseHourTime
      ) {
        this.isInOpeningHours = true;

        this.isInNearCloseHours = false;
        this.isInClosingHours = false;
      } else if (
        this.clock >= this.nearCloseHourTime &&
        this.clock <= this.openingEndTime
      ) {
        this.isInNearCloseHours = true;
        this.isInClosingHours = false;
        this.isInOpeningHours = false;
      } else {
        this.isInClosingHours = true;
        this.isInOpeningHours = false;
        this.isInNearCloseHours = false;
      }
    }, 1000);
  },
  methods: {
    getTime(timezone, format) {
      return moment().tz(timezone).format(format);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

.dark-clock {
  font-family: "Share Tech Mono", monospace;
  display: inline-block;
  margin: 0 1rem 0 0;
  color: #daf6ff;
  text-shadow: 0 0 20px rgba(10, 175, 230, 1), 0 0 20px rgba(10, 175, 230, 0);
  .time {
    font-size: 1.3em;
  }
  .date {
    font-size: 0.8em;
  }
  .text {
    font-size: 0.4em;
  }
}
.light-clock {
  font-family: "Share Tech Mono", monospace;
  display: inline-block;
  margin: 0 1rem 0 0;
  color: #414648;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3),
    0 0 20px rgba(10, 175, 230, 0.3);

  .time {
    font-size: 1.3em;
  }
  .date {
    font-size: 0.8em;
  }
  .text {
    font-size: 0.4em;
  }
}
</style>