<template>
  <div class="x-list">
    <!-- calendar -->
    <b-card class="shadow-none">
      <template #header>
        <div
          class="py-2 px-3 d-block justify-content-start align-items-center w-100"
          :class="isDarkSkin ? 'text-white' : 'text-dark'"
        >
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <strong style="font-size: 30px; min-width: 220px">
                {{ currentMonth }}
              </strong>
              <div class="d-flex ml-3">
                <feather-icon
                  icon="ArrowLeftCircleIcon"
                  size="25"
                  class="text-primary cursor-pointer hover-me"
                  @click="navigatorToDates(-1)"
                />
                <feather-icon
                  icon="CalendarIcon"
                  size="22"
                  class="text-primary mx-1 cursor-pointer hover-me"
                  @click="navigatorToDates(0)"
                />
                <feather-icon
                  icon="ArrowRightCircleIcon"
                  size="25"
                  class="text-primary cursor-pointer hover-me"
                  @click="navigatorToDates(1)"
                />
              </div>
            </div>
          </div>
          <p
            class="mt-1 py-1 border-top border-bottom"
            style="border-color: skyblue !important"
          >
            Here all your planned events. You will find information for each
            event.
          </p>
        </div>
      </template>
      <!--filters by meeting type-->
      <div
        style="
          padding: 15px 50px;
          box-sizing: border-box !important
        "
        class="m-0 pb-5 w-100"
      >
        <b-row
          class="border-top border-bottom d-flex justify-content-center justify-content-xl-around align-items-center p-2 mb-2 overflow: auto !important;"
          :class="!isDarkSkin ? 'list-group-item-light' : ''"
        >
          <b-col
            cols="12"
            md="6"
            class="d-block d-md-flex justify-content-center align-items-center py-1"
          >
            <strong
              class="mr-4"
              style="font-size: 15px;"
            >Status of meet: </strong>
            <b-form-checkbox-group
              v-model="selectedFilter"
              :options="optionsFilter"
              class="mr-2"
              value-field="item"
              text-field="name"
              switches
              disabled-field="notEnabled"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-center align-items-center py-1"
          >
            <strong class="text-warning">Orange Date : pending meeting</strong>
            <strong class="mx-3 text-info">Purple Date : reprogrammed meeting</strong>
            <strong class="text-success">Green Date : success meeting</strong>
          </b-col>
        </b-row>
        <!--calendar-->
        <b-skeleton-img
          v-if="loading"
          no-aspect
          height="500px"
        />
        <div
          v-else
          class="container-wrapper"
        >
          <div
            class="wrapper-head"
            style="min-width: 1000px;"
          >
            <!-- Names of Date -->
            <div
              v-for="date in optionDates"
              :key="date"
              :class="isDarkSkin ? 'text-white' : ''"
              class="position-relative"
              style="color: #34495e; padding: 15px"
              :style="!isDarkSkin
                ? 'border-bottom: 2px solid #34495E !important'
                : 'border-bottom: 2px solid white !important'
              "
            >
              <strong style="-webkit-text-stroke: 0.5px; letter-spacing: 2px">{{
                date
              }}</strong>
            </div>
          </div>
          <div
            v-if="miItems.length"
            class="wrapper"
            style="min-width: 1000px;"
          >
            <!-- Generated spaces until first day of month-->
            <div
              v-for="i in spaceEmpty"
              :key="i * -1"
              class="w-100"
              style="background-color: transparent !important"
            />
            <!-- Generated day with meets-->
            <div
              v-for="(item, index) in miItems"
              :key="index"
              class="position-relative"
              :class="currentDate === index + 1
                ? 'list-group-item-secondary'
                : !isDarkSkin
                  ? 'list-group-item-light'
                  : ''
              "
              :style="!isDarkSkin
                ? 'border-top: 2px solid #34495E !important'
                : 'border-top: 2px solid white !important'
              "
            >
              <strong
                class="day-calendar"
                style="font-size: 30px"
                :style="!isDarkSkin ? 'color:#34495E !important;' : ''"
              >{{ index + 1 > 9 ? index + 1 : `0${index + 1}`
              }}</strong>
              <!-- list meets -->
              <div
                class="d-block text-left px-1 overflow-auto"
                style="max-height: 170px"
              >
                <div
                  v-for="project in item"
                  :key="project.id"
                  style="max-height: 100%"
                >
                  <div
                    v-if="project"
                    style="margin: 10px 0px; padding: 3px 10px"
                    class="rounded cursor-pointer"
                    :class="optionsClass[project.status]"
                    @click="showModalDetails(project)"
                  >
                    <strong
                      class="text-uppercase"
                      style="font-size: 12px"
                    >{{
                      (project.name_project).length > 20
                        ? `${project.name_project.substring(0, 20)}...`
                        : project.name_project
                    }}</strong>
                    <br>
                    <div class="d-flex">
                      <small class="mr-1">
                        <feather-icon
                          icon="ClockIcon"
                          size="12"
                          class="m-0"
                        />
                        {{ project.time }}
                      </small>
                      <small class="rounded">
                        <feather-icon
                          icon="UserIcon"
                          size="12"
                          class="m-0"
                        />
                        {{ project.participants ? JSON.parse(project.participants).length : 0 }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div />
      </template>
    </b-card>
    <!--details calendar-->
    <modal-details
      v-if="showDetails"
      :meet="detailsMeet"
      @closeModal="closeModalDetails"
      @successAction="successMeeting"
    />
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import ServiceMeeting from '@/views/commons/components/projects/services/meeting.service';
import ModalDetails from '@/views/commons/components/projects/components/meetings/modals/ModalMeeting.vue';

export default {
  components: {
    ModalDetails,
  },
  data() {
    return {
      showDetails: false,
      currentMonth: null,
      miItems: [],
      optionDates: [
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
        'SATURDAY',
        'SUNDAY',
      ],
      detailsMeet: {},
      optionsClass: {
        pending: 'item-warning',
        completed: 'item-success',
        reprogrammed: 'item-info',
      },
      optionsFilter: [
        { item: 1, name: 'Pending' },
        { item: 2, name: 'Reprogrammed' },
        { item: 3, name: 'Completed' },
      ],
      selectedFilter: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    spaceEmpty() {
      return parseInt(
        moment(this.currentMonth, 'MMMM-YYYY').startOf('month').day(),
        10,
      ) === 0
        ? 6
        : parseInt(
          moment(this.currentMonth, 'MMMM-YYYY').startOf('month').day(),
          10,
        ) - 1;
    },
    currentDate() {
      return parseInt(moment().format('DD'), 10);
    },
    month() {
      return parseInt(moment(this.currentMonth, 'MMMM-YYYY').format('MM'), 10);
    },
    year() {
      return parseInt(moment(this.currentMonth, 'MMMM-YYYY').format('YYYY'), 10);
    },
  },
  watch: {
    async selectedFilter() {
      await this.getData();
    },
  },
  async mounted() {
    this.currentMonth = moment().format('MMMM-YYYY');
    this.selectedFilter = [1, 2]; // pending and reprogrammed metting
  },
  methods: {
    async navigatorToDates(value) {
      this.currentMonth = value !== 0
        ? moment(this.currentMonth).add(value, 'month').format('MMMM-YYYY')
        : moment().format('MMMM-YYYY');
      await this.getData();
    },
    async getData() {
      try {
        const params = {
          created_by: this.currentUser.user_id,
          month: this.month,
          year: this.year,
          status: this.selectedFilter ? this.selectedFilter.join(',') : null,
        };
        const { data } = await ServiceMeeting.getMeetings(params);
        const lastDayCurrentDay = parseInt(moment(this.currentMonth, 'MMMM-YYYY').endOf('month').format('DD'), 10);

        // generated days of month
        this.miItems = [];
        for (let index = 1; index <= lastDayCurrentDay; index += 1) {
          const keyDate = moment(`${this.year}-${this.month}-${index}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
          this.miItems.push(data[keyDate] ? data[keyDate] : []);
        }
        this.loading = false;
      } catch (error) {
        console.log('error', error);
        this.loading = false;
      }
    },
    showModalDetails(item) {
      this.detailsMeet = item;
      this.detailsMeet.date = moment(item.date).format('DD-MM-YYYY');
      this.showDetails = true;
    },
    closeModalDetails() {
      this.showDetails = false;
    },
    successMeeting() {
      this.showDetails = false;
      this.getData();
    },
  },
};
</script>
<style scoped>
.hover-me {
  transition: 0.3s ease-in-out;
}

.hover-me:hover {
  transform: scale(1.15);
}

.hover-light:hover {
  box-shadow: 0px 0px 3px white;
  /* border: 1px solid white; */
}

.hover-dark:hover {
  box-shadow: 0px 0px 2px black;
  /* border: 1px solid black; */
}
</style>
