<template>
  <div>
    <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
    >
      <template #button-content>
        <tabler-icon
          icon="CalendarIcon"
          size="20"
          ref="appointment-icon"
          :badge="notifications.length > 99 ? '+99' : notifications.length"
          badge-classes="badge-important"
          class="text-body"
        />
      </template>

      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">Appointments</h4>
          <b-badge pill variant="light-primary"
            >{{ notifications.length }} New</b-badge
          >
        </div>
      </li>

      <!-- Notifications -->
      <vue-perfect-scrollbar
        v-if="notifications.length > 0"
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <!-- Account Notification -->
        <!-- :to="notificationRoute(notification)" -->
        <template v-for="notification in notifications">
          <div :key="notification.id" class="cursor-pointer">
            <b-link
              :key="notification.id"
              :to="notificationRoute(notification)"
              target="_blank"
            >
              <b-media>
                <template #aside>
                  <b-avatar size="32" variant="light-secondary">
                    <feather-icon icon="InfoIcon" />
                  </b-avatar>
                </template>
                <p class="media-heading">
                  <span
                    class="font-weight-bolder"
                    v-html="notification.notification"
                  />

                  <span
                    v-if="notification.status == 0"
                    class="float-right cursor-pointer unread-notification"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                    >
                      <circle
                        id="Ellipse_1"
                        data-name="Ellipse 1"
                        cx="5"
                        cy="5"
                        r="5"
                        fill="#00b5b8"
                      />
                    </svg>
                  </span>
                </p>

                <small class="notification-text">
                  {{ notification.created_at | myDateGlobal }}
                </small>
              </b-media>
            </b-link>
          </div>
        </template>
      </vue-perfect-scrollbar>

      <!-- No Notifications -->
      <li v-else class="text-center my-3">
        <p class="mb-0">No notifications found</p>
      </li>

      <!-- Cart Footer -->
      <li class="dropdown-menu-footer">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          @click="notificationModal = true"
          >Read all appointments</b-button
        >
      </li>
    </b-nav-item-dropdown>
    <b-modal
      v-model="notificationModal"
      title-class="h3 text-white"
      size="lg"
      centered
      title="Appointments"
      hide-footer
      modal-class="modal-primary"
    >
      <AppointmenstNotificationList
        @closeNotifications="notificationModal = false"
        @update="update"
      ></AppointmenstNotificationList>
    </b-modal>
  </div>
</template>
  
  <script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import NavbarService from "@/layouts/components/navbar/service/navbar.service.js";
import AppointmenstNotificationList from "@/layouts/components/navbar/components/notifications/AppointmentNotificationsList.vue";
import moment from 'moment'
export default {
  mounted() {
    if (this.currentUser) {
      this.getAppointmentNotificactions();
    }
  },
  components: {
    VuePerfectScrollbar,
    AppointmenstNotificationList,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    dateNow(){
      return moment().format('YYYY-MM-DD')
    },
    
  },
  data() {
    return {
      showSettlementOfferModal: false,
      offerInfo: {},
      showResumeModal: false,
      resumeModalInfo: {},
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      notificationModal: false,
      accountsHoldModal: false,
      selectedNotification: {},

      notifications: [],
    };
  },
  methods: {
    notificationRoute(item){
      if(item.created_at_date <= '2023-05-17'){
        return item.link;
      }else{
        return {name: 'lead-show', params: {id: item.link} }
      }
    },
    async getAppointmentNotificactions() {
      try {
        const data = await NavbarService.getSellerApointments({
          id: this.currentUser.user_id,
        });
        this.notifications = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    updateNotifications() {
      //   this.A_GET_OFFER_NOTIFICATIONS({ id: this.currentUser.user_id });
    },
    update() {
      this.getAppointmentNotificactions();
    },
  },
};
</script>
  
  <style>
.wiggle {
  animation: wiggle 2s linear infinite;
}
@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
}
</style>
  