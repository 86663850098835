<template>
  <b-modal
    ref="modalRegisterPayment"
    modal-class="modal-primary"
    title-class="h4 text-white font-weight-bolder"
    title="Make Payment"
    size="md"
    @hidden="closeModal()"
    hide-footer
    no-close-on-backdrop
  >
    <b-row style="padding: 0 15px">
      <b-col cols="12" md="6" xl="4" class="mt-1">
        <b-form-group label="Search by:">
          <b-form-radio-group
            v-model="selectedItem"
            :options="itemOptions"
            button-variant="outline-primary"
            buttons
            class="w-100"
            @input="manageInput"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="12" xl="8" class="mt-1">
        <b-overlay :show="loadingSearch" rounded="lg" opacity="0.6">
          <template #overlay>
            <div class="d-flex align-items-center mt-2" style="gap: 4px">
              <b-spinner small type="grow" variant="primary" />
              <b-spinner type="grow" variant="primary" />
              <b-spinner small type="grow" variant="primary" />
              <!-- We add an SR only text for screen readers -->
              <span class="sr-only">Please wait...</span>
            </div>
          </template>
          <ValidationObserver ref="form">
            <b-form-group
              :label="
                isSearchingLead ? 'Lead name:' : 'Client or Account Number:'
              "
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="searchText"
                rules="required"
              >
                <b-input-group :aria-hidden="loadingSearch ? 'true' : null">
                  <b-form-input
                    type="text"
                    :placeholder="
                      isSearchingLead
                        ? 'Search by name'
                        : 'Search by name or account number'
                    "
                    v-model="searchText"
                    @keyup.enter="reloadTable()"
                    id="search-lead-or-client"
                    :state="errors[0] ? false : null"
                    ref="inputSearch"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      class="py-0"
                      @click="reloadTable()"
                    >
                      SEARCH
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </ValidationProvider>
            </b-form-group>
          </ValidationObserver>
        </b-overlay>
      </b-col>
    </b-row>
    <filter-slot
      :filter="[]"
      :total-rows="FiltersForSlot.totalRows"
      :paginate="FiltersForSlot.paginate"
      :start-page="FiltersForSlot.startPage"
      :to-page="FiltersForSlot.toPage"
      :top-paginate="false"
      :filter-principal="{}"
      :no-visible-principal-filter="true"
      :show-container-filters-selector="false"
      @reload="$refs['registerTable'].refresh()"
      class="filter-slot-new-customization"
    >
      <b-table
        ref="registerTable"
        slot="table"
        class="blue-scrollbar position-relative table-new-customization"
        :items="myProvider"
        :fields="showFields"
        :busy.sync="isBusy"
        primary-key="id"
        responsive="md"
        sticky-header="50vh"
        show-empty
        no-provider-filtering
        :per-page="FiltersForSlot.paginate.perPage"
        :current-page="FiltersForSlot.paginate.currentPage"
      >
        <template v-slot:cell(client_account_id)="data">
          <router-link
            class="font-weight-bolder"
            :to="{
              name: dashboardRouteName(moduleId),
              params: {
                idClient: data.item.client_account_id,
              },
            }"
            target="_blank"
          >
            <div class="d-flex gap-5">
              <status-account :account="data.item" :text="false" />
              {{ data.item.name }}
            </div>
            <div style="margin-left: 24px">
              {{ data.item.account }}
            </div>
          </router-link>
        </template>
        <template v-slot:cell(lead_id)="data">
          <router-link
            class="text-warning font-weight-bolder"
            target="_blank"
            :to="{
              name: dashboardLeadRouteName(moduleId),
              params: { id: data.item.id },
            }"
          >
            {{ data.item.name }} <br />
            ID: {{ data.item.id }}
          </router-link>
        </template>
        <template v-slot:cell(actions)="data">
          <div class="d-flex justify-content-center align-items-center gap-5">
            <b-badge
              v-if="selectedItem == 'lead'"
              variant="light-success"
              class="clickable"
              @click="openChooseLeadTransaction(data.item)"
            >
              Add Payment
            </b-badge>
            <b-badge
              v-if="
                selectedItem == 'client' &&
                ![4, 5, 6, 11, 12, 13].includes(data.item.client_status)
              "
              variant="light-success"
              class="clickable"
              @click="openMonthlyPayment(data.item)"
            >
              Monthly Payment
            </b-badge>
            <b-badge
              v-if="selectedItem == 'client'"
              variant="light-primary"
              class="clickable"
              @click="openChargePayment(data.item)"
            >
              Charge
            </b-badge>
          </div>
          <template v-if="data.item.sales">
            <div
              v-for="(sale, index) in JSON.parse(data.item.sales)"
              :key="index"
            >
              <b-badge
                variant="light-warning"
                class="clickable"
                @click="openInitialPayment(data.item, sale)"
              >
                Initial Payment [{{ sale.program_name }}]
              </b-badge>
            </div>
          </template>
        </template>
      </b-table>
    </filter-slot>

    <monthly-payment-modal
      v-if="showNewMonthlyPayment"
      @refreshTable="reloadTable"
      @close="showNewMonthlyPayment = false"
      @paymentSaveSuccessfully="reloadTable"
    />

    <add-charge-modal
      v-if="showChargeModal"
      :title-add-charge="`Add Charge`"
      @refresh="reloadTable"
      @close="showChargeModal = false"
    />

    <lead-choose-transaction
      v-if="showChooseLeadTransaction"
      @hidden="showChooseLeadTransaction = false"
      :lead-name="lead.name"
      :lead-id="lead.id"
      @on-transaction-registered="
        showChooseLeadTransaction = false;
        reloadTable();
      "
    />

    <modal-initial-payment
      v-if="showInitialPaymentModal"
      :modal="{ initial_payment: true }"
      :initial_payment="modalIP"
      @reload="reloadTable"
      @close="showInitialPaymentModal = false"
    />
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import RegisterPaymentService from "@/views/commons/new-payment/service/index.js";

import MonthlyPaymentModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/monthly-payment-modal/MonthlyPaymentModal.vue";
import AddChargeModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/AddChargeModal.vue";
import LeadChooseTransaction from "@/views/commons/components/leads/transactions/LeadChooseTransaction.vue";
import ModalInitialPayment from "@/views/crm/views/sales-made/components/modals/InitialPaymentModal.vue";
export default {
  components: {
    StatusAccount,
    MonthlyPaymentModal,
    AddChargeModal,
    LeadChooseTransaction,
    ModalInitialPayment,
  },
  data() {
    return {
      itemOptions: [
        { text: "CLIENT", value: "client", disabled: false },
        { text: "LEAD", value: "lead", disabled: false },
      ],
      selectedItem: "client",
      searchText: "",
      loadingSearch: false,
      isBusy: false,
      Fields: [
        { key: "client_account_id", label: "Client Name", visible: true },
        { key: "lead_id", label: "Lead Name", visible: false },
        {
          key: "mobile",
          label: "Mobile",
          tdClass: "text-center",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "actions",
          label: "Actions",
          tdClass: "text-center",
          thClass: "text-center",
          visible: true,
        },
      ],
      FiltersForSlot: {
        totalRows: 0,
        paginate: {
          perPage: 10,
          currentPage: 1,
        },
        startPage: 1,
        toPage: 1,
      },
      lead: {},
      modalIP: {},
      initialPayment: {},
      showNewMonthlyPayment: false,
      showChargeModal: false,
      showChooseLeadTransaction: false,
      showInitialPaymentModal: false,
    };
  },
  mounted() {
    this.toggleModal("modalRegisterPayment");
  },
  created() {
    if (this.moduleId == 2) {
      this.selectedItem = "lead";
      this.itemOptions[0].disabled = true;
      this.manageInput();
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isSearchingLead() {
      return this.selectedItem == "lead";
    },
    showFields() {
      return this.Fields.filter((field) => field.visible);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    ...mapActions({
      SET_CURRENT_CLIENT: "DebtSolutionClients/A_GET_CLIENTS",
    }),
    async myProvider() {
      try {
        if (this.searchText == "") {
          return [];
        }
        const params = {
          search: this.searchText,
          type: this.selectedItem,
          module_id: this.moduleId,
          page: this.FiltersForSlot.paginate.currentPage,
          per_page: this.FiltersForSlot.paginate.perPage,
        };
        this.loadingSearch = true;
        const response = await RegisterPaymentService.getClientsOrLeads(params);
        this.FiltersForSlot.totalRows = response.total;
        this.FiltersForSlot.toPage = response.to;
        return response.data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingSearch = false;
      }
    },
    reloadTable() {
      this.$refs["registerTable"].refresh();
    },
    manageInput() {
      this.searchText = "";
      if (this.selectedItem == "lead") {
        this.Fields[0].visible = false;
        this.Fields[1].visible = true;
      } else {
        this.Fields[0].visible = true;
        this.Fields[1].visible = false;
      }
      this.reloadTable();
    },
    async openMonthlyPayment(item) {
      this.addPreloader();
      try {
        await this.SET_CURRENT_CLIENT({ id: item.client_account_id });
        this.showNewMonthlyPayment = true;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async openChargePayment(item) {
      this.addPreloader();
      try {
        await this.SET_CURRENT_CLIENT({ id: item.client_account_id });
        this.showChargeModal = true;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async openChooseLeadTransaction(item) {
      this.lead = item;
      this.showChooseLeadTransaction = true;
    },
    async openInitialPayment(item, sale) {
      this.modalIP = {};
      try {
        this.addPreloader();
        this.modalIP = sale;
        this.modalIP.nameProgram = sale.program_name;
        this.modalIP.nameClient = item.name;
        this.modalIP.programid = sale.program_id;
        this.modalIP.sessionId = this.currentUser.user_id;
        this.modalIP.role_id = this.currentUser.role_id;
        this.modalIP.feeprops = sale.fee;
        this.modalIP.cfeestatus = 0;
        this.modalIP.editmodal = true;
        this.modalIP.statusSale = 1;
        this.modalIP.valorInitalPayment = 1;
        this.modalIP.allcards = await this.getCards(item.id);
        [this.modalIP.payments] = await this.getPaymentsSalesMade(sale);
        this.modalIP.modul = this.moduleId;
        this.initialPayment = {
          programid: sale.program_id,
          type_payment_ce: sale.type_payment_ce,
        };
        this.showInitialPaymentModal = true;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getCards(leadId) {
      try {
        const cards = await amgApi.post("/clients/search-cards-clients", {
          id: leadId,
        });
        return cards.data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getPaymentsSalesMade(sale) {
      this.addPreloader();
      try {
        const response = await amgApi.post(
          "/sales-made/get-payments-sales-made",
          { id: sale.sale_id }
        );
        return response.data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>