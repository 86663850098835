<template>
  <div>
    <svg
      id="Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1080 1080"
    >
      <path
        class="cls-1"
        :style="isDarkSkin ? 'fill: #00d25b' : 'fill: #00d25b'"
        d="M855.16,204.66H219.54c-46.5,0-84.33,37.83-84.33,84.33v360.19c0,46.5,37.83,84.33,84.33,84.33h286.17c23.18,78.93,96.22,136.75,182.55,136.75s160.32-58.58,182.99-138.31c38.83-7.53,68.24-41.77,68.24-82.77v-360.19c0-46.5-37.83-84.33-84.33-84.33ZM902.2,420.77H172.5v-68.65h729.7v68.65ZM219.54,241.95h635.62c25.94,0,47.04,21.1,47.04,47.04v25.85H172.5v-25.85c0-25.94,21.1-47.04,47.04-47.04ZM688.26,832.96c-84.35,0-152.97-68.63-152.97-152.98s68.62-152.97,152.97-152.97,152.97,68.62,152.97,152.97-68.62,152.98-152.97,152.98ZM878.25,690.11c.18-3.35.27-6.73.27-10.13,0-104.91-85.35-190.26-190.26-190.26s-190.26,85.35-190.26,190.26c0,5.47.29,10.87.74,16.23H219.54c-25.94,0-47.04-21.1-47.04-47.03v-191.11h729.7v191.11c0,17.55-9.68,32.85-23.95,40.94Z"
      />
      <rect
        :style="isDarkSkin ? 'fill: #00d25b' : 'fill: #00d25b'"
        class="cls-1"
        x="245.39"
        y="518.23"
        width="135.6"
        height="37.29"
      />
      <rect
        :style="isDarkSkin ? 'fill: #00d25b' : 'fill: #00d25b'"
        class="cls-1"
        x="423.36"
        y="518.23"
        width="50.85"
        height="37.29"
      />
      <path
        :style="isDarkSkin ? 'fill: #00d25b' : 'fill: #00d25b'"
        class="cls-1"
        d="M753.63,684.9c-4.08-7.32-10.55-13.26-19.23-17.65-7.75-3.92-17.58-7.24-29.97-10.14-9.48-2.31-16.76-4.68-21.63-7.02-3.75-1.8-6.36-3.81-7.57-5.82-1.29-2.14-1.94-4.93-1.94-8.31v-.5c0-3.27.63-5.87,1.92-7.92,1.12-1.78,3.21-3.27,6.21-4.42,3.86-1.48,9.28-2.23,16.1-2.23,9.08,0,17.16,1.09,24.02,3.23,7.08,2.21,13.31,4.89,18.52,7.98,3.02,1.79,6.8,1.73,9.76-.16s4.61-5.28,4.27-8.78l-1.86-18.82c-.3-3.04-2.07-5.74-4.74-7.23-5.63-3.14-12.85-5.99-21.44-8.48-3.53-1.03-7.35-1.84-11.54-2.45v-15.59c0-5.15-4.17-9.32-9.32-9.32h-17.83c-5.15,0-9.32,4.17-9.32,9.32v15.4c-11.59,1.8-21.01,5.67-28.08,11.53-10.7,8.88-16.12,21.39-16.12,37.2v1.11c0,10.13,1.99,18.89,5.92,26.03,4.04,7.35,10.51,13.46,19.23,18.15,7.93,4.26,18.01,7.8,30.88,10.84,8.77,2,15.7,4.14,20.59,6.34,3.73,1.68,6.38,3.64,7.67,5.67,1.33,2.1,2.01,5.12,2.01,8.99v1.98c0,7.44-2.86,10.41-5.01,12-2.88,2.13-8.97,4.67-21.57,4.67-9.11,0-17.6-1.28-25.24-3.8-7.94-2.62-14.88-5.69-20.61-9.11-3.03-1.81-6.82-1.76-9.79.14-2.97,1.9-4.62,5.31-4.26,8.82l2.1,20.43c.31,3.04,2.1,5.74,4.78,7.21,3.7,2.04,8.3,4.07,13.67,6.03,5.38,1.98,11.55,3.65,18.32,4.98,1.77.35,3.61.65,5.54.91v15.82c0,5.15,4.17,9.32,9.32,9.32h17.83c5.15,0,9.32-4.17,9.32-9.32v-16.28c11.35-1.97,20.66-5.84,27.78-11.55,11.47-9.21,17.29-22.59,17.29-39.77v-2.6c0-10.72-2-19.75-5.95-26.83h0Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "PaymentButton",
};
</script>

<style scoped>
.cls-1 {
  stroke-width: 1px;
}
</style>