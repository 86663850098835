<template>
  <div class="btn-payment" @click="openModalRegister">
    <PaymentButton class="clickable" v-b-tooltip.hover.right="'Make Payment'" />
    <ModalRegisterPayment v-if="showModal" @close="showModal = false" />
  </div>
</template>

<script>
import PaymentButton from "@/views/commons/new-payment/icons/PaymentButton.vue";
import ModalRegisterPayment from "@/views/commons/new-payment/ModalRegisterPayment.vue";
export default {
  components: {
    ModalRegisterPayment,
    PaymentButton,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModalRegister() {
      this.showModal = true;
    },
  },
};
</script>

<style scoped>
.btn-payment {
  width: 30px;
}
</style>