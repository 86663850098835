<template>
  <div class="x-list">
    <b-nav-item-dropdown
      left
      toggle-class="d-flex align-items-center dropdown-user-link"
    >
      <template #button-content>
        <div class="position-relative">
          <feather-icon
            badge-classes="badge-important"
            class="cursor-pointer"
            :class="
              G_COUNT_EMPLOYEES_WITHOUT_SCHEDULE
                ? isValidRange
                  ? 'blink text-danger'
                  : 'text-danger'
                : isValidRange
                ? 'blink text-primary'
                : 'text-primary'
            "
            icon="CalendarIcon"
            size="20"
          />
          <strong
            v-if="isValidRange"
            class="bg-danger rounded-sm text-white"
            style="
              padding: 1px 5px;
              font-size: 8px;
              position: absolute;
              top: -13px;
              left: -5px;
            "
            >New</strong
          >
        </div>
      </template>
      <b-dropdown-item
        v-for="(
          { module_name, module_id, module_icon }, index
        ) in attendanceChiefDashboard"
        :key="index"
        :to="{ name: attendanceDashboardsPathNames[module_id] }"
      >
        <div class="d-flex">
          <div>
            <img
              :src="baseUrl + module_icon"
              alt="Logo"
              style="width: auto"
              height="16"
              class="mr-2"
              :class="
                [3, 5, 6, 7, 8, 11, 12, 15].includes(module_id)
                  ? ''
                  : 'gray-home'
              "
            />
          </div>
          <span class="text-center" style="padding-top: 2px"
            >{{ module_id != 2 ? capitalizeWord(module_name) : module_name }}
            {{ module_id == 21 ? "Digital" : "" }}
          </span>
        </div>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      attendanceDashboardsPathNames: {
        2: "crm-dashboard-attendance",
        3: "bu-dashboard-attendance",
        4: "adm-dashboard-attendance",
        5: "ds-dashboard-attendance",
        6: "credit-experts-dashboard-attendance",
        7: "bc-dashboard-attendance",
        8: "tr-dashboard-attendance",
        9: "correspondence-dashboard-attendance",
        10: "court-info-dashboard-attendance",
        11: "specialists-dashboard-attendance",
        12: "paragon-dashboard-attendance",
        14: "bookeeping-dashboard-attendance",
        15: "sn-dashboard-attendance",
        18: "quality-dashboard-attendance",
        19: "logistic-dashboard-attendance",
        20: "ce-connection-dashboard-attendance",
        21: "ce-customer-service-dashboard-attendance",
        22: "ce-customer-service-dashboard-attendance",
        23: "ce-financial-dashboard-attendance",
        25: "sp-digital-dashboard-attendance",
        26: "ce-sales-dashboard-attendance",
        27: "creative-dashboard-attendance",
        28: "sp-analyst-department-attendance",
        29: "sp-financial-approval-attendance",
        30: "sp-accounts-resolution-attendance",
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_COUNT_EMPLOYEES_WITHOUT_SCHEDULE:
        "NotificationStore/G_COUNT_EMPLOYEES_WITHOUT_SCHEDULE",
    }),

    currentUserModules() {
      return this.currentUser.arrRoles;
    },
    mainModule() {
      return this.currentUser.main_module;
    },
    attendanceChiefDashboard() {
      return this.currentUserModules.filter(({ role_id, module_id }) => {
        if (role_id === 17 && this.mainModule == 6) {
          return module_id !== 24;
        } else if (
          [2, 17, 11, 26].includes(role_id) &&
          this.mainModule != 6 && this.mainModule !== 11 &&
          ![1, 13, 16, 17, 21, 24].includes(module_id)
        ) {
          return module_id === this.mainModule;
        } else if ([28,29,30].includes(module_id)) {
          return module_id;
        } else {
          return false;
        }
      });
    },
    thereAreAttendanceChiefDashboardToShow() {
      return this.attendanceChiefDashboard.length > 0;
    },
    isValidRange() {
      const dateDeployment = moment("2023-07-01");
      const currentDate = moment();
      const diffDate = currentDate.diff(dateDeployment, "days");
      return diffDate <= 7;
    },
  },
  async mounted() {
    await this.A_SET_COUNT_EMPLOYEES_WITHOUT_SCHEDULE({
      user_id: this.currentUser.user_id,
    });
    this.$emit(
      "employeeWithoutSchedule",
      this.G_COUNT_EMPLOYEES_WITHOUT_SCHEDULE
    );
  },
  methods: {
    ...mapActions({
      A_SET_COUNT_EMPLOYEES_WITHOUT_SCHEDULE:
        "NotificationStore/A_SET_COUNT_EMPLOYEES_WITHOUT_SCHEDULE",
    }),
    capitalizeWord(inputString) {
      const words = inputString.toLowerCase().split(" ");
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
      }
      return words.join(" ");
    },
  },
};
</script>

<style>
.x-list .dropdown-menu {
  max-height: 300px !important;
  overflow-y: auto;
}

.gray-home {
  transition: all 0.1s ease;
  filter: invert(93%) sepia(12%) saturate(0%) hue-rotate(127deg) brightness(83%)
    contrast(80%);
}
.blink {
  animation: animationBlink 0.5s ease-in-out infinite;
}
.blink:hover {
  animation: none;
}
@keyframes animationBlink {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
</style>
