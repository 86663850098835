var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canViewVerticalNavMenuLink(_vm.item))?_c('li',{staticClass:"nav-item",class:{
    active: _vm.isActive,
    disabled: _vm.item.disabled,
  }},[_c('b-link',_vm._b({staticClass:"d-flex align-items-center"},'b-link',_vm.linkProps,false),[(_vm.item.icon)?_c('tabler-icon',{attrs:{"icon":_vm.item.icon || 'CircleIcon',"badge":!_vm.isMouseHovered &&
          _vm.isVerticalMenuCollapsed &&
          (_vm.currentBreakPoint == 'xl' || _vm.currentBreakPoint == 'xxl')
          ? _vm.item.tag && _vm.item.tag > 0
            ? _vm.item.tag
            : 0
          : 0,"badge-classes":"bg-danger"}}):_c(_vm.item.otherIcon,{tag:"component",style:({
        fill: _vm.isDarkSkin || _vm.isActive ? '#fff' : '#70667b',
      })}),_c('span',{staticClass:"menu-title text-truncate"},[_vm._v(_vm._s(_vm.t(_vm.item.title)))]),(_vm.item.tag && _vm.item.tag > 0)?_c('b-badge',{staticClass:"mr-1 ml-auto",attrs:{"pill":"","variant":_vm.item.tagVariant || 'danger'}},[_vm._v(_vm._s(_vm.item.tag)+" ")]):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }