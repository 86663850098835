<template>
  <!-- <tabler-icon
    :badge="G_COUNTER_ALL_MESSAGES_UNREAD"
    icon="MessageIcon"
    size="25"
    style="margin-left: 0.4rem"
    class="cursor-pointer"
    v-if="[2, 15].includes(moduleId) || inMetaOrCRM || isCeo"
    v-b-tooltip.hover
    title="Sms Inbox"
    @click="goToInbox()"
  /> -->

  <div
    v-if="[2, 15].includes(moduleId) || inMetaOrCRM || isCeo"
    @click="openDetail()"
    class="container-icon-rc-main"
  >
    <img
      v-b-tooltip.hover
      title="SMS INBOX"
      class="icon-rc-main"
      :src="logoRc"
    />
    <span
      class="badge badge-up badge-pill badge-important cursor-pointer"
      v-if="G_COUNTER_ALL_MESSAGES_UNREAD != 0"
    >
      {{ G_COUNTER_ALL_MESSAGES_UNREAD }}</span
    >

    <rc-icon-detail v-if="showDetail" @hidden="showDetail = false" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import io from "socket.io-client";
import RcChatOne from "@/views/commons/components/ring-central/chat-sms/RcChatOne.vue";
import RcIconDetail from "@/views/commons/components/ring-central/chat-sms/components/utils/IconDetail.vue";
export default {
  components: {
    RcChatOne,
    RcIconDetail,
  },

  data() {
    return {
      socket: null,
      showDetail: false,
    };
  },

  computed: {
    ...mapGetters({
      G_COUNTER_MESSAGES_NOT_READ_LEAD:
        "SmsInboxStore/G_COUNTER_MESSAGES_NOT_READ_LEAD",
      currentBreakPoint: "app/currentBreakPoint",
      currentUser: "auth/currentUser",

      G_COUNTER_ALL_MESSAGES_UNREAD:
        "SmsInboxStore/G_COUNTER_ALL_MESSAGES_UNREAD",
    }),

    logoRc() {
      return "/assets/images/ringcentral.ico";
    },

    moduleId() {
      return this.$route.matched[0]?.meta.module;
    },

    inMetaOrCRM() {
      return this.currentUser.arrModuls.find(
        (rol) => rol.id_module === 2 || rol.id_module === 15
      );
    },
  },

  methods: {
    ...mapActions({
      A_GET_ALL_MESSAGES_UNREAD: "SmsInboxStore/A_GET_ALL_MESSAGES_UNREAD",
    }),

    openDetail() {
      if (this.G_COUNTER_ALL_MESSAGES_UNREAD > 0) {
        this.showDetail = true;
      } else {
        this.goToInbox();
      }
    },

    goToInbox() {
      if (
        this.$route.name == "crm-chat-sms" ||
        this.$route.name == "mm-chat-sms"
      )
        return;

      if (this.moduleId == 2) {
        this.$router.push({
          name: "crm-chat-sms",
        });
        return;
      }

      if (this.moduleId == 15) {
        this.$router.push({
          name: "mm-chat-sms",
        });
        return;
      }

      const modules = this.currentUser.arrModuls.map(
        (modul) => modul.id_module
      );

      if (modules.includes(2) && modules.includes(15)) {
        const mainModule = this.currentUser.main_module;

        if (mainModule == 2 || this.currentUser.role_id == 1) {
          this.$router.push({
            name: "crm-chat-sms",
          });
          return;
        } else {
          this.$router.push({
            name: "mm-chat-sms",
          });
          return;
        }
      }

      if (modules.includes(2)) {
        this.$router.push({
          name: "crm-chat-sms",
        });
        return;
      }

      if (modules.includes(15)) {
        this.$router.push({
          name: "mm-chat-sms",
        });
        return;
      }
    },

    async bindReadAllMessage() {
      try {
        this.socket.on("notification-message-created", (data) => {
          if (data.direction == "Inbound" && data.readStatus == "Unread") {
            this.A_GET_ALL_MESSAGES_UNREAD();
          }
        });

        this.socket.on("notification-read-all-messages", (data) => {
          this.A_GET_ALL_MESSAGES_UNREAD();
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    initWebsocket() {
      const connectionOptions = {
        "force new connection": true,
        reconnectionAttempts: "Infinity",
        timeout: 10000,
        transports: ["websocket"],
      };
      this.socket = io(process.env.VUE_APP_NEST_BACK, connectionOptions);
    },
  },

  created() {
    this.initWebsocket();
  },

  mounted() {
    this.A_GET_ALL_MESSAGES_UNREAD();
    this.bindReadAllMessage();
  },
};
</script>

<style lang="scss" scoped>
.container-icon-rc-main {
  display: flex;
  position: relative;

  .icon-rc-main {
    width: 22px;
    height: 22px;
    margin-left: 5px;
    cursor: pointer;
  }

  .counter-rc-messages {
    position: absolute;
    top: -10px;
    right: -5px;
  }
}
</style>
