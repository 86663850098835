<template>
  <div class="x-list">
    <div
      left
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="position-relative"
    >
      <router-link
        v-b-tooltip.hover.top="'Customer Tickets'"
        :to="{ name: 'customer-tickets-ceo' }"
        class="text-warning cursor-pointer mr-1"
      >
        <tabler-icon
          :badge="countersTotal > 99 ? '+99' : countersTotal"
          badge-classes="badge-important"
          icon="TicketIcon"
          size="25"
        />
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_COUNT_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS",
    }),
    countersTotal() {
      const { v_total_open, v_total_open_p } =
        this.G_COUNT_PENDING_CUSTOMER_TICKETS;

      return v_total_open + v_total_open_p;
    },
  },
  async mounted() {
    await this.A_COUNT_PENDING_CUSTOMER_TICKETS({
      user_id: this.currentUser.user_id,
      client_account_id: null,
    });
  },
  methods: {
    ...mapActions({
      A_COUNT_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS",
    }),
  },
};
</script>
