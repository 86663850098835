<template>
  <div>
    <template v-if="item.module && item.modules && modulesFiltered.length > 1">
      <b-select
        v-model="selectedModule"
        v-if="canViewHeader"
        :options="modulesFiltered"
        class="mx-1 my-2 custom-expanded-select"
        @change="changeModule"
      />
      <li
        class="navigation-header text-truncate name-select-hide"
        v-if="canViewHeader"
      >
        <span>{{ translatedHeader }}</span>
        <tabler-icon :icon="iconName" size="18" />
      </li>
    </template>
    <template v-else>
      <li class="navigation-header text-truncate" v-if="canViewHeader">
        <span>{{ translatedHeader }}</span>
        <tabler-icon :icon="iconName" size="18" />
      </li>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { useUtils as useAclUtils } from "@core/libs/acl";

export default {
  data() {
    return {
      selectedModule: null,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.selectedModule = this.item.module;
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    modulesFiltered() {
      const { arrModuls } = this.currentUser;
      const onlyModules = arrModuls.map((module) => module.id_module);
      return this.item.modules.filter((module) =>
        onlyModules.includes(module.value)
      );
    },
    translatedHeader() {
      const { t } = useI18nUtils();
      return t(this.item.header);
    },
    canViewHeader() {
      const { canViewVerticalNavMenuHeader } = useAclUtils();
      return canViewVerticalNavMenuHeader(this.item);
    },
    iconName() {
      return "DotsIcon";
    },
  },
  methods: {
    changeModule() {
      const { route } = this.modulesFiltered.find(
        (module) => module.value === this.selectedModule
      );
      this.$router.push({ name: route });
    },
  },
};
</script>

<style lang="scss">
.custom-expanded-select {
  display: none;
}
.expanded > ul > div > ul > div > .custom-expanded-select {
  display: block;
  width: 90%;
}
.name-select-hide {
  display: block;
}
.expanded > ul > div > ul > div > .name-select-hide {
  display: none;
}
</style>